import React from 'react'
import { Link } from 'react-router-dom'
import { Loader, Icon } from '@kmon/ui'
import { Network } from '@kmon/schemas'
import { getChainConfiguration } from '@kmon/dapps/dist/lib/chainConfiguration'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import {
  isPending,
  getTransactionHref
} from '@kmon/dapps/dist/modules/transaction/utils'
import {
  TransactionStatus,
  Transaction
} from '@kmon/dapps/dist/modules/transaction/types'
import { formatDistanceToNow } from '../../../../lib/date'
import { locations } from '../../../../modules/routing/locations'
import { NFTImage } from '../../../NFTImage'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'
import { CoinPopup } from '../../../CoinPopup'
import { Props } from './TransactionDetail.types'
import './TransactionDetail.css'
import basicLootbox from '../../../../images/items/lootbox_basic.png'
import mediumLootbox from '../../../../images/items/lootbox_medium.png'
import premiumLootbox from '../../../../images/items/lootbox_premium.png'
import unfreezePotion from '../../../../images/items/unfreeze_potion.png'
import battleStaminaPotion from '../../../../images/items/battle_stamina_potion.png'
import diamondStoryChest from '../../../../images/items/diamond_story_chest.png'
import { Image } from '../../../Image'

export const images: Record<string, string> = {
  lootbox_basic: basicLootbox,
  lootbox_medium: mediumLootbox,
  lootbox_premium: premiumLootbox,
  unfreeze_potion: unfreezePotion,
  battle_stamina_potion: battleStaminaPotion,
  diamond_story_chest: diamondStoryChest
}

const getHref = (tx: Transaction) => {
  if (tx.status === null) {
    return
  }
  return getTransactionHref({ txHash: tx.replacedBy || tx.hash }, tx.chainId)
}

const TransactionDetail = (props: Props) => {
  const { nft, text, tx, image } = props
  return (
    <Row className="TransactionDetail">
      <Column align="left" grow={true}>
        <div className="image">
          {nft === null ? (
            <Loader active size="small" />
          ) : nft ? (
            <Link to={locations.nft(nft.contractAddress, nft.tokenId)}>
              <NFTImage nft={nft} isSmall />
            </Link>
          ) : (
            // <Mana
            //   network={
            //     tx.chainId
            //       ? getChainConfiguration(tx.chainId).network
            //       : Network.ETHEREUM
            //   }
            // />
            <></>
          )}
          {tx.payload.item?.name ? <Image src={tx.payload.item.name.indexOf("-") > -1 ? images[tx.payload.item.name.toLocaleLowerCase().substring(0, tx.payload.item.name.indexOf("-"))] : images[tx.payload.item.name.toLocaleLowerCase()]} /> : null}
          <Image src={image} />
        </div>
        <div className="text">
          <div className="description">{text}</div>
          <div className="timestamp">{formatDistanceToNow(tx.timestamp)}.</div>
        </div>
      </Column>
      <Column align="right">
        <a
          href={getHref(tx)}
          className={tx.status ? 'status ' + tx.status : 'status'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tx.status && (
            <div className="description">
              {t(`transaction.status.${tx.status}`) || t('global.loading')}
            </div>
          )}
          {isPending(tx.status) ? (
            <div className="spinner">
              <Loader active size="mini" />
            </div>
          ) : null}
          {tx.status === TransactionStatus.REVERTED ? (
            <Icon name="warning sign" />
          ) : null}
          {tx.status === TransactionStatus.CONFIRMED ||
            tx.status === TransactionStatus.REPLACED ? (
            <Icon name="check" />
          ) : null}
        </a>
      </Column>
    </Row>
  )
}

export default React.memo(TransactionDetail)
