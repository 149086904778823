import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { RouteComponentProps } from 'react-router'
import { Address } from 'web3x-es/address'
import {
  getData as getAuthorizations,
  getLoading as getLoadingAuthorizations
} from '@kmon/dapps/dist/modules/authorization/selectors'
import { isLoadingType } from '@kmon/dapps/dist/modules/loading/selectors'
import { FETCH_AUTHORIZATIONS_REQUEST } from '@kmon/dapps/dist/modules/authorization/actions'

import { RootState } from '../../../modules/reducer'
import { getWallet, isConnecting } from '../../../modules/wallet/selectors'
import {
  Params,
  MapStateProps,
  MapDispatch,
  MapDispatchProps
} from './SellPage.types'
import SellPage from './SellPage'
import {
  buyItemRequest,
  buyNFTItemRequest,
  fetchItemRequest,
  fetchMyItemsRequest,
  fetchItemTypesRequest,
  fetchAllItemsRequest,
  BUY_ITEM_REQUEST,
  buyItemWithCandiesRequest,
  craftNFTItemRequest,
  fetchMaterialItemTypesRequest,
  fetchItemBalanceRequest,
  createOrderRequest,
  CRAFT_NFT_ITEM_REQUEST,
  BUY_NFT_ITEM_REQUEST,
  TRANSFER_ITEM_REQUEST
} from '../../../modules/item/actions'
import {
  getCurrentItem,
  _getCurrentItem,
  getMyCurrentItem,
  getMyData,
  getUpgradeDefinitions,
  getItemTypes,
  getLoading as getLoadingItem,
  getMaterialItemTypes,
  getItemBalance,
  getCraftingStarted,
  getCraftingFinished,
  getOrderedCurrentItem
} from '../../../modules/item/selectors'
import { Item, AllItem } from '../../../modules/item/types'
import { ItemVersion } from '../../../modules/item/types'

const mapState = (
  state: RootState,
  ownProps: RouteComponentProps<Params>
): MapStateProps => {
  const { address, id, isOwned } = ownProps.match.params
  const { orderedItems } = state.item;
  return {
    authorizations: getAuthorizations(state),
    address: address?.toLowerCase(),
    wallet: getWallet(state),
    isConnecting: isConnecting(state),
    isBuyingItem: isLoadingType(getLoadingItem(state), BUY_NFT_ITEM_REQUEST),
    isTransferringItem: isLoadingType(
      getLoadingItem(state),
      TRANSFER_ITEM_REQUEST
    ),
    itemId: id,
    isOwned,
    currentItem:
      isOwned === '0' ? getMyCurrentItem(state) : _getCurrentItem(state),
    orderedItem: orderedItems,
    itemBalance: getItemBalance(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onRedirect: path => dispatch(replace(path)),
  onFetchMyItems: () => dispatch(fetchMyItemsRequest()),
  onFetchAllItems: () => dispatch(fetchAllItemsRequest()),
  onFetchItemBalance: id => dispatch(fetchItemBalanceRequest(id)),
  onSell: (
    itemId: any,
    name: string,
    price: number,
    numberOfItem: number,
    paymentToken: string,
    expiresAt: number
  ) => dispatch(createOrderRequest(itemId, name, price, numberOfItem, paymentToken, expiresAt)),
  onNavigate: path => dispatch(push(path)),
})

export default connect(mapState, mapDispatch)(SellPage)
