import { BigNumber } from 'ethers'
import { Address } from 'web3x-es/address'

export type Item = {
  itemId: string
  name: string
  price: string
  priceWithCandies: string
  usdPrice: any
}

export type AllItem = {
  itemTypeId: BigNumber
  candiesPrice: BigNumber
  image: string
  isNFT: boolean
  name: string
  rate: BigNumber
  usdPrice: BigNumber
  isOwned: string
}

export type OrderedItem = {
  itemTypeId: BigNumber
  candiesPrice: BigNumber
  image: string
  isNFT: boolean
  name: string
  rate: BigNumber
  usdPrice: BigNumber
  isOwned: string
  paymentToken: Address
  seller: Address
  amount: number
  priceInWei: string
}

export enum ItemVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3'
}

export type Error = {
  code: any
  data: any
  message: any
  stack: any
}
