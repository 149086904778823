import React from 'react'
import { Card } from 'semantic-ui-react'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { KIcon } from '@kmon/ui'
import { Image } from '../../Image'
import { Props } from './OrderItemDetailCard.types'
import './OrderItemDetailCard.css'
import NFTLock from '../../../images/items/nft-lock-icon.svg'
import { BigNumber } from 'ethers'

const OrderItemDetailCard = (props: Props) => {
  const {
    name,
    image,
    price,
    kmonPrice,
    priceWithCandies,
    canBeTransferred,
    isOwned,
    balance,
    isNFT,
    itemType
  } = props
  return (
    <Card className="LootboxCard" link>
      <div className="card-image-container">
        <div className="card-image">
          <Image src={image} />
        </div>
      </div>
      <div className="product-description-container">
        <div className="product-info">
          <div className="product-info-name-container">
            {/* <Row> */}
            <p className="product-info-number-card">
              {t(`nft_item.title.${name}`)}
            </p>
            {/* </Row> */}
          </div>
        </div>
        <div className="product-description">
          <div className="product-description-left">
            <p className="product-description-left-item">
              {t('item_page.item_type')}:
              {/token/i.test(name) ? ' Materials' : ' Power-up'}
            </p>
            <p className="product-description-left-item">
              {/* {t('item_page.type')}: {metaData?.properties?.type} */}
            </p>
          </div>
          {!canBeTransferred && (
            <div className="product-description-right-nft">
              {/* <div className="product-description-right-nft-text">NFT</div> */}
              <img src={NFTLock} />
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default React.memo(OrderItemDetailCard)
