import React, { useCallback, useState, useEffect } from 'react'
import { Card, Button, Loader } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { getAnalytics } from '@kmon/dapps/dist/modules/analytics/utils'

import { getMaxQuerySize, MAX_PAGE, PAGE_SIZE } from '../../modules/vendor/api'
import { NFTCard } from '../NFTCard'
import { Props } from './NFTList.types'
import { LootboxCard } from '../LootboxesPageNFT/LootboxCard'
import { IndexingDelay } from '../IndexingDelayCard'
import { useIndexingDelay } from '../../hooks'
import { BigNumber } from 'ethers'
import { fromWei } from 'web3x-es/utils'

const NFTList = (props: Props) => {
  const {
    vendor,
    nfts,
    page,
    count,
    subgraphTimestamp,
    isLoading,
    isPreventClick,
    myNFT,
    myItems,
    pathname,
    isbreeding,
    onBrowse,
    onClickCard
  } = props
  const { showIndexingDelay } = useIndexingDelay(
    subgraphTimestamp
  )
  const handleLoadMore = useCallback(() => {
    const newPage = page + 1
    if (/^\/breed/gi.test(pathname)) {
      if (myNFT) {
        const newSex = []
        if (parseInt(myNFT?.data.kryptomon!.genes.sex.toString()) > 5) {
          newSex.push('0')
          newSex.push('5')
        } else {
          //male
          newSex.push('6')
          newSex.push('10')
        }
        onBrowse({
          page: newPage,
          isInBreedingCentre: true,
          sex: newSex,
          owner: myNFT.owner
        })
      } else {
        onBrowse({ page: newPage, isInBreedingCentre: true })
      }
    } else {
      onBrowse({ page: newPage })
    }
  }, [onBrowse, page, myNFT, pathname])

  const maxQuerySize = getMaxQuerySize(vendor)

  const hasExtraPages =
    (nfts.length !== count || count === maxQuerySize) && page <= MAX_PAGE

  const isLoadingNewPage = isLoading && nfts.length >= PAGE_SIZE

  return (
    <>
      <Card.Group>
        {showIndexingDelay && <IndexingDelay />}
        {nfts.length > 0 && !/^\/browseItem/gi.test(pathname)
          ? nfts.map((nft, index) => (
            <NFTCard
              key={nft.id + '-' + index}
              nft={nft}
              status={{ showPrice: true }}
              isPreventClick={isPreventClick}
              onClickCard={onClickCard}
              isbreeding={isbreeding}
            />
          ))
          : null}
        {isLoading ? (
          <>
            <div className="overlay" />
            <Loader size="massive" active />
          </>
        ) : null}
      </Card.Group>
      {myItems.length > 0 && /^\/account/gi.test(pathname) || myItems.length > 0 && /^\/browseItem/gi.test(pathname) ? (
        <Card.Group>
          {myItems.map((item: any) => {
            return (
              <LootboxCard
                key={BigNumber.from(item.itemTypeId).toString()}
                itemId={BigNumber.from(item.itemTypeId).toString()}
                name={item.name}
                image={item.image}
                price={fromWei(
                  BigNumber.from(item.usdPrice).toString(),
                  'ether'
                )}
                kmonPrice={fromWei(
                  BigNumber.from(item.usdPrice)
                    .div(BigNumber.from(item.rate))
                    .toString(),
                  'wei'
                )}
                priceWithCandies={
                  item.candiesPrice
                    ? fromWei(
                      BigNumber.from(item.candiesPrice).toString(),
                      'wei'
                    )
                    : '0'
                }
                isNFT={item.isNFT}
                balance={item.balance}
                isOwned={item.isOwned}
                canBeTransferred={item.canBeTransferred}
              />
            )
          })}
        </Card.Group>
      ) : null}

      {nfts.length === 0 && myItems.length === 0 && !isLoading ? (
        <div className="empty">{t('nft_list.empty')}</div>
      ) : null}

      {nfts.length > 0 && hasExtraPages && (!isLoading || isLoadingNewPage) ? (
        <div className="load-more">
          <Button loading={isLoading} primary onClick={handleLoadMore}>
            {t('global.load_more')}
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default React.memo(NFTList)
