export const community = [
  '0x766acd5fbd63ce8791647d49303a55a52cabe5f8',
  '0x616802d1267bbdefcd43e9ee702f16ceef6f255b',
  '0xf63b452c223a05dfa8725a3e23f1b0a4ef539728',
  '0x5325c3bbcbfcff6167311dc03a8b3ef908ff4d1a',
  '0xbf7749cf41c3f2e7bcab1e9d9b7bbf11ff931840',
  '0x3f644b59d54ebedbd231817ed66eb9ed6d191cb0',
  '0xa15ea154b4d0b3aa34105cc9087bdf22e5193552',
  '0xd674fa2a55facafb73d9cc89a5cc47ad77593b61',
  '0x472a444100f3a08e5a8a361c952fe054bdadbfc3',
  '0xce32570e1346071847eee10a658b339a0432062d',
  '0xc33c6dfa5744a7e9e1226a2f6d02417022678543',
  '0xa4ed27feb8bb9b878e382a16a7aed870bd70a41a',
  '0x3c647f719861a323a02733af0f2c35bfaf2c35e4',
  '0x5ba69796a95c68f8548adab0d41057d203d916db',
  '0x5e66259bce7aec3d253560d21869f06214d3039c',
  '0xdc079ff974da0b3cf455dc3ff7c776d5087bb80c',
  '0xcdb4fcbd2af5e7aaa89a8fa41847a9ab501de089',
  '0x7feca9ea9ea15f6cd598ca6cb1e0f29d64063b2d',
  '0x1508b88437230e7cae53d6f91585aea6fa640860',
  '0x34ca04cbb31d710cdafe8e682389682c8411e342',
  '0x7976da01e432969527c103bf236bfc7a5141fae2',
  '0x4dbc4ffc6eba8fd97c6e7d505c524265f9c8bb10',
  '0x6d3fe07c56cee7a7efcbd1676868809f41710b80',
  '0x243304d464619f2e2eed62e9ffa3dacef4b17a62',
  '0x3c1257b0177eb65cdcb8856dbd8f62a2a962171c',
  '0x24ff248a4ddbbe678db0096688da1ef82d6b0765',
  '0x943ab3e78702efccf46f4cd7ab52ccb533c22a29',
  '0x718ff9d629242b40c554b0ea956befccb8fa3fb6',
  '0x243304d464619f2e2eed62e9ffa3dacef4b17a62',
  '0x943ab3e78702efccf46f4cd7ab52ccb533c22a29',
  '0x93b0f3b2cab61bb7f42ae0a520d62e842b26126d',
  '0x934eAa295dC9bD99D7a276bdB7DEED7af2d1587b',
  '0x5421d2212c196c3246a565597131134bc75149a2',
  '0x30A2D9B73C7ef1CB929e524A1C8481A86B14287C',
  '0xd6E608e8baFF4dD4Dee65EeC5d7348781F93530b',
  '0x465A78dA73F768a93004D212215FD33aB02306BB',
  '0xE12578722737d44e0FE43F2Ff33955aa1E99aeAF'
  // '0x47Fd19d68a422c2fC6DefF57aF77C6f94588fCd1'
]
