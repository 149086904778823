import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs, Responsive } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

import { locations } from '../../modules/routing/locations'
import { Props, NavigationTab } from './Navigation.types'
import { Image } from '../../components/Image'
import shop_icon from '../../images/items/Shop.svg'

const Navigation = (props: Props) => {
  const { activeTab, isFullscreen } = props
  const handleClick = () => {
    const dropdownBox = document.querySelector('.dcl.tabs-left')
    console.log('click')
    console.log(dropdownBox)
    dropdownBox?.classList.contains('show')
      ? dropdownBox.classList.remove('show')
      : dropdownBox?.classList.add('show')
  }
  return (
    <Tabs isFullscreen={isFullscreen}>
      <button className="hamburgerBtn" onClick={handleClick}></button>
      <Tabs.Left>
        <Link to={locations.root()}>
          <Tabs.Tab active={activeTab === NavigationTab.HOME}>
            {t('navigation.home')}
          </Tabs.Tab>
        </Link>
        <Link to={locations.browse()}>
          <Tabs.Tab active={activeTab === NavigationTab.BROWSE}>
            {t('navigation.kryptomons')}
          </Tabs.Tab>
        </Link>
        {/* <Link to={locations.browseItem()}>
          <Tabs.Tab active={activeTab === NavigationTab.BROWSEITEM}>
            {t('navigation.items')}
          </Tabs.Tab>
        </Link> */}
        <Link to={locations.bids()}>
          <Tabs.Tab active={activeTab === NavigationTab.MY_BIDS}>
            {t('navigation.my_bids')}
          </Tabs.Tab>
        </Link>
        <Link to={locations.currentAccount({ onlyOnSale: false })}>
          <Tabs.Tab active={activeTab === NavigationTab.MY_ASSETS}>
            {t('navigation.my_assets')}
          </Tabs.Tab>
        </Link>
        <Link to={locations.breed('undefined', 'undefined')}>
          <Tabs.Tab active={activeTab === NavigationTab.BREEDING_CENTER}>
            {t('navigation.breeding_center')}
          </Tabs.Tab>
        </Link>
        {/* <Link to={locations.bnft()}>
          <Tabs.Tab active={activeTab === NavigationTab.BNFT}>
            {t('navigation.bnft')}
          </Tabs.Tab>
        </Link> */}
        {/* <Link to={locations.items()} className="shop">
          <img className="tab-shop-icon" src={shop_icon} />
          <Tabs.Tab active={activeTab === NavigationTab.SHOP}>
            {t('navigation.shop')}
          </Tabs.Tab>
        </Link> */}
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Link to={locations.activity()}>
            <Tabs.Tab active={activeTab === NavigationTab.ACTIVITY}>
              {t('navigation.activity')}
            </Tabs.Tab>
          </Link>
        </Responsive>
      </Tabs.Left>
    </Tabs>
  )
}

export default React.memo(Navigation)
