import { action } from 'typesafe-actions'
import { ChainId } from '@kmon/schemas'
import { buildTransactionPayload } from '@kmon/dapps/dist/modules/transaction/utils'
import { fromWei } from 'web3x-es/utils'
import { BigNumber } from 'ethers'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'

import { Address } from 'web3x-es/address'
import { Item, AllItem } from './types'
import { ItemVersion } from './types'

export const FETCH_ALL_ITEMS_REQUEST = '[Request] Fetch All Items'
export const FETCH_ALL_ITEMS_SUCCESS = '[Success] Fetch All Items'
export const FETCH_ALL_ITEMS_FAILURE = '[Failure] Fetch All Items'

export const fetchAllItemsRequest = () => action(FETCH_ALL_ITEMS_REQUEST, {})
export const fetchAllItemsSuccess = (items: [[], []]) =>
  action(FETCH_ALL_ITEMS_SUCCESS, { items })
export const fetchAllItemsFailure = (error: string) =>
  action(FETCH_ALL_ITEMS_FAILURE, { error })

export type FetchAllItemsRequestAction = ReturnType<typeof fetchAllItemsRequest>
export type FetchAllItemsSuccessAction = ReturnType<typeof fetchAllItemsSuccess>
export type FetchAllItemsFailureAction = ReturnType<typeof fetchAllItemsFailure>

export const FETCH_MY_ITEMS_REQUEST = '[Request] Fetch MY Items'
export const FETCH_MY_ITEMS_SUCCESS = '[Success] Fetch MY Items'
export const FETCH_MY_ITEMS_FAILURE = '[Failure] Fetch MY Items'

export const fetchMyItemsRequest = () => action(FETCH_MY_ITEMS_REQUEST, {})
export const fetchMyItemsSuccess = (items: []) =>
  action(FETCH_MY_ITEMS_SUCCESS, { items })
export const fetchMyItemsFailure = (error: string) =>
  action(FETCH_MY_ITEMS_FAILURE, { error })

export type FetchMyItemsRequestAction = ReturnType<typeof fetchMyItemsRequest>
export type FetchMyItemsSuccessAction = ReturnType<typeof fetchMyItemsSuccess>
export type FetchMyItemsFailureAction = ReturnType<typeof fetchMyItemsFailure>

export const FETCH_ORDERED_ITEMS_REQUEST = '[Request] Fetch Ordered Items'
export const FETCH_ORDERED_ITEMS_SUCCESS = '[Success] Fetch Ordered Items'
export const FETCH_ORDERED_ITEMS_FAILURE = '[Failure] Fetch Ordered Items'

export const fetchOrderedItemsRequest = (params: any) =>
  action(FETCH_ORDERED_ITEMS_REQUEST, params)
export const fetchOrderedItemsSuccess = (items: []) =>
  action(FETCH_ORDERED_ITEMS_SUCCESS, { items })
export const fetchOrderedItemsFailure = (error: string) =>
  action(FETCH_ORDERED_ITEMS_FAILURE, { error })

export type FetchOrderedItemsRequestAction = ReturnType<typeof fetchOrderedItemsRequest>
export type FetchOrderedItemsSuccessAction = ReturnType<typeof fetchOrderedItemsSuccess>
export type FetchOrderedItemsFailureAction = ReturnType<typeof fetchOrderedItemsFailure>

export const FETCH_ORDERED_ITEMS_GROUP_SUCCESS = '[Success] Fetch Ordered Items Group'

export const fetchOrderedItemsGroupSuccess = (items: []) =>
  action(FETCH_ORDERED_ITEMS_GROUP_SUCCESS, { items })

export type FetchOrderedItemsGroupSuccessAction = ReturnType<typeof fetchOrderedItemsGroupSuccess>

export const FETCH_ITEMS_REQUEST = '[Request] Fetch Items'
export const FETCH_ITEMS_SUCCESS = '[Success] Fetch Items'
export const FETCH_ITEMS_FAILURE = '[Failure] Fetch Items'

export const fetchItemsRequest = () => action(FETCH_ITEMS_REQUEST, {})
export const fetchItemsSuccess = (items: Item[]) =>
  action(FETCH_ITEMS_SUCCESS, { items })
export const fetchItemsFailure = (error: string) =>
  action(FETCH_ITEMS_FAILURE, { error })

export type FetchItemsRequestAction = ReturnType<typeof fetchItemsRequest>
export type FetchItemsSuccessAction = ReturnType<typeof fetchItemsSuccess>
export type FetchItemsFailureAction = ReturnType<typeof fetchItemsFailure>

export const FETCH_ITEM_REQUEST = '[Request] Fetch Item'
export const FETCH_ITEM_SUCCESS = '[Success] Fetch Item'
export const FETCH_ITEM_FAILURE = '[Failure] Fetch Item'

export const fetchItemRequest = () => action(FETCH_ITEM_REQUEST, {})
export const fetchItemSuccess = () => action(FETCH_ITEM_SUCCESS, {})
export const fetchItemFailure = (error: string) =>
  action(FETCH_ITEM_FAILURE, { error })

export type FetchItemRequestAction = ReturnType<typeof fetchItemRequest>
export type FetchItemSuccessAction = ReturnType<typeof fetchItemSuccess>
export type FetchItemFailureAction = ReturnType<typeof fetchItemFailure>

export const FETCH_ITEM_BALANCE_REQUEST = '[Request] Fetch item balance by id'
export const FETCH_ITEM_BALANCE_SUCCESS = '[Success] Fetch item balance by id'
export const FETCH_ITEM_BALANCE_FAILURE = '[Failure] Fetch item balance by id'

export const fetchItemBalanceRequest = (id: any) =>
  action(FETCH_ITEM_BALANCE_REQUEST, { id })
export const fetchItemBalanceSuccess = (items: []) =>
  action(FETCH_ITEM_BALANCE_SUCCESS, { items })
export const fetchItemBalanceFailure = (error: string) =>
  action(FETCH_ITEM_BALANCE_FAILURE, { error })

export type FetchItemBalanceRequestAction = ReturnType<
  typeof fetchItemBalanceRequest
>
export type FetchItemBalanceSuccessAction = ReturnType<
  typeof fetchItemBalanceSuccess
>
export type FetchItemBalanceFailureAction = ReturnType<
  typeof fetchItemBalanceFailure
>

export const FETCH_ITEM_TYPES_REQUEST = '[Request] Fetch item types'
export const FETCH_ITEM_TYPES_SUCCESS = '[Success] Fetch item types'
export const FETCH_ITEM_TYPES_FAILURE = '[Failure] Fetch item types'

export const fetchItemTypesRequest = (ids: any) =>
  action(FETCH_ITEM_TYPES_REQUEST, { ids })
export const fetchItemTypesSuccess = (items: []) =>
  action(FETCH_ITEM_TYPES_SUCCESS, { items })
export const fetchItemTypesFailure = (error: string) =>
  action(FETCH_ITEM_TYPES_FAILURE, { error })

export type FetchItemTypesRequestAction = ReturnType<
  typeof fetchItemTypesRequest
>
export type FetchItemTypesSuccessAction = ReturnType<
  typeof fetchItemTypesSuccess
>
export type FetchItemTypesFailureAction = ReturnType<
  typeof fetchItemTypesFailure
>

export const FETCH_MATERIAL_ITEM_TYPES_REQUEST =
  '[Request] Fetch Material item types'
export const FETCH_MATERIAL_ITEM_TYPES_SUCCESS =
  '[Success] Fetch Material item types'
export const FETCH_MATERIAL_ITEM_TYPES_FAILURE =
  '[Failure] Fetch Material item types'

export const fetchMaterialItemTypesRequest = (ids: any) =>
  action(FETCH_MATERIAL_ITEM_TYPES_REQUEST, { ids })
export const fetchMaterialItemTypesSuccess = (items: []) =>
  action(FETCH_MATERIAL_ITEM_TYPES_SUCCESS, { items })
export const fetchMaterialItemTypesFailure = (error: string) =>
  action(FETCH_MATERIAL_ITEM_TYPES_FAILURE, { error })

export type FetchMaterialItemTypesRequestAction = ReturnType<
  typeof fetchMaterialItemTypesRequest
>
export type FetchMaterialItemTypesSuccessAction = ReturnType<
  typeof fetchMaterialItemTypesSuccess
>
export type FetchMaterialItemTypesFailureAction = ReturnType<
  typeof fetchMaterialItemTypesFailure
>

export const BUY_ITEM_REQUEST = '[Request] Buy item'
export const BUY_ITEM_SUCCESS = '[Success] Buy item'
export const BUY_ITEM_FAILURE = '[Failure] Buy item'

export const buyItemRequest = (
  version: ItemVersion,
  item: Item,
  count: number,
  to: Address
) => action(BUY_ITEM_REQUEST, { version, item, count, to })
export const buyItemSuccess = (
  chainId: ChainId,
  txHash: string,
  item: any,
  count: number,
  to: Address
) => (
  window.analytics.track('Bought item', {
    name: item.name,
    amount: count,
    price: item.usdPrice,
    revenue: count * item.usdPrice
  }),
  action(BUY_ITEM_SUCCESS, {
    item,
    count,
    to,
    ...buildTransactionPayload(chainId, txHash, {
      chainId,
      txHash,
      item,
      count,
      to
    })
  })
)

export const buyItemFailure = (itemId: string, error: string) =>
  action(BUY_ITEM_FAILURE, {
    itemId,
    error
  })

export type BuyItemRequestAction = ReturnType<typeof buyItemRequest>
export type BuyItemSuccessAction = ReturnType<typeof buyItemSuccess>
export type BuyItemFailureAction = ReturnType<typeof buyItemFailure>

export const BUY_NFT_ITEM_REQUEST = '[Request] Buy NFT item'
export const BUY_NFT_ITEM_SUCCESS = '[Success] Buy NFT item'
export const BUY_NFT_ITEM_FAILURE = '[Failure] Buy NFT item'

export const buyNFTItemRequest = (
  version: ItemVersion,
  item: AllItem,
  count: number,
  to: Address,
  withCandies: boolean = false
) => action(BUY_NFT_ITEM_REQUEST, { version, item, count, to, withCandies })
export const buyNFTItemSuccess = (item: AllItem, count: number) => (
  window.analytics.track('Bought item', {
    name: item.name,
    amount: count,
    price: fromWei(BigNumber.from(item.usdPrice).toString(), 'ether'),
    revenue:
      count *
      parseInt(fromWei(BigNumber.from(item.usdPrice).toString(), 'ether'))
  }),
  action(BUY_NFT_ITEM_SUCCESS, { item })
)
export const buyNFTItemFailure = (itemId: any, error: string) =>
  action(BUY_NFT_ITEM_FAILURE, {
    itemId,
    error
  })

export type BuyNFTItemRequestAction = ReturnType<typeof buyNFTItemRequest>
export type BuyNFTItemSuccessAction = ReturnType<typeof buyNFTItemSuccess>
export type BuyNFTItemFailureAction = ReturnType<typeof buyNFTItemFailure>

export const BUY_ITEM_WITH_CANDIES_REQUEST = '[Request] Buy item with candies'
export const BUY_ITEM_WITH_CANDIES_SUCCESS = '[Success] Buy item with candies'
export const BUY_ITEM_WITH_CANDIES_FAILURE = '[Failure] Buy item with candies'

export const buyItemWithCandiesRequest = (
  version: ItemVersion,
  item: Item,
  count: number,
  to: Address
) => action(BUY_ITEM_WITH_CANDIES_REQUEST, { version, item, count, to })
export const buyItemWithCandiesSuccess = (
  chainId: ChainId,
  txHash: string,
  item: Item,
  count: number,
  to: Address
) =>
  action(BUY_ITEM_WITH_CANDIES_SUCCESS, {
    item,
    count,
    to,
    ...buildTransactionPayload(chainId, txHash, {
      chainId,
      txHash,
      item,
      count,
      to
    })
  })
export const buyItemWithCandiesFailure = (itemId: string, error: string) =>
  action(BUY_ITEM_WITH_CANDIES_FAILURE, {
    itemId,
    error
  })

export type BuyItemWithCandiesRequestAction = ReturnType<
  typeof buyItemWithCandiesRequest
>
export type BuyItemWithCandiesSuccessAction = ReturnType<
  typeof buyItemWithCandiesSuccess
>
export type BuyItemWithCandiesFailureAction = ReturnType<
  typeof buyItemWithCandiesFailure
>

export const CRAFT_NFT_ITEM_REQUEST = '[Request] Craft NFT item'
export const CRAFT_NFT_ITEM_SUCCESS = '[Success] Craft NFT item'
export const CRAFT_NFT_ITEM_FAILURE = '[Failure] Craft NFT item'

export const craftNFTItemRequest = (param: any) =>
  action(CRAFT_NFT_ITEM_REQUEST, { param })
export const craftNFTItemSuccess = (param: any) =>
  action(CRAFT_NFT_ITEM_SUCCESS, { param })
export const craftNFTItemFailure = (error: string) =>
  action(CRAFT_NFT_ITEM_FAILURE, { error })

export type CraftNFTItemRequestAction = ReturnType<typeof craftNFTItemRequest>
export type CraftNFTItemSuccessAction = ReturnType<typeof craftNFTItemSuccess>
export type CraftNFTItemFailureAction = ReturnType<typeof craftNFTItemFailure>

export const CRAFT_NFT_ITEM_START = '[Request] Craft NFT item start'
export const CRAFT_NFT_ITEM_FINISH = '[Request] Craft NFT item finish'

export const craftNFTItemStart = () => action(CRAFT_NFT_ITEM_START)
export const craftNFTItemFinish = () => action(CRAFT_NFT_ITEM_FINISH)

export type CraftNFTItemStartAction = ReturnType<typeof craftNFTItemStart>
export type CraftNFTItemFinishAction = ReturnType<typeof craftNFTItemFinish>

export const TRANSFER_ITEM_REQUEST = '[Request] Transfer Item'
export const TRANSFER_ITEM_SUCCESS = '[Success] Transfer Item'
export const TRANSFER_ITEM_FAILURE = '[Failure] Transfer Item'

export const transferItemRequest = (param: any) =>
  action(TRANSFER_ITEM_REQUEST, { param })
export const transferItemSuccess = (
  chainId: ChainId,
  txHash: string,
  tokenId: any,
  address: any,
  to: any,
  image: any
) =>
  action(TRANSFER_ITEM_SUCCESS, {
    tokenId,
    to,
    image,
    ...buildTransactionPayload(chainId, txHash, {
      chainId,
      txHash,
      tokenId,
      address,
      image
    })
  })
export const transferItemFailure = (
  tokenId: any,
  address: string,
  error: string
) => action(TRANSFER_ITEM_FAILURE, { tokenId, address, error })

export type TransferItemRequestAction = ReturnType<typeof transferItemRequest>
export type TransferItemSuccessAction = ReturnType<typeof transferItemSuccess>
export type TransferItemFailureAction = ReturnType<typeof transferItemFailure>

// Create Order (aka Sell)

export const CREATE_ORDER_REQUEST = '[Request] Create Order 1155'
export const CREATE_ORDER_SUCCESS = '[Success] Create Order 1155'
export const CREATE_ORDER_FAILURE = '[Failure] Create Order 1155'

export const createOrderRequest = (
  itemId: any,
  name: any,
  price: number,
  numberOfItem: number,
  paymentToken: string,
  expiresAt: number
) => action(CREATE_ORDER_REQUEST, { itemId, name, price, numberOfItem, paymentToken, expiresAt })

export const createOrderSuccess = (
  name: string,
  itemId: string,
  image: any,
  price: number,
  numberOfItem: number,
  paymentToken: string,
  expiresAt: number,
  txHash: string
) =>
  action(CREATE_ORDER_SUCCESS, {
    name,
    itemId,
    image,
    price,
    numberOfItem,
    paymentToken,
    expiresAt,
    ...buildTransactionPayload(Number(getConnectedProviderChainId()), txHash, {
      tokenId: itemId,
      contractAddress: "0x00",
      paymentToken,
      network: "97",
      price,
      numberOfItem,
      name,
      image
    })
  })
export const createOrderFailure = (
  itemId: any,
  price: number,
  numberOfItem: number,
  paymentToken: string,
  expiresAt: number,
  error: string
) => action(CREATE_ORDER_FAILURE, { itemId, price, numberOfItem, paymentToken, expiresAt, error })

export type CreateOrderRequestAction = ReturnType<typeof createOrderRequest>
export type CreateOrderSuccessAction = ReturnType<typeof createOrderSuccess>
export type CreateOrderFailureAction = ReturnType<typeof createOrderFailure>

// Execute Order (aka Buy)

export const EXECUTE_ORDER_REQUEST = '[Request] Execute Order 1155'
export const EXECUTE_ORDER_SUCCESS = '[Success] Execute Order 1155'
export const EXECUTE_ORDER_FAILURE = '[Failure] Execute Order 1155'

export const executeOrderRequest = (
  itemId: any,
  tokenName: any,
  seller: string,
  price: string,
  paymentToken: string
) => action(EXECUTE_ORDER_REQUEST, { itemId, tokenName, seller, price, paymentToken })

export const executeOrderSuccess = (
  tokenName: any,
  seller: string,
  price: string,
  paymentToken: string,
  image: any,
  txHash: string,
  buyer: any,
) =>
  action(EXECUTE_ORDER_SUCCESS, {
    tokenName,
    seller,
    price,
    paymentToken,
    image,
    txHash,
    buyer,
    ...buildTransactionPayload(Number(getConnectedProviderChainId()), txHash, {
      tokenName, seller, price, paymentToken, image
    })
  })

export const executeOrderFailure = (itemId: any, seller: string, price: number, paymentToken: string, error: string) =>
  action(EXECUTE_ORDER_FAILURE, { itemId, seller, price, paymentToken, error })

export type ExecuteOrderRequestAction = ReturnType<typeof executeOrderRequest>
export type ExecuteOrderSuccessAction = ReturnType<typeof executeOrderSuccess>
export type ExecuteOrderFailureAction = ReturnType<typeof executeOrderFailure>

// // Cancel Order (aka Cancel Sale)

export const CANCEL_ORDER_REQUEST = '[Request] Cancel Order 1155'
export const CANCEL_ORDER_SUCCESS = '[Success] Cancel Order 1155'
export const CANCEL_ORDER_FAILURE = '[Failure] Cancel Order 1155'

export const cancelOrderRequest = (itemId: any, name: any) =>
  action(CANCEL_ORDER_REQUEST, { itemId, name })
export const cancelOrderSuccess = (itemName: any, image: any, txHash: string) =>
  action(CANCEL_ORDER_SUCCESS, {
    itemName,
    image,
    ...buildTransactionPayload(Number(getConnectedProviderChainId()), txHash, {
      itemName, image
    })
  })
export const cancelOrderFailure = (itemId: any, error: string) =>
  action(CANCEL_ORDER_FAILURE, { itemId, error })

export type CancelOrderRequestAction = ReturnType<typeof cancelOrderRequest>
export type CancelOrderSuccessAction = ReturnType<typeof cancelOrderSuccess>
export type CancelOrderFailureAction = ReturnType<typeof cancelOrderFailure>
