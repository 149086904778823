/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useCallback, useEffect } from 'react'
import { Header, Button } from '@kmon/ui'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'
import { Coin, Network } from '@kmon/schemas'
import { Address } from 'web3x-es/address'
import { ContractName } from '@kmon/transactions'
import { locations } from '../../../modules/routing/locations'
import { getNFTName } from '../../../modules/nft/utils'
import { useFingerprint, useComputedPrice } from '../../../modules/nft/hooks'
import { NFT } from '../../../modules/nft/types'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { NFTAction } from '../../NFTAction'
import { CoinPopup } from '../../CoinPopup'
import { AuthorizationModal } from '../../AuthorizationModal'
import { Props } from './BuyModal.types'
import { formatCoin } from '../../../lib/kmon'
import { USD_PAYMENT_TOKEN } from '../../../modules/order/utils'

const SimplexComponent = () => {
  return (
    <div className="payment">
      <div className="header">{t('buy_page.buy_credit_card')}</div>
      <form
        id="simplex-form"
        style={{
          backgroundColor: '#000',
          borderRadius: '30px',
          boxShadow: 'rgb(0 0 0 / 10%) 3px 3px 10px 4px',
          width: 'auto',
          height: 'auto',
          color: '#fff'
        }}
      >
        <div id="checkout-element"></div>
      </form>
    </div>
  )
}

const BuyPage = (props: Props) => {
  const {
    nft,
    order,
    wallet,
    authorizations,
    isLoading,
    onNavigate,
    onExecuteOrder,
    isOwner,
    hasInsufficientCoin
  } = props

  const [fingerprint, isFingerprintLoading] = useFingerprint(nft)
  const [
    computedPrice,
    percentageIncrease,
    isAboveMaxPercentage
  ] = useComputedPrice(nft, order)
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)
  const [wantsToProceed, setWantsToProceed] = useState(false)
  const [isFormCreated, setIsFormCreated] = useState(false)
  const [isInit, setIsInit] = useState(false)

  function SimplexInit() {
    const Simplex = window.Simplex
    setIsInit(true)
    window.simplexAsyncFunction = function () {
      console.log('Init-->')
      Simplex.init({
        public_key: 'pk_live_35f0a2a6-27ec-4f5b-867d-25709e5931c2'
      })
    }
  }

  function SandBoxInit() {
    const _order: any = order
    const simplex = window.simplex
    const coin =
      order && _order.paymentToken === Address.ZERO.toString()
        ? Coin.BNB
        : _order.paymentToken === USD_PAYMENT_TOKEN
          ? Coin.USD
          : Coin.KMON

    simplex.createForm().then(function (data: any) {
      setIsFormCreated(true)
      console.log('Created-->')
      const currencies = data.supportedCryptoCurrencies
      order && simplex.updateCryptoCurrency(coin)
    })
  }

  useEffect(() => {
    if (order) {
      var scriptURL1 = 'https://cdn.simplex.com/sdk/v1/js/sdk.js'
      var scriptURL2 = 'https://iframe.simplex-affiliates.com/form-sdk.js'
      var scriptURL3 = 'https://checkout.simplexcc.com/splx.js'

      var script1 = document.createElement('script')
      var script2 = document.createElement('script')
      var script3 = document.createElement('script')

      script1.async = true
      script1.src = scriptURL1
      document.body.appendChild(script1)

      script2.async = true
      script2.src = scriptURL2
      document.body.appendChild(script2)

      script3.async = true
      script3.src = scriptURL3
      document.body.appendChild(script3)

      script1.onload = SimplexInit
      script2.onload = SandBoxInit

      return () => {
        document.body.removeChild(script1)
        document.body.removeChild(script2)
        document.body.removeChild(script3)
      }
    }
  }, [])

  const handleExecuteOrder = useCallback(() => {
    if (order === null) return
    onExecuteOrder(order, nft, order.paymentToken, fingerprint)
  }, [order, nft, fingerprint, onExecuteOrder])

  if (!wallet) {
    return null
  }
  const contractNames = getContractNames()
  const kmon = getContract({
    name: contractNames.KMONToken
  })
  const marketplace = getContract({
    name: contractNames.MARKETPLACE
  })
  const authorization: Authorization = {
    address: wallet.address,
    authorizedAddress: marketplace.address,
    contractAddress: kmon.address,
    contractName: ContractName.KMONToken,
    chainId: nft.chainId,
    type: AuthorizationType.ALLOWANCE
  }

  const handleToggleWantsToProceed = () => {
    setWantsToProceed(!wantsToProceed)
  }

  const handleSubmit = () => {
    if (
      order?.paymentToken === Address.ZERO.toString() ||
      hasAuthorization(authorizations, authorization)
    ) {
      handleExecuteOrder()
    } else {
      setShowAuthorizationModal(true)
    }
  }

  const handleClose = () => setShowAuthorizationModal(false)

  const isDisabled = !order || isOwner || hasInsufficientCoin
  // || (!fingerprint && nft.category === NFTCategory.KRYPTOMON)

  const name = <Name nft={nft} />

  let subtitle = null
  if (!order) {
    subtitle = <T id={'buy_page.not_for_sale'} values={{ name }} />
  }
  // else if (
  //   !fingerprint &&
  //   nft.category === NFTCategory.KRYPTOMON &&
  //   !isFingerprintLoading
  // ) {
  //   subtitle = <T id={'buy_page.no_fingerprint'} />
  // }
  else if (isOwner) {
    subtitle = <T id={'buy_page.is_owner'} values={{ name }} />
  } else if (hasInsufficientCoin) {
    const coin =
      order.paymentToken === Address.ZERO.toString() ? Coin.BNB :
        order.paymentToken === USD_PAYMENT_TOKEN
          ? Coin.USD : Coin.KMON
    subtitle = (
      <T
        id={'buy_page.not_enough_coin'}
        values={{
          coin: coin,
          name,
          amount: (
            <Price network={nft.network} price={order.price} coin={coin} />
          )
        }}
      />
    )
  } else {
    subtitle = (
      <T
        id={'buy_page.subtitle'}
        values={{
          name,
          amount: (
            <Price
              network={nft.network}
              price={order.price}
              coin={
                order.paymentToken === Address.ZERO.toString()
                  ? Coin.BNB
                  : order.paymentToken === USD_PAYMENT_TOKEN.toLowerCase()
                    ? Coin.USD
                    : Coin.KMON
              }
            />
          )
        }}
      />
    )
  }

  return (
    <NFTAction nft={nft}>
      <Header size="large">
        {t('buy_page.title', { category: t(`global.${nft.category}`) })}
      </Header>
      <div className={isDisabled ? 'error' : ''}>{subtitle}</div>
      <div className="buttons">
        <Button
          onClick={() =>
            onNavigate(locations.nft(nft.contractAddress, nft.tokenId))
          }
        >
          {t('global.cancel')}
        </Button>

        {isDisabled ||
          !isAboveMaxPercentage ||
          (isAboveMaxPercentage && wantsToProceed) ? (
          <Button
            primary
            disabled={isDisabled || isLoading}
            onClick={handleSubmit}
            loading={isLoading}
          >
            {t('buy_page.buy')}
          </Button>
        ) : (
          <Button
            primary
            onClick={handleToggleWantsToProceed}
            loading={isLoading}
          >
            {t('buy_page.proceed_anyways')}
          </Button>
        )}
      </div>
      {hasInsufficientCoin && !isOwner && <SimplexComponent />}
      <AuthorizationModal
        open={showAuthorizationModal}
        authorization={authorization}
        onProceed={handleExecuteOrder}
        onCancel={handleClose}
      />
    </NFTAction>
  )
}

const Name = (props: { nft: NFT }) => <b>{getNFTName(props.nft)}</b>

const Price = (props: { network?: Network; price: string; coin: Coin }) => (
  <CoinPopup network={props.network} inline withTooltip coin={props.coin}>
    {formatCoin(props.price)}
  </CoinPopup>
)

export default React.memo(BuyPage)
