import { useEffect, useState } from 'react'
import { Eth } from 'web3x-es/eth'
import { getConnectedProvider } from '@kmon/dapps/dist/lib/eth'

import { convertTime } from '../lib/date'

export const useIndexingDelay = (subgraphTimestamp: number | undefined) => {
  const [showIndexingDelay, setShowIndexingDelay] = useState(false)
  const [indexingDelay, setIndexingDelay] = useState<string | null>(null)

  useEffect(() => {
    if (subgraphTimestamp) {
      fetchIndexingDelay()
    } else {
      setShowIndexingDelay(false)
      setIndexingDelay(null)
    }
  }, [subgraphTimestamp])


  const fetchIndexingDelay = async () => {
    const currentTimestamp = Date.now() / 1000;
    if (!subgraphTimestamp) {
      return;
    }
    const delay = currentTimestamp - subgraphTimestamp;

    if (delay > 60) {
      setShowIndexingDelay(true)
      setIndexingDelay(convertTime(delay))
    } else {
      setShowIndexingDelay(false)
      setIndexingDelay(null)
    }
  }

  return { showIndexingDelay, indexingDelay }
}