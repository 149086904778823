import { put, call, takeEvery, select, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { getChainId } from '@kmon/dapps/dist/modules/wallet/selectors'
import { ChainId } from '@kmon/schemas'
import {
  FETCH_BNFT_BALANCE_REQUEST,
  FETCH_COMMUNITY_PRODUCTS_REQUEST,
  FETCH_COMMUNITY_ORDER_REQUEST,
  FETCH_ORDER_DETAILS_REQUEST,
  FetchBNFTBalanceRequestAction,
  fetchBNFTBalanceSuccess,
  fetchBNFTBalanceFailure,
  fetchCommunityProductsSuccess,
  fetchCommunityProductsFailure,
  fetchCommunityOrderSuccess,
  fetchCommunityOrderFailure,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsFailure
} from './actions'
import { locations } from '../routing/locations'
import {
  fetchBNFTBalance,
  fetchCommunityProducts,
  fetchCommunityOrder,
  getOrderDetails
} from './utils'
import { getWallet } from '../wallet/selectors'
import { getData as getItems } from './selectors'
import { BNFTItem } from './types'

export function* bNFTSaga() {
  yield takeEvery(FETCH_BNFT_BALANCE_REQUEST, handleFetchBNFTBalanceRequest)
  yield takeEvery(
    FETCH_COMMUNITY_PRODUCTS_REQUEST,
    handleFetchCommunityProductsRequest
  )
  yield takeEvery(
    FETCH_COMMUNITY_ORDER_REQUEST,
    handleFetchCommunityOrderRequest
  )
  yield takeEvery(FETCH_ORDER_DETAILS_REQUEST, handleFetchOrderDetailsRequest)
}

function* handleFetchBNFTBalanceRequest() {
  // const { version, item, count, to } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const result: BNFTItem[] = yield call(fetchBNFTBalance, wallet!)
    yield put(fetchBNFTBalanceSuccess(result))
  } catch (error) {
    // @ts-ignore
    yield put(fetchBNFTBalanceFailure(error.message))
  }
}

function* handleFetchCommunityProductsRequest() {
  // const { version, item, count, to } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const result: BNFTItem[] = yield call(fetchCommunityProducts, wallet!)
    yield put(fetchCommunityProductsSuccess(result))
  } catch (error) {
    // @ts-ignore
    yield put(fetchCommunityProductsFailure(error.message))
  }
}

function* handleFetchOrderDetailsRequest(options: any) {
  const { orderId, email } = options.payload.options
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const result: BNFTItem[] = yield call(
      getOrderDetails,
      wallet!,
      orderId,
      email
    )
    yield put(fetchOrderDetailsSuccess(result))
  } catch (error) {
    // @ts-ignore
    yield put(fetchOrderDetailsFailure(error.message))
  }
}

function* handleFetchCommunityOrderRequest(options: any) {
  const { orderId, email } = options.payload.options
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const result: BNFTItem[] = yield call(
      fetchCommunityOrder,
      wallet!,
      orderId,
      email
    )
    yield put(fetchCommunityOrderSuccess(result))
  } catch (error) {
    // @ts-ignore
    yield put(fetchCommunityOrderFailure(error.message))
  }
}
