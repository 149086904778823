import React, { useEffect } from 'react'
import { Card, Page } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { fromWei } from 'web3x-es/utils'
import { BigNumber } from 'ethers'
import { NavigationTab } from '../Navigation/Navigation.types'
import { locations } from '../../modules/routing/locations'
import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import { Navigation } from '../Navigation'
import { Props } from './LootboxesPage.types'
import { LootboxCard } from './LootboxCard'
import { LootboxCard as NFTItemCard } from '../LootboxesPageNFT/LootboxCard'
import { Header } from '../Header'
import basicLootbox from '../../images/items/lootbox_basic.png'
import mediumLootbox from '../../images/items/lootbox_medium.png'
import premiumLootbox from '../../images/items/lootbox_premium.png'
import unfreezePotion from '../../images/items/unfreeze_potion.png'
import battleStaminaPotion from '../../images/items/battle_stamina_potion.png'
import diamondStoryChest from '../../images/items/diamond_story_chest.png'
import Lock from '../../images/lock.svg'
import { FooterImage } from '../FooterImage'
import './LootboxesPage.css'
import battleLeaguePass from '../../images/items/battle-league-pass.png'


export const images: Record<string, string> = {
  lootbox_basic: basicLootbox,
  lootbox_medium: mediumLootbox,
  lootbox_premium: premiumLootbox,
  unfreeze_potion: unfreezePotion,
  battle_stamina_potion: battleStaminaPotion,
  diamond_story_chest: diamondStoryChest,
  battle_league_pass: battleLeaguePass
}

const LootboxesPage = (props: Props) => {
  const {
    wallet,
    isConnecting,
    isFullscreen,
    items,
    allItems,
    onRedirect,
    onFetchItems
  } = props

  const isConnected = !!wallet

  useEffect(() => {
    if (isConnected) {
      onFetchItems()
    } else {
      onRedirect(locations.signIn('items'))
    }
  }, [isConnected])

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation
          activeTab={NavigationTab.ITEMS}
          isFullscreen={isFullscreen}
        />
      </div>
      <Header
        title={t('item_page.title')}
        subTitle={
          <div>
            <div>{t('item_page.sub_title')}</div>
            <div className="second-container">
              <img src={Lock} />
              <div>
                <div className="second-bold">{t('item_page.bounded_nft')}</div>
                {': '}
                {t('item_page.not_transferable')}
              </div>
            </div>
          </div>
        }
        wallet={wallet}
      />
      <Page className="NFTBrowse">
        <Card.Group>
          {Object.values(items).map(item => (
            <LootboxCard
              key={item.itemId}
              itemId={item.itemId}
              name={item.name}
              image={item.name.indexOf("-") > -1 ? images[item.name.toLocaleLowerCase().substring(0, item.name.indexOf("-"))] : images[item.name.toLocaleLowerCase()]}
              price={fromWei(item.price, 'ether')}
              priceWithCandies={
                item.priceWithCandies
                  ? fromWei(item.priceWithCandies, 'ether')
                  : '0'
              }
              usdPrice={item.usdPrice}
            />
          ))}
          {allItems.map((item: any) => {
            return (
              <NFTItemCard
                key={BigNumber.from(item.itemTypeId).toString()}
                itemId={BigNumber.from(item.itemTypeId).toString()}
                name={item.name}
                image={item.image}
                price={fromWei(
                  BigNumber.from(item.usdPrice).toString(),
                  'ether'
                )}
                kmonPrice={fromWei(
                  BigNumber.from(item.usdPrice)
                    .div(BigNumber.from(item.rate))
                    .toString(),
                  'wei'
                )}
                priceWithCandies={fromWei(
                  BigNumber.from(item.candiesPrice).toString(),
                  'ether'
                )}
                isNFT={item.isNFT}
                balance={item.balance}
                isOwned={item.isOwned}
                canBeTransferred={item.canBeTransferred}
              />
            )
          })}
        </Card.Group>
        <div className="lootbox-container"></div>
        <FooterImage />
      </Page>
      <Footer isFullscreen={isFullscreen} />
    </>
  )
}

export default React.memo(LootboxesPage)
