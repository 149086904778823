import React from 'react'
import { useHistory } from 'react-router-dom'
import { LoginModal } from '@kmon/dapps/dist/containers'
import { Page } from '@kmon/ui'

import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import './SignInPage.css'

const SignInPage = () => {
  const history = useHistory()

  const onClose = () => {
    history.goBack()
  }

  return (
    <>
      <Navbar isFullscreen />
      <Page className="SignInPage" isFullscreen>
        <LoginModal
          open={true}
          onClose={onClose}
        />
      </Page>
      <Footer isFullscreen />
    </>
  )
}

export default React.memo(SignInPage)
