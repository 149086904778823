import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { RouteComponentProps } from 'react-router'
import { Address } from 'web3x-es/address'
import {
  getData as getAuthorizations,
  getLoading as getLoadingAuthorizations
} from '@kmon/dapps/dist/modules/authorization/selectors'
import { isLoadingType } from '@kmon/dapps/dist/modules/loading/selectors'
import {
  FETCH_AUTHORIZATIONS_REQUEST,
  grantTokenRequest
} from '@kmon/dapps/dist/modules/authorization/actions'

import { RootState } from '../../../modules/reducer'
import { getWallet, isConnecting } from '../../../modules/wallet/selectors'
import {
  Params,
  MapStateProps,
  MapDispatch,
  MapDispatchProps
} from './BNFTDetail.types'
import LootboxDetail from './BNFTDetail'
import {
  fetchBNFTBalanceRequest,
  saveBNFTCheckoutRequest,
  FETCH_BNFT_BALANCE_REQUEST
} from '../../../modules/bnft/actions'
import {
  getCurrentItem,
  getLoading as getLoadingItem,
  getBNFTBalance,
  getBNFTTokenIds,
  getBNFTCheckout,
  getBNFTTokenBalances,
  getOwnedNFTs
} from '../../../modules/bnft/selectors'
import { Item } from '../../../modules/item/types'
import { ItemVersion } from '../../../modules/item/types'

const mapState = (
  state: RootState,
  ownProps: RouteComponentProps<Params>
): MapStateProps => {
  const { address, id } = ownProps.match.params

  return {
    address: address?.toLowerCase(),
    wallet: getWallet(state),
    authorizations: getAuthorizations(state),
    isConnecting: isConnecting(state),
    isLoading: isLoadingType(
      getLoadingAuthorizations(state),
      FETCH_AUTHORIZATIONS_REQUEST
    ),
    itemId: id,
    currentItem: getCurrentItem(state),
    bnftBalance: getBNFTBalance(state),
    bnftTokenIds: getBNFTTokenIds(state),
    bnftCheckout: getBNFTCheckout(state),
    ownedNFTs: getOwnedNFTs(state),
    bnftTokenBalances: getBNFTTokenBalances(state)
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onRedirect: path => dispatch(replace(path)),
  onFetchBNFTBalance: () => dispatch(fetchBNFTBalanceRequest()),
  onSaveBNFTCheckout: checkout => dispatch(saveBNFTCheckoutRequest(checkout)),
  onGrant: authorization => dispatch(grantTokenRequest(authorization))
})

export default connect(mapState, mapDispatch)(LootboxDetail)
