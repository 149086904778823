import React, { useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Page, Loader } from '@kmon/ui'
import { Icon } from 'semantic-ui-react'
import { Profile } from '@kmon/dapps/dist/containers'
import { isMobile } from '@kmon/dapps/dist/lib/utils'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

import { View } from '../../modules/ui/types'
import { useTimer } from '../../lib/timer'
import { Navbar } from '../Navbar'
import { PageHeader } from '../PageHeader'
import { Footer } from '../Footer'
import { NFTBrowse } from '../NFTBrowse'
import { Navigation } from '../Navigation'
import { NavigationTab } from '../Navigation/Navigation.types'
import { locations } from '../../modules/routing/locations'
import { Props } from './ItemBrowse.types'
import { Column } from '../Layout/Column'
import { AccountProfile } from '../AccountProfile'
import './ItemBrowse.css'
import { FooterImage } from '../FooterImage'
import { Header } from '../Header'

const ItemBrowse = (props: Props) => {
    const {
        address,
        vendor,
        wallet,
        isConnecting,
        onRedirect,
        onFetchMyItems,
        isFullscreen
    } = props

    const isCurrentAccount =
        address === undefined || (wallet && wallet.address === address)

    const [hasCopiedAddress, setHasCopiedAddress] = useTimer(1200)

    return (
        <div className="AccountPage">
            <div className="PageCustomHeader">
                <Navbar isFullscreen />
                <Navigation
                    activeTab={isCurrentAccount ? NavigationTab.BROWSEITEM : undefined}
                    isFullscreen={isFullscreen}
                />
            </div>
            <Header
                title={t('saleitem_page.title')}
                subTitle={t('saleitem_page.sub_title')}
                wallet={wallet}
            />
            {/* <AccountProfile wallet={wallet} /> */}
            {isCurrentAccount ? (
                <NFTBrowse
                    vendor={vendor}
                    address={wallet ? wallet.address : ''}
                    view={View.MARKET}
                    isItems={true}
                />
            ) : address !== undefined ? (
                <>
                    <PageHeader>
                        <Column>
                            <Profile
                                address={address}
                                size="massive"
                                imageOnly
                                inline={false}
                            />
                            <div className="profile-name">
                                <Profile address={address} textOnly inline={false} />
                            </div>
                            <div className="profile-address">
                                <div className="profile-address-hash">{address}</div>
                                {!isMobile() && (
                                    <div>
                                        <CopyToClipboard
                                            text={address}
                                            onCopy={setHasCopiedAddress}
                                        >
                                            <Icon
                                                aria-label="Copy address"
                                                aria-hidden="false"
                                                className="copy"
                                                name="copy outline"
                                            />
                                        </CopyToClipboard>
                                        {hasCopiedAddress && (
                                            <span className="profile-copied-text-desktop copied">
                                                {t('account_page.copied')}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            {isMobile() && (
                                <div className="profile-copy-text-mobile">
                                    <CopyToClipboard text={address} onCopy={setHasCopiedAddress}>
                                        {hasCopiedAddress ? (
                                            <span className="copied">
                                                {t('account_page.copied_capitalized')}
                                            </span>
                                        ) : (
                                            <span className="copy">
                                                {t('account_page.copy_address')}
                                            </span>
                                        )}
                                    </CopyToClipboard>
                                </div>
                            )}
                        </Column>
                    </PageHeader>

                    {/* <NFTBrowse vendor={vendor} address={address} view={View.ACCOUNT} /> */}
                </>
            ) : null}
            <div className="lootbox-container"></div>
            <FooterImage />
            <Footer isFullscreen={isFullscreen} />
        </div>
    )
}

export default React.memo(ItemBrowse)
