import React, { useState, useEffect } from 'react'
import { t, T } from '@kmon/dapps/dist/modules/translation/utils'
import { Form, Field, Button, Modal, Popup, CoinIcon } from '@kmon/ui'

import { Props } from './ConfirmModal.types'
import { fromItemCount, toItemCount } from '../../../lib/number'
import { Image } from '../../Image';
import { fromWei } from 'web3x-es/utils'
import './ConfirmModal.css'
import { CoinPopup } from '../../CoinPopup'
import { providers } from 'ethers'
import { Wallet } from '../../Wallet'
import { getConnectedProviderChainId, getConnectedProvider } from '@kmon/dapps/dist/lib/eth'
import { ethers } from 'ethers'
import { KmonftDiamond } from '../../../modules/item/diamond'
import { addresses } from '../../../modules/item/addresses'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { ContractName } from '@kmon/transactions'
import { getContract } from '../../../modules/contract/utils'
import { getContractNames } from '../../../modules/vendor'
import { AuthorizationModal } from '../../AuthorizationModal'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'
import { Address } from 'web3x-es/address'
import { formatCoin } from '../../../lib/kmon'
import { Coin, Network } from '@kmon/schemas'
import { USD_PAYMENT_TOKEN } from '../../../modules/order/utils'

const ConfirmModal = (props: Props) => {
  const {
    wallet,
    authorizations,
    currentItem,
    showConfirmModal,
    handleProceed,
    onCloseModal,
    image,
    selectedItem,
    isInsufficient
  } = props

  const [isAuthorizing, setIsAuthorizing] = useState(false)
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)
  const [coin, setCoin] = useState(Coin.BNB);

  const isBnborKmon = (address: any) => {
    if (address.toString() == Address.ZERO.toString()) {
      return 'BNB';
    }

    if (address.toString() == USD_PAYMENT_TOKEN) {
      return 'USD';
    }

    return 'KMON';
  }
  const contractNames = getContractNames()

  const marketplace = getContract({
    name: contractNames.MARKETPLACE
  })

  const kmon = getContract({
    name: contractNames.KMONToken
  })

  const authorization: Authorization = {
    address: wallet?.address,
    authorizedAddress: marketplace.address,
    contractAddress: kmon.address,
    contractName: ContractName.KMONToken,
    chainId: kmon.chainId,
    type: AuthorizationType.ALLOWANCE
  }

  const handleBuyButton = async () => {
    setIsAuthorizing(true);
    const approved = selectedItem.paymentToken == Address.ZERO.toString() || hasAuthorization(authorizations, authorization);
    if (approved) {
      setIsAuthorizing(false)
      handleProceed(
        selectedItem.itemTypeId,
        currentItem.name,
        selectedItem.seller.toString(),
        selectedItem.priceInWei,
        selectedItem.paymentToken.toString()
      )
    } else {
      setShowAuthorizationModal(true);
      setIsAuthorizing(false)
    }
  }

  useEffect(() => {
    if (selectedItem != null) {
      setCoin(selectedItem.paymentToken.toString() === Address.ZERO.toString() ? Coin.BNB : selectedItem.paymentToken === USD_PAYMENT_TOKEN
        ? Coin.USD : Coin.KMON)
    }
  }, [selectedItem])

  const handleClose = () => setShowAuthorizationModal(false)

  return (
    <>
      <Modal size="small" open={showConfirmModal} className="ConfirmNumberOfItemsModal">
        <Modal.Content>
          {selectedItem && <div className='confirm-modal-container'>
            <div className='confirm-modal-image'>
              <img src={image} />
            </div>
            <div className='confirm-modal-info-container'>
              <div className='confirm-modal-right-container'>
                <div className='confirm-modal-info-title'>Buy Item</div>
                <div className='confirm-modal-info-desc'>You are about to buy {t(`nft_item.title.${currentItem.name}`)} for {fromWei(selectedItem.priceInWei, 'ether')} {isBnborKmon(selectedItem.paymentToken)}</div>
              </div>
              {isInsufficient ?
                <T
                  id={'buy_page.not_enough_coin'}
                  values={{
                    coin: coin,
                    name: currentItem.name,
                    amount: (
                      <Price price={selectedItem.priceInWei} coin={coin} />
                    )
                  }}
                />
                : <></>}
              <div className='confirm-button-container'>
                <Button
                  primary
                  disabled={isInsufficient}
                  onClick={handleBuyButton}
                >
                  {t('buy_page.buy')}
                </Button>
                <Button
                  primary
                  onClick={onCloseModal}
                >
                  {t('global.cancel')}
                </Button>
              </div>
            </div>
          </div>}
        </Modal.Content>
      </Modal>
      <AuthorizationModal
        open={showAuthorizationModal}
        authorization={authorization}
        // isLoading={isTransferringItem}
        onProceed={() =>
          handleProceed(
            selectedItem.itemTypeId,
            currentItem.name,
            selectedItem.seller.toString(),
            selectedItem.priceInWei,
            selectedItem.paymentToken.toString()
          )
        }
        onCancel={handleClose}
      />
    </>
  )
}

const Price = (props: { network?: Network; price: string; coin: Coin }) => (
  <CoinPopup network={props.network} inline withTooltip coin={props.coin}>
    {formatCoin(props.price)}
  </CoinPopup>
)

export default React.memo(ConfirmModal)
