import React, { useState, useEffect, useCallback } from 'react'
import { Coin } from '@kmon/schemas'
import { fromWei } from 'web3x-es/utils'
import dateFnsFormat from 'date-fns/format'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'
import { t, T } from '@kmon/dapps/dist/modules/translation/utils'
import { Header, Form, Field, Button, Modal, Page } from '@kmon/ui'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'
import { ContractName } from '@kmon/transactions'
import { Address } from 'web3x-es/address'
import { toCoin, fromCoin } from '../../../../lib/kmon'
import {
  INPUT_FORMAT,
  getDefaultExpirationDate
} from '../../../../modules/order/utils'
import { getNFTName, isOwnedBy } from '../../../../modules/nft/utils'
import { locations } from '../../../../modules/routing/locations'
import { VendorFactory } from '../../../../modules/vendor/VendorFactory'
import { AuthorizationModal } from '../../../AuthorizationModal'
import { NFTAction } from '../../../NFTAction'
import { CoinPopup } from '../../../CoinPopup'
import { CoinField } from '../../../CoinField'
import { getContractNames } from '../../../../modules/vendor'
import { getContract } from '../../../../modules/contract/utils'
import { Props } from './SellModal.types'
import { CoinSelectField } from '../../../CoinSelectField'
import { toDecimal } from '../../../../lib/number'
import { LootboxDetailCard } from '../../LootboxDetailCard'
import { fetchIPFS } from '../../../../modules/item/utils'
import { ConfirmModal } from '../ConfirmModal'
import { BigNumber } from 'ethers'

const SellModal = (props: Props) => {
  const {
    wallet,
    authorizations,
    onNavigate,
    onSell,
    isUpdate,
    filteredItem,
    itemId,
    currentItem,
    itemBalance,
    isBuyingItem,
    isTransferringItem,
    isOwned,
    metaData,
  } = props

  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)
  const [price, setPrice] = useState(isUpdate ? fromWei(filteredItem.priceInWei, 'ether') : '')
  const [quantity, setQuantity] = useState(isUpdate ? filteredItem.amount.toString() : '')
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [paymentCoin, setPaymentCoin] = useState(
    isUpdate ? (filteredItem.paymentToken.toString() == Address.ZERO.toString() ? Coin.BNB : Coin.KMON) : Coin.KMON
  )

  const [expiresAt, setExpiresAt] = useState(
    isUpdate ? dateFnsFormat(+filteredItem!.expires, INPUT_FORMAT)
      : getDefaultExpirationDate()
  )

  const contractNames = getContractNames()

  const marketplace = getContract({
    name: contractNames.MARKETPLACE
  })
  const item = getContract({
    name: contractNames.KMONFTV2
  })
  const kmon = getContract({
    name: contractNames.KMONToken
  })
  if (!wallet) {
    return null
  }

  const authorization: Authorization = {
    address: wallet.address,
    authorizedAddress: marketplace.address,
    contractAddress: item.address,
    contractName: ContractName.KMONFTV2,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    type: AuthorizationType.APPROVAL
  }

  const handleSubmit = async () => {
    setShowConfirmModal(true)
  }

  const handleCreateOrder1155 = () => {
    onSell(
      itemId,
      currentItem.name,
      fromCoin(price, paymentCoin),
      Number(quantity),
      paymentCoin === Coin.BNB ? Address.ZERO.toString() : kmon.address,
      new Date(expiresAt).getTime());
  }

  const handleProceedButton = async (confirmPrice: any) => {
    const approved = hasAuthorization(authorizations, authorization);
    if (approved) {
      onSell(
        itemId,
        currentItem.name,
        fromCoin(confirmPrice, paymentCoin),
        Number(quantity),
        paymentCoin === Coin.BNB ? Address.ZERO.toString() : kmon.address,
        new Date(expiresAt).getTime()
      )
    } else {
      setShowAuthorizationModal(true)
    }
  }

  const getImage = (uri: string) => {
    if (!uri) {
      return ''
    } else {
      const _img = `https://ipfs.kryptomon.co/ipfs/${uri.replace(
        'ipfs://',
        ''
      )}`
      return _img
    }
  }

  const handleClose = () => setShowAuthorizationModal(false)

  const isInvalidDate = new Date(expiresAt).getTime() < Date.now()

  const isDisabled = price === '' || quantity === ''

  return (
    <Page className="SellPage product-container">
      <div className="NFTAction">
        <Row className="Row">
          <Column align="left">
            {currentItem && (
              <LootboxDetailCard
                name={currentItem.name || ''}
                image={getImage(metaData.image)}
                price={fromWei(
                  BigNumber.from(currentItem.usdPrice).toString(),
                  'ether'
                )}
                kmonPrice={fromWei(
                  BigNumber.from(currentItem.usdPrice)
                    .div(BigNumber.from(currentItem.rate))
                    .toString(),
                  'wei'
                )}
                priceWithCandies={fromWei(
                  BigNumber.from(currentItem.candiesPrice || 0).toString(),
                  'wei'
                )}
                isNFT={currentItem.isNFT}
                itemType={metaData?.properties?.type}
                canBeTransferred={currentItem.canBeTransferred}
                balance={itemBalance}
                isOwned={isOwned}
              />
            )}
          </Column>
          <Column align="right">
            <Header size="large">LIST FOR SALE</Header>
            <div className="subtitle">
              <T
                id="sell_page.subtitle"
                values={{ name: <b>{currentItem?.name || ''}</b> }}
              />
            </div>
            <div className="lootbox-separator" />
            <Form onSubmit={() => setShowConfirmModal(true)}>
              <div className="form-fields">
                {/* <CoinSelectField
                    coin={Coin.BNB}
                    onChangeCoin={c => setPaymentCoin(c)}
                    defaultCoin={paymentCoin}
                  /> */}
                <Field
                  label={t('sell_page.quantity')}
                  type="text"
                  value={quantity}
                  placeholder={itemBalance}
                  onChange={(_event, props) => {
                    if (props.value > itemBalance)
                      _event.preventDefault();
                    else
                      setQuantity(props.value);
                  }}
                />
                <CoinField
                  label={t('sell_page.price')}
                  type="text"
                  placeholder={toCoin(1000)}
                  coin={paymentCoin}
                  value={price}
                  focus={true}
                  onChange={(_event, props) => {
                    if (paymentCoin === Coin.BNB) {
                      setPrice(toDecimal(props.value))
                    } else {
                      const newPrice = fromCoin(props.value, paymentCoin)
                      setPrice(toCoin(newPrice))
                    }
                  }}
                />
                <Field
                  label={t('sell_page.expiration_date')}
                  type="date"
                  value={expiresAt}
                  onChange={(_event, props) =>
                    setExpiresAt(props.value || getDefaultExpirationDate())
                  }
                  error={isInvalidDate}
                  message={isInvalidDate ? t('sell_page.invalid_date') : undefined}
                />
              </div>
              <div className="buttons">
                <Button
                  // type="submit"
                  primary
                  onClick={() => handleSubmit()}
                  // loading={isTransfering}
                  disabled={isDisabled}
                >
                  {isUpdate ? t('sell_page.update_submit') : t('sell_page.submit')}
                </Button>
                <Button
                  as="div"
                  onClick={() =>
                    onNavigate(locations._item(itemId, isOwned))
                  }
                >
                  {t('global.cancel')}
                </Button>
              </div>
            </Form>
            <div className="lootbox-separator" />
          </Column>
        </Row>
        <ConfirmModal
          price={price}
          currentItem={currentItem}
          currentPrice={price}
          paymentCoin={paymentCoin}
          isBuyingItem={isBuyingItem}
          showConfirmModal={showConfirmModal}
          handleProceed={handleProceedButton}
          onCloseModal={() => {
            setShowConfirmModal(false)
            // setCurrentItemCount('')
          }}
        />
        <AuthorizationModal
          open={showAuthorizationModal}
          authorization={authorization}
          isLoading={isTransferringItem}
          onProceed={handleCreateOrder1155}
          onCancel={handleClose}
        />
      </div>
    </Page>
  )
}

export default React.memo(SellModal)
