import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'
import { BigNumber, ethers, providers } from 'ethers'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'
import { Coin } from '@kmon/schemas'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { Page, Modal, Button, Field, Loader } from '@kmon/ui'
import { ContractName } from '@kmon/transactions'
import { locations } from '../../../modules/routing/locations'
import { toCoin } from '../../../lib/kmon'
import { Network } from '@kmon/schemas'
import { Navbar } from '../../Navbar'
import { Footer } from '../../Footer'
import { Navigation } from '../../Navigation'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { CoinField } from '../../CoinField'
import { AuthorizationModal } from '../../AuthorizationModal'
import { Props } from './PayPage.types'
import './PayPage.css'
import useWeb3Modal from './useWeb3Modal'
import { KmonftDiamond } from './diamond'
import { BNFT } from '../../../modules/bnft/BNFTABI'
import { BUSD } from './erc20'
import { toWei } from 'web3x-es/utils'
import { addresses } from './addresses'
import { getIfCommunityMember } from '../../../modules/bnft/utils'

const PayPage = (props: Props) => {
  const dispatch = useDispatch()
  const { orderId, email } = useParams<{ orderId?: string; email?: string }>()

  const {
    wallet,
    authorizations,
    isConnecting,
    bnftBalance,
    bnftTokenIds,
    orderedItems,
    bnftTokenBalances,
    communityRedeeption,
    tokenBalancesByIds,
    isBNFTSuccessLoading,
    isBNFTRequestLoading,
    onGrant,
    onRedirect,
    onFetchBNFTBalance,
    onFetchCommunityOrder,
    onFetchOrderDetails
    // onBuyBNFTSuccess
  } = props

  // const { address, web3, chainId } = useWeb3Modal()
  const [showLeavingSiteModal, setShowLeavingSiteModal] = useState(true)
  const [order, setOrder] = useState(orderId || '')
  const [userEmail, setUserEmail] = useState(email || '')
  const [price, setPrice] = useState(0)
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [orderedTokenIds, setOrderedTokenIds] = useState([])
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(true)
  const [payIsLoading, setPayIsLoading] = useState(false)
  const [isAuthorizationLoading, setIsAuthorizationLoading] = useState(false)
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)

  useEffect(() => {
    const checkIfCommunity = wallet && getIfCommunityMember(wallet)
    if (
      wallet &&
      checkIfCommunity &&
      communityRedeeption !== null &&
      communityRedeeption !== undefined
    ) {
      if (communityRedeeption === 200 || communityRedeeption === 201) {
        toast.success('Redeemed Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        dispatch(push(locations.bnft()))
      } else if (communityRedeeption === 400) {
        toast.warn('Already redeemed!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        dispatch(push(locations.bnft()))
      } else {
        toast.warn('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        dispatch(push(locations.bnft()))
      }
    }
  }, [communityRedeeption])

  useEffect(() => {
    const checkIfCommunity = wallet && getIfCommunityMember(wallet)
    wallet &&
      (!checkIfCommunity
        ? onFetchBNFTBalance()
        : onFetchCommunityOrder({ orderId, email }))
    wallet && onFetchOrderDetails({ orderId, email })
  }, [wallet])

  useEffect(() => {
    if (orderedItems && orderedItems.line_items) {
      const count: any = {}
      const lineItems = orderedItems.line_items
      for (let index = 0; index < lineItems.length; index++) {
        const element = lineItems[index]

        if (count[element.sku]) {
          count[element.sku] += element.quantity
        } else {
          count[element.sku] = element.quantity
        }
      }
      let newArray: any = []
      if (tokenBalancesByIds) {
        Object.keys(count).forEach((item: string | number) => {
          const slicedTokens = tokenBalancesByIds[item].slice(0, count[item])
          if (slicedTokens?.length > 0) {
            slicedTokens.forEach((element: any) => {
              newArray.push(element)
            })
          } else {
            toast.warn('Insufficient balance', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            })
            setIsInsufficientBalance(false)
          }
        })
        setOrderedTokenIds(newArray)
        setIsInsufficientBalance(false)
      }
    }
  }, [orderedItems, bnftTokenBalances])

  if (!wallet) {
    return null
  }

  const __addresses: any = addresses

  const _chainId = Number(getConnectedProviderChainId())

  const contractNames = getContractNames()

  const authorization: Authorization = {
    address: wallet?.address,
    authorizedAddress: __addresses[_chainId].KmonftDiamond,
    contractAddress: __addresses[_chainId].BNFT,
    contractName: 'BNFT' as ContractName,
    chainId: _chainId,
    type: AuthorizationType.APPROVAL
  }

  // useEffect(() => {
  //   if (!isConnecting && !wallet) {
  //     onRedirect(locations.signIn())
  //   }
  // }, [isConnecting, wallet, onRedirect])

  // if (!wallet) {
  //   return null
  // }

  /* BUSD Process
  const contractNames = getContractNames()
  const busd = getContract({
    name: contractNames.BUSD
  })

  useEffect(() => {
    order?.length > 0 &&
      email?.length > 0 &&
      onFetchMerchandise({ orderId: order, email: email })
  }, [order, email])

  useEffect(() => {
    if (orderData) {
      const { amount } = orderData
      setPrice(amount)
      isInsufficientBalance(wallet, orderData)
    }
  }, [orderData, wallet])

  const isInsufficientBalance = (wallet: any | null, order: any | null) => {
    if (order === null) setVisible(false)
    if (order && order.amount && order?.status === 'unpaid') {
      if (
        wallet?.networks[Network.BSC].busdBalance > parseFloat(order.amount)
      ) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    } else {
      setVisible(false)
    }
  }

  const start = async () => {
    const { amount, orderId } = orderData
    try {
      // get allowance
      setIsLoading(true)
      console.log(address)
      const _web3: any = web3
      const _addresses: any = addresses
      const contract = await new _web3.eth.Contract(BUSD, busd.address)
      // const res = await contract.methods.transfer(_addresses[chainId]?.KmonftDiamond, toWei(amount.toString(), 'ether')).send({ from: address })
      const res = await contract.methods
        .allowance(address, _addresses[chainId]?.KmonftDiamond)
        .call({ from: address })

      console.log(res)
      setIsLoading(false)
      setShowLeavingSiteModal(false)
      dispatch(push(locations.account(address)))
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 5000)
      alert('Payment successfull!')
    } catch (error) {
      setIsLoading(false)
      console.log('start error=>', error)
    }
  }

  const proceed = async () => {
    const _address: string = address || ''
    const _addresses: any = addresses
    setIsLoading(true)
    await onGrant({
      address: _address,
      authorizedAddress: _addresses[chainId]?.KmonftDiamond,
      contractAddress: busd?.address,
      contractName: ContractName.BUSD,
      chainId: chainId,
      type: AuthorizationType.ALLOWANCE
    })

    const { amount, orderId } = orderData
    try {
      const _web3: any = web3
      const contract = await new _web3.eth.Contract(
        KmonftDiamond,
        _addresses[chainId]?.KmonftDiamond
      )
      const res = await contract.methods
        .payForShopifyOrder(
          toWei(amount.toString(), 'ether'),
          ethers.utils.formatBytes32String(orderId)
        )
        .send({ from: address })
        .on('transactionHash', (hash: any) => {
          console.log('hash=>', hash)
        })
      console.log('res=>', res)
      setIsLoading(false)
      start()
    } catch (error) {
      setIsLoading(false)
      console.log('payOrder error', error)
    }
  }*/

  const proceed = async () => {
    const _addresses: any = addresses

    const connectedProvider = await getConnectedProvider()
    if (!connectedProvider) {
      throw new Error('Provider not connected')
    }

    const provider = await new providers.Web3Provider(connectedProvider)
    const chainId = Number(getConnectedProviderChainId())
    // const _addresses: any = addresses

    const contract = await new ethers.Contract(
      _addresses[chainId].KmonftDiamond,
      KmonftDiamond,
      provider.getSigner()
    )

    const bnftContract = await new ethers.Contract(
      _addresses[chainId].BNFT,
      BNFT,
      provider.getSigner()
    )

    if (!isInsufficientBalance) {
      const isApprovedForall = await bnftContract.isApprovedForAll(
        wallet?.address,
        _addresses[chainId].KmonftDiamond
      )
      console.log('Is approved-->', isApprovedForall)

      if (!isApprovedForall) {
        setShowAuthorizationModal(true)
      } else {
        handleProceed()
      }
    } else {
      setPayIsLoading(false)
    }
  }

  const handleClose = () => setShowAuthorizationModal(false)

  const handleProceed = async () => {
    setPayIsLoading(true)
    const _addresses: any = addresses

    const connectedProvider = await getConnectedProvider()
    if (!connectedProvider) {
      throw new Error('Provider not connected')
    }

    const provider = await new providers.Web3Provider(connectedProvider)
    const chainId = Number(getConnectedProviderChainId())
    // const _addresses: any = addresses

    const contract = await new ethers.Contract(
      _addresses[chainId].KmonftDiamond,
      KmonftDiamond,
      provider.getSigner()
    )

    const bnftContract = await new ethers.Contract(
      _addresses[chainId].BNFT,
      BNFT,
      provider.getSigner()
    )

    if (!isInsufficientBalance) {
      try {
        const gasEstimate = await contract.estimateGas.redeemBoxForOrder(
          _addresses[chainId].BNFT,
          orderedTokenIds,
          ethers.utils.formatBytes32String(String(orderId))
        )
        console.log('TokenIds-->', orderedTokenIds, gasEstimate)

        const res = await contract.redeemBoxForOrder(
          _addresses[chainId].BNFT,
          orderedTokenIds,
          ethers.utils.formatBytes32String(String(orderId)),
          { gasLimit: gasEstimate.add(1000000) }
        )

        const response: any = await res.wait().then((resp: any) => {
          setPayIsLoading(false)
          console.log(resp)
          toast.success(
            `Redeemed successfully! It'll take few minutes to be available on your balance`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          )
          dispatch(push(locations.currentAccount()))
        })
      } catch (error) {
        setPayIsLoading(false)
        console.log('Failed reason below')
        console.log(error)
        setPayIsLoading(false)
        toast.error(`Transaction failed!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      }
    } else {
      setPayIsLoading(false)
    }
  }

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation />
      </div>
      <Page className="SettingsPage">
        {/* <div>Pay</div> */}
        {(isLoading || payIsLoading) && <Loader size="massive" active />}
        <Modal
          className="LeavingSiteModal"
          size="small"
          open={showLeavingSiteModal}
          onClose={() => setShowLeavingSiteModal(false)}
        >
          <Modal.Header>{'Pay BNFT Token'}</Modal.Header>
          {(isLoading || payIsLoading) && <Loader size="massive" active />}
          {isBNFTRequestLoading && <Loader size="massive" active />}
          <Modal.Content>
            <Field
              className="mana-input"
              label={'Order Id'}
              // coin={Coin.KMON}
              // placeholder={toCoin(1000)}
              value={order}
              disabled
              iconPosition="left"
            />
            <Field
              className="mana-input"
              label={'Email'}
              // coin={Coin.KMON}
              // placeholder={'example@example.co'}
              value={userEmail}
              // onChange={(_event, props) => {
              //   // const newPrice = fromCoin(props.value, Coin.KMON)
              //   setEmail(props.value)
              // }}
              disabled
              iconPosition="left"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setShowLeavingSiteModal(false)
              }}
            >
              {t('global.cancel')}
            </Button>
            <Button
              primary
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              disabled={
                isInsufficientBalance ||
                getIfCommunityMember(wallet!) ||
                payIsLoading
              }
              onClick={() => proceed()}
            >
              {t('global.proceed')}
            </Button>
          </Modal.Actions>
        </Modal>
        <AuthorizationModal
          open={showAuthorizationModal}
          authorization={authorization}
          isLoading={isBNFTRequestLoading || payIsLoading}
          onProceed={handleProceed}
          onCancel={handleClose}
        />
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(PayPage)
