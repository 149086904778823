import React, { useState } from 'react'
import { t, T } from '@kmon/dapps/dist/modules/translation/utils'
import { Modal } from '@kmon/ui'

import { Props } from './CraftingModal.types'
import './CraftingModal.css'

const CraftingModal = (props: Props) => {
  const { showCraftingModal, itemImage, buy, handleProceed } = props

  const classes = ['item-image']
  buy ? classes.push('buy') : classes.push('craft')

  return (
    <Modal size="small" open={showCraftingModal} className="CraftingModal">
      <Modal.Header>{buy ? `Sending` : `Crafting...`}</Modal.Header>
      <Modal.Content>
        <div className={classes.join(' ')}>
          <img src={itemImage} className="ball" />
          {/* // <div className="ball"></div> */}
        </div>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  )
}

export default React.memo(CraftingModal)
