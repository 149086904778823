import {
  LoadingState,
  loadingReducer
} from '@kmon/dapps/dist/modules/loading/reducer'
import {
  BuyItemFailureAction,
  BuyItemRequestAction,
  BuyItemSuccessAction,
  BuyItemWithCandiesFailureAction,
  BuyItemWithCandiesRequestAction,
  BuyItemWithCandiesSuccessAction,
  BUY_ITEM_FAILURE,
  BUY_ITEM_REQUEST,
  BUY_ITEM_SUCCESS,
  BUY_ITEM_WITH_CANDIES_FAILURE,
  BUY_ITEM_WITH_CANDIES_REQUEST,
  BUY_ITEM_WITH_CANDIES_SUCCESS,
  FetchAllItemsFailureAction,
  FetchAllItemsRequestAction,
  FetchAllItemsSuccessAction,
  FetchMyItemsFailureAction,
  FetchMyItemsRequestAction,
  FetchMyItemsSuccessAction,
  FetchItemFailureAction,
  FetchItemRequestAction,
  FetchItemsFailureAction,
  FetchItemsRequestAction,
  FetchItemsSuccessAction,
  FetchItemSuccessAction,
  FETCH_ALL_ITEMS_FAILURE,
  FETCH_ALL_ITEMS_REQUEST,
  FETCH_ALL_ITEMS_SUCCESS,
  FETCH_MY_ITEMS_FAILURE,
  FETCH_MY_ITEMS_REQUEST,
  FETCH_MY_ITEMS_SUCCESS,
  FETCH_ITEMS_FAILURE,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_TYPES_FAILURE,
  FETCH_ITEM_TYPES_REQUEST,
  FETCH_ITEM_TYPES_SUCCESS,
  FetchItemTypesFailureAction,
  FetchItemTypesRequestAction,
  FetchItemTypesSuccessAction,
  FetchMaterialItemTypesRequestAction,
  FetchMaterialItemTypesFailureAction,
  FetchMaterialItemTypesSuccessAction,
  FETCH_MATERIAL_ITEM_TYPES_REQUEST,
  FETCH_MATERIAL_ITEM_TYPES_SUCCESS,
  FETCH_MATERIAL_ITEM_TYPES_FAILURE,
  FetchItemBalanceFailureAction,
  FetchItemBalanceRequestAction,
  FetchItemBalanceSuccessAction,
  FETCH_ITEM_BALANCE_SUCCESS,
  FETCH_ITEM_BALANCE_FAILURE,
  FETCH_ITEM_BALANCE_REQUEST,
  CRAFT_NFT_ITEM_START,
  CraftNFTItemStartAction,
  CraftNFTItemFinishAction,
  CRAFT_NFT_ITEM_FINISH,
  CRAFT_NFT_ITEM_REQUEST,
  CRAFT_NFT_ITEM_SUCCESS,
  CraftNFTItemSuccessAction,
  CraftNFTItemRequestAction,
  CRAFT_NFT_ITEM_FAILURE,
  CraftNFTItemFailureAction,
  BUY_NFT_ITEM_REQUEST,
  BUY_NFT_ITEM_SUCCESS,
  BUY_NFT_ITEM_FAILURE,
  BuyNFTItemRequestAction,
  BuyNFTItemFailureAction,
  BuyNFTItemSuccessAction,
  TRANSFER_ITEM_REQUEST,
  TRANSFER_ITEM_SUCCESS,
  TRANSFER_ITEM_FAILURE,
  TransferItemRequestAction,
  TransferItemSuccessAction,
  TransferItemFailureAction,
  FETCH_ORDERED_ITEMS_REQUEST,
  FETCH_ORDERED_ITEMS_SUCCESS,
  FETCH_ORDERED_ITEMS_GROUP_SUCCESS,
  FETCH_ORDERED_ITEMS_FAILURE,
  FetchOrderedItemsRequestAction,
  FetchOrderedItemsSuccessAction,
  FetchOrderedItemsFailureAction,
  FetchOrderedItemsGroupSuccessAction
} from './actions'
import { Item } from './types'
import { ethers } from 'ethers'

export type ItemState = {
  loading: LoadingState
  data: Item[]
  allItems: any
  myItems: any
  orderedItems: any
  orderedItemsGroup: any
  upgradeDefinitions: any
  itemTypes: any
  materialItemTypes: any
  itemBalance: any
  craftingStarted: any
  craftingFinished: any
  error: string | null
}

const INITIAL_STATE = {
  loading: [],
  data: [],
  allItems: [],
  myItems: [],
  orderedItems: [],
  orderedItemsGroup: [],
  upgradeDefinitions: [],
  itemTypes: [],
  materialItemTypes: [],
  itemBalance: null,
  craftingStarted: false,
  craftingFinished: false,
  error: null
}

type ItemReducerAction =
  | FetchMyItemsFailureAction
  | FetchMyItemsRequestAction
  | FetchMyItemsSuccessAction
  | FetchAllItemsRequestAction
  | FetchAllItemsSuccessAction
  | FetchAllItemsFailureAction
  | FetchItemsRequestAction
  | FetchItemsSuccessAction
  | FetchItemsFailureAction
  | FetchItemRequestAction
  | FetchItemSuccessAction
  | FetchItemFailureAction
  | BuyItemRequestAction
  | BuyItemSuccessAction
  | BuyItemFailureAction
  | BuyItemWithCandiesRequestAction
  | BuyItemWithCandiesSuccessAction
  | BuyItemWithCandiesFailureAction
  | FetchItemTypesFailureAction
  | FetchItemTypesRequestAction
  | FetchItemTypesSuccessAction
  | FetchMaterialItemTypesRequestAction
  | FetchMaterialItemTypesFailureAction
  | FetchMaterialItemTypesSuccessAction
  | FetchItemBalanceFailureAction
  | FetchItemBalanceRequestAction
  | FetchItemBalanceSuccessAction
  | CraftNFTItemStartAction
  | CraftNFTItemFinishAction
  | CraftNFTItemSuccessAction
  | CraftNFTItemRequestAction
  | CraftNFTItemFailureAction
  | BuyNFTItemRequestAction
  | BuyNFTItemFailureAction
  | BuyNFTItemSuccessAction
  | TransferItemRequestAction
  | TransferItemSuccessAction
  | TransferItemFailureAction
  | FetchOrderedItemsRequestAction
  | FetchOrderedItemsSuccessAction
  | FetchOrderedItemsFailureAction
  | FetchOrderedItemsGroupSuccessAction

export function itemReducer(
  state: ItemState = INITIAL_STATE,
  action: ItemReducerAction
): ItemState {
  switch (action.type) {
    case FETCH_ITEMS_REQUEST:
    case FETCH_ALL_ITEMS_REQUEST:
    case FETCH_MY_ITEMS_REQUEST:
    case FETCH_ORDERED_ITEMS_REQUEST:
    case FETCH_ITEM_REQUEST:
    case BUY_ITEM_REQUEST:
    case BUY_ITEM_WITH_CANDIES_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case FETCH_MY_ITEMS_SUCCESS:
      return {
        ...state,
        myItems: action.payload.items
      }
    case FETCH_ORDERED_ITEMS_SUCCESS:
      return {
        ...state,
        orderedItems: action.payload.items
      }
    case FETCH_ORDERED_ITEMS_GROUP_SUCCESS:
      return {
        ...state,
        orderedItemsGroup: action.payload.items
      }
    case FETCH_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        allItems: action.payload.items[0],
        upgradeDefinitions: action.payload.items[1]
      }
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        data: action.payload.items.map(item => ({
          ...item,
          itemId: item.name,
          name: ethers.utils.parseBytes32String(item.name)
        }))
      }
    case FETCH_ITEM_SUCCESS:
    case BUY_ITEM_SUCCESS:
    case BUY_ITEM_WITH_CANDIES_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null
      }
    case FETCH_ITEMS_FAILURE:
    case FETCH_ITEM_FAILURE:
    case BUY_ITEM_FAILURE:
    case BUY_ITEM_WITH_CANDIES_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error
      }
    case FETCH_ITEM_TYPES_REQUEST:
    case FETCH_ITEM_TYPES_SUCCESS:
      return {
        ...state,
        itemTypes: action.payload
      }
    case FETCH_ITEM_TYPES_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error
      }
    case FETCH_ITEM_BALANCE_REQUEST:
    case FETCH_ITEM_BALANCE_SUCCESS:
      return {
        ...state,
        itemBalance: action.payload
      }
    case FETCH_ITEM_BALANCE_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error
      }
    case FETCH_MATERIAL_ITEM_TYPES_REQUEST:
    case FETCH_MATERIAL_ITEM_TYPES_SUCCESS:
      return {
        ...state,
        materialItemTypes: action.payload
      }
    case FETCH_MATERIAL_ITEM_TYPES_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error
      }
    case BUY_NFT_ITEM_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case BUY_NFT_ITEM_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case BUY_NFT_ITEM_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case CRAFT_NFT_ITEM_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case CRAFT_NFT_ITEM_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case CRAFT_NFT_ITEM_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error
      }
    case CRAFT_NFT_ITEM_START:
      return {
        ...state,
        craftingStarted: true,
        craftingFinished: false
      }
    case CRAFT_NFT_ITEM_FINISH:
      return {
        ...state,
        craftingStarted: false,
        craftingFinished: true
      }
    case TRANSFER_ITEM_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case TRANSFER_ITEM_SUCCESS:
    case TRANSFER_ITEM_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    default:
      return state
  }
}
