import {
  FETCH_GAME_ACCOUNT_REQUEST,
  FETCH_GAME_ACCOUNT_SUCCESS,
  FETCH_GAME_ACCOUNT_FAILURE,
  FetchGameAccountRequestAction,
  FetchGameAccountSuccessAction,
  FetchGameAccountFailureAction,
  FETCH_GAME_AUTH_TOKEN_REQUEST,
  FETCH_GAME_AUTH_TOKEN_SUCCESS,
  FETCH_GAME_AUTH_TOKEN_FAILURE,
  FetchGameAuthTokenRequestAction,
  FetchGameAuthTokenSuccessAction,
  FetchGameAuthTokenFailureAction
} from './actions'

export type RoutingState = {
  isLoadMore: boolean
}

export type GameAccountState = {
  authToken: null
  gameData: null
  infoResultPayload: null
}

const INITIAL_STATE = {
  authToken: null,
  gameData: null,
  infoResultPayload: null
}

type GameAccountReducerAction =
  | FetchGameAccountRequestAction
  | FetchGameAccountSuccessAction
  | FetchGameAccountFailureAction
  | FetchGameAuthTokenRequestAction
  | FetchGameAuthTokenSuccessAction
  | FetchGameAuthTokenFailureAction

export function settingsReducer(
  state: GameAccountState = INITIAL_STATE,
  action: GameAccountReducerAction
) {
  switch (action.type) {
    case FETCH_GAME_ACCOUNT_REQUEST:
    case FETCH_GAME_ACCOUNT_SUCCESS: {
      action.payload.data !== undefined &&
        localStorage.setItem('game_data', JSON.stringify(action.payload.data))
      return {
        ...state,
        gameData: action.payload.data
      }
    }
    case FETCH_GAME_ACCOUNT_FAILURE: {
      return {
        ...state,
        gameData: null
      }
    }
    case FETCH_GAME_AUTH_TOKEN_REQUEST:
    case FETCH_GAME_AUTH_TOKEN_SUCCESS: {
      let _authToken = null
      let _username = null
      let _email = null
      let _populated = false

      if (action.payload.options) {
        const { authToken, InfoResultPayload } = action.payload?.options
        if (authToken) {
          _authToken = authToken
        }
        if (InfoResultPayload && InfoResultPayload.AccountInfo) {
          if (InfoResultPayload.AccountInfo.Username) {
            _username = InfoResultPayload.AccountInfo.Username
            localStorage.setItem('user-name', _username)
          }
          if (InfoResultPayload.AccountInfo.PrivateInfo.Email) {
            _email = InfoResultPayload.AccountInfo.PrivateInfo.Email
            localStorage.setItem('user-email', _email)
          }
        }
      }
      return {
        ...state,
        authToken: action.payload.options.authToken,
        infoResultPayload: action.payload.options.InfoResultPayload
      }
    }
    case FETCH_GAME_AUTH_TOKEN_FAILURE: {
      return {
        ...state,
        authToken: null
      }
    }
    default:
      return state
  }
}
