import React, { useEffect, useCallback, useState } from 'react'
import { Card } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { KIcon } from '@kmon/ui'
import { fetchJson } from 'ethers/lib/utils'
import { locations } from '../../../modules/routing/locations'
import { fetchIPFS, getIPFSUrl } from '../../../modules/item/utils'
import { Props } from './LootboxCard.types'
import { Image } from '../../Image'
import NFTLock from '../../../images/items/nft-lock-icon.svg'
import './LootboxCard.css'
import { BigNumber } from 'ethers'

const LootboxCard = (props: Props) => {
  const {
    itemId,
    name,
    kmonPrice,
    price,
    priceWithCandies,
    image,
    balance,
    isNFT,
    canBeTransferred,
    isOwned
  } = props

  const [metaData, setMetaData] = useState({} as any)

  let itemName = name.replace(/_/g, ' ')
  itemName = itemName.replace(/basic/gi, 'bronze')
  itemName = itemName.replace(/medium/gi, 'silver')
  itemName = itemName.replace(/premium/gi, 'gold')
  const itemKmonPrice = Number.parseFloat(kmonPrice).toFixed(2)
  const itemPrice = Number.parseFloat(price).toFixed(0)
  const itemPriceWithCandies = Number.parseFloat(priceWithCandies).toFixed(2)
  const fetchData = useCallback(async image => {
    const data = await fetchIPFS(image)
    setMetaData(data)
  }, [])

  const getImage = (uri: string) => {
    if (!uri) {
      return ''
    } else {
      if (uri.indexOf('ipfs://') > -1) {
        return `https://ipfs.kryptomon.co/ipfs/${uri.replace(
          'ipfs://',
          ''
        )}`;
      }
      return uri;
    }
  }

  useEffect(() => {
    fetchData(image).catch(console.error)
  }, [fetchData])

  return (
    <Card
      className="LootboxCard"
      link
      as={Link}
      to={locations._item(itemId, isOwned)}
    >
      <div className="card-image-container">
        <div className="card-image">
          {(metaData) ? (
            <Image src={getImage(metaData.image)} />
          ) : (
            <Image src={getImage(image)} />
          )}
        </div>
        <div className="card-image-text">
          <div className="product-type-price-container">
            {isOwned === '0' && (
              <div className="product-type-balance-container">
                {BigNumber.from(balance || 0).toString()}
              </div>
            )}
            {!(
              /hunter/i.test(name) ||
              /hoodie/i.test(name) ||
              isOwned === '0'
            ) ? (
              <div className="product-type-price">{`${itemKmonPrice}KMON`}</div>
            ) : null}
          </div>
        </div>
        <div className="card-image-text candy">
          {!(
            (/hunter/i.test(name) ||
              /hoodie/i.test(name) ||
              isOwned === '0') || parseInt(itemPriceWithCandies) == 0
          ) ? (
            <div className="product-type-price-container">
              <div className="product-type-price-candy">
                <KIcon icon="candy" inline>
                  {itemPriceWithCandies}
                </KIcon>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="product-description-container">
        <div className="product-info">
          <div className="product-info-name-container">
            {/* <Row> */}
            <p className="product-info-number-card">
              {t(`nft_item.title.${name}`)}
            </p>
            {/* </Row> */}
          </div>
        </div>
        <div className="product-description">
          <div className="product-description-left">
            <p className="product-description-left-item">
              {t('item_page.item_type')}:
              {/token/i.test(name) ? ' Materials' : ' Power-up'}
            </p>
            <p className="product-description-left-item">
              {/* {t('item_page.type')}: {metaData?.properties?.type} */}
            </p>
          </div>
          {!canBeTransferred && (
            <div className="product-description-right-nft">
              {/* <div className="product-description-right-nft-text">NFT</div> */}
              <img src={NFTLock} />
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default React.memo(LootboxCard)
