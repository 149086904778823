import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import ProgressBar from '@ramonak/react-progress-bar'
import { Loader } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'
import { Props } from './BluePrint.types'
import './BluePrint.css'
import { BigNumber } from 'ethers'

export const REACT_APP_API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL!
const BluePrint = (props: Props) => {
  const {
    tokenName,
    definitions,
    itemTypes,
    materialItemTypes,
    myItems,
    isOwned,
    mainItemBalance,
    loading,
    onCraft
  } = props

  const getBalanceById = (itemId: any) => {
    if (!itemId) {
      return '0'
    } else {
      const filteredItem = myItems.filter(
        (element: any) =>
          BigNumber.from(element.itemTypeId).toString() ===
          BigNumber.from(itemId).toString()
      )
      return filteredItem.length > 0
        ? BigNumber.from(filteredItem[0]?.balance).toString()
        : '0'
    }
  }

  const fetchItemTypes = (id: any) => {
    const filteredItem = materialItemTypes?.items?.filter(
      (element: any) => element.id === BigNumber.from(id).toString()
    )
    return filteredItem?.length > 0 ? filteredItem[0]?.metadata.name : null
  }

  console.log("UPDATE DEFINITIONS");
  const definitions2 = definitions.sort((d1: any, d2: any) => {
    // if you have the main item + all materials + 1
    // the higher the level the better
    console.log(d1);
    console.log(d2);
    let score = 0;
    const d1ItemType = fetchItemTypes(d1.mainItemTypeId);
    const d2ItemType = fetchItemTypes(d2.mainItemTypeId);
    if (d1ItemType == null || d2ItemType == null)
      return score;

    const d1MainTypeBalance = getBalanceById(d1.mainItemTypeId);
    const d2MainTypeBalance = getBalanceById(d2.mainItemTypeId);
    const d1MaterialBalance = getBalanceById(d1.items[0]);
    const d2MaterialBalance = getBalanceById(d2.items[0]);
    const d1SameMainTypeAndMaterials = d1.mainItemTypeId.eq(d1.items[0]);
    const d2SameMainTypeAndMaterials = d2.mainItemTypeId.eq(d2.items[0]);
    const d1qty = d1SameMainTypeAndMaterials ? d1.quantities[0].add(1) : d1.quantities[0];
    const d2qty = d2SameMainTypeAndMaterials ? d2.quantities[0].add(1) : d2.quantities[0];
    const d1CanCraft = d1qty.lte(BigNumber.from(d1MaterialBalance));
    const d2CanCraft = d2qty.lte(BigNumber.from(d2MaterialBalance));
    if (d1CanCraft && !d2CanCraft)
      score -= 1;
    else if (!d1CanCraft && d2CanCraft)
      score += 1;

    if (parseInt(d1ItemType.slice(-1)) > parseInt(d2ItemType.slice(-1))) {
      score -= 1;
    }
    else if (parseInt(d2ItemType.slice(-1)) > parseInt(d1ItemType.slice(-1))) {
      score += 1;
    }

    return score;
  })

  return (
    <div className="blueprint-container">
      <div className="blueprint-title">Crafting Blueprints</div>
      {definitions2.map((def: any) => {
        const availableType = itemTypes?.items.filter(
          (item: any) =>
            BigNumber.from(item.id).toString() ===
            BigNumber.from(def.resultItemTypeId).toString()
        )
        const balance =
          def.quantities?.length === 0
            ? getBalanceById(def.mainItemTypeId)
            : getBalanceById(def.items[0])

        const sameMainTypeAndMaterials = def.items.length > 0 ? def.mainItemTypeId.eq(def.items[0]) : false;
        const needQuantities =
          def.quantities?.length === 0
            ? 1
            : sameMainTypeAndMaterials ? def.quantities[0].add(1) : def.quantities[0];
        const { metadata } = availableType?.length > 0 && availableType[0]
        const itemType: any =
          def.items.length > 0 ? fetchItemTypes(def.items[0]) : tokenName
        const completedProgress =
          (Number(balance) / Number(needQuantities)) * 100

        const classes = ['craft-button']
        completedProgress >= 100 && !loading
          ? classes.push('clickable')
          : classes.push('unclickable')
        return (
          // tslint:disable-next-line: jsx-key
          availableType?.length > 0 ? (
            <div className="blueprint-item-container">
              <img className="item-image" src={metadata?.image} />
              <div className="blueprint-item-description">
                <div className="blueprint-item-title">
                  {t(`nft_item.title.${metadata?.name}`)}
                </div>
                <div className="blueprint-item-sub-title">
                  {t(`nft_item.description.${metadata?.name}`)}
                </div>
                <div className="action-container">
                  <div className="progress-container">
                    <div className="token-text">{`${itemType ? t(`nft_item.title.${itemType}`) : ''
                      } [${balance}/${needQuantities}]`}</div>
                    <ProgressBar
                      className="progress-bar"
                      completed={completedProgress}
                      width="90px"
                      height="10px"
                      bgColor="rgb(0, 208, 103)"
                      baseBgColor="#2A2A2A"
                      isLabelVisible={false}
                    />
                  </div>
                  {isOwned === '0' &&
                    mainItemBalance &&
                    BigNumber.from(mainItemBalance).toString() !== '0' ? (
                    <div
                      onClick={() =>
                        completedProgress >= 100 &&
                        !loading &&
                        onCraft({
                          ...def,
                          tokenName,
                          craftItem: metadata?.name
                        })
                      }
                      className={classes.join(' ')}
                    >
                      {loading ? (
                        <Loader size="small" active={loading} />
                      ) : (
                        'Craft'
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null
        )
      })}
    </div>
  )
}

export default React.memo(BluePrint)
