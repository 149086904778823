import React from 'react'
import { t, T } from '@kmon/dapps/dist/modules/translation/utils'
import { Page, Header, Button } from '@kmon/ui'

import { locations } from '../../modules/routing/locations'
import { getNFTName } from '../../modules/nft/utils'
import { formatCoin } from '../../lib/kmon'
import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import { Wallet } from '../Wallet'
import { CoinPopup } from '../CoinPopup'
import { NFTProviderPage } from '../NFTProviderPage'
import ItemAction from '../ItemAction/ItemAction'
import { Props } from './CancelItemPage.types'
import './CancelItemPage.css'

const CancelItemPage = (props: Props) => {
  const { itemId, isOwned, isLoading, orderedItem, onNavigate, onCancelOrder } = props
  let itemName = '';
  let itemNameForCancel = '';
  let image = '';
  orderedItem.forEach((ele: any) => {
    ele.detail.forEach((subEle: any) => {
      if (subEle.itemTypeId == itemId) {
        itemName = ele.name;
        itemNameForCancel = ele.name;
        image = ele.image;
      }
    })
  });

  const filterItem = orderedItem.filter((ele: any) => ele.itemTypeId == itemId);

  itemName = itemName.replace(/_/g, ' ');
  itemName = itemName.replace(/basic/gi, 'bronze').toUpperCase()
  itemName = itemName.replace(/medium/gi, 'silver').toUpperCase()
  itemName = itemName.replace(/premium/gi, 'gold').toUpperCase()
  itemName = itemName.replace(/-/g, ' ').toUpperCase()

  return (
    <>
      <Navbar isFullscreen />
      <Page className="CancelItemPage">
        <Wallet>
          {wallet => (
            <ItemAction itemId={itemId} isOwned={isOwned} image={image}>
              <Header size="large">{t('cancel_sale_page.title')}</Header>
              <div className="subtitle">
                <T
                  id="cancel_sale_page.subtitle"
                  values={{
                    name: <b>{itemName}</b>,
                    amount: ''
                    // amount: (
                    //   <CoinPopup inline>
                    //     {formatCoin(order.price)}
                    //   </CoinPopup>
                    // )
                  }}
                />
              </div>
              <div className="buttons">
                <Button
                  onClick={() =>
                    onNavigate(
                      // locations._browseitem(itemId, isOwned)
                      locations.browseItem()
                    )
                  }
                >
                  {t('global.cancel')}
                </Button>
                {console.log(wallet)}
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => onCancelOrder(itemId, itemNameForCancel)}
                >
                  {t('cancel_sale_page.submit')}
                </Button>
              </div>
            </ItemAction>
          )}
        </Wallet>
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(CancelItemPage)
