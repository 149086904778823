import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { RouteComponentProps } from 'react-router'

import { RootState } from '../../modules/reducer'
import { getIsFullscreen, getVendor } from '../../modules/routing/selectors'
import { getWallet, isConnecting } from '../../modules/wallet/selectors'
import {
    Params,
    MapStateProps,
    MapDispatch,
    MapDispatchProps
} from './ItemBrowse.types'
import ItemBrowse from './ItemBrowse'
import { fetchMyItemsRequest } from '../../modules/item/actions'

const mapState = (
    state: RootState,
    ownProps: RouteComponentProps<Params>
): MapStateProps => {
    const { address } = ownProps.match.params

    return {
        address: address?.toLowerCase(),
        vendor: getVendor(state),
        wallet: getWallet(state),
        isConnecting: isConnecting(state),
        isFullscreen: getIsFullscreen(state)
    }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
    onRedirect: path => dispatch(replace(path)),
    onFetchMyItems: () => dispatch(fetchMyItemsRequest())
})

export default connect(mapState, mapDispatch)(ItemBrowse)
