import React, { useEffect } from 'react'
import { Navbar } from '../Navbar'

import { Props } from './BNFTPage.types'
import { Navigation } from '../Navigation'
import { NavigationTab } from '../Navigation/Navigation.types'
import { locations } from '../../modules/routing/locations'
import { BNFTCard } from './BNFTCard'
import { Page, Loader } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { Card } from 'semantic-ui-react'
import ProductImagesSlider from './ProductImageSlider'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/thumbs/thumbs.min.css'
import { Header } from '../Header'
import { getIfCommunityMember } from '../../modules/bnft/utils'

const BNFTPage = (props: Props) => {
  const {
    isConnecting,
    wallet,
    authorizations,
    onNavigate,
    onPlaceBid,
    isPlacingBid,
    bnftItems,
    onFetchBNFTBalance,
    onFetchCommunityProducts,
    onRedirect,
    isBNFTRequestLoading,
    isBNFTSuccessLoading
  } = props

  // useEffect(() => {
  //   const checkIfCommunity = wallet && getIfCommunityMember(wallet)
  //   !checkIfCommunity ? onFetchBNFTBalance() : onFetchCommunityProducts()
  // }, [])

  useEffect(() => {
    const checkIfCommunity = wallet && getIfCommunityMember(wallet)
    wallet &&
      (!checkIfCommunity ? onFetchBNFTBalance() : onFetchCommunityProducts())
  }, [wallet])

  const NotFound = () => (
    <div className="nft-center">
      <p className="secondary-text">{t('bnft_page.not_found')}&hellip;</p>
    </div>
  )

  console.log('BNFT loading-->', isBNFTRequestLoading, isBNFTSuccessLoading)
  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation activeTab={NavigationTab.BNFT} />
      </div>
      {wallet && isBNFTRequestLoading && <Loader size="massive" active />}
      <Header
        title={t('bnft_page.title')}
        subTitle={t('bnft_page.sub_title')}
        link={{
          text: 'our Telegram bug channel.',
          link: 'https://t.me/+kQuwnMrJA9w0YThl'
        }}
        content={
          <>
            <p>Steps to redeem</p>
            <span>
              (1) Connect your wallet that has the Hunter Pack NFT token
              <br />
            </span>
            <span>
              (2) Select hunter pack you want to redeem
              <br />
            </span>
            <span>
              (3) Select your size
              <br />
            </span>
            <span>
              (4) Click add to cart
              <br />
            </span>
            <span>
              (5) Fill in your address and contact details
              <br />
            </span>
            <span>
              (6) Check your mail and click redeem
              <br />
            </span>
            <span>
              (7) Approve wallet transaction and wait till your items are minted
              to your wallet
              <br />
            </span>
            <span>
              (7) Enjoy your Hunter Pack
              <br />
            </span>
          </>
        }
        wallet={wallet}
      />
      <Page className="NFTBrowse">
        {bnftItems.length === 0 && wallet && !isBNFTRequestLoading && (
          <NotFound />
        )}
        <Card.Group>
          {bnftItems &&
            bnftItems.map((item: any) => {
              return <BNFTCard item={item} />
            })}
        </Card.Group>
      </Page>
    </>
  )
}

export default React.memo(BNFTPage)
