import { createMatchSelector } from 'connected-react-router'
import { createSelector } from 'reselect'

import { locations } from '../routing/locations'
import { RootState } from '../reducer'
import { Item, AllItem } from './types'
import { ItemState } from './reducer'
import { BigNumber } from 'ethers'

export const getState = (state: RootState) => state.item
export const getData = (state: RootState) => getState(state).data
export const getAllData = (state: RootState) => getState(state).allItems
export const getMyData = (state: RootState) => getState(state).myItems
export const getOrderedData = (state: RootState) => getState(state).orderedItems
export const getOrdredItemGroups = (state: RootState) => getState(state).orderedItemsGroup
export const getCraftingStarted = (state: RootState) =>
  getState(state).craftingStarted
export const getCraftingFinished = (state: RootState) =>
  getState(state).craftingFinished
export const getUpgradeDefinitions = (state: RootState) =>
  getState(state).upgradeDefinitions
export const getItemTypes = (state: RootState) => getState(state).itemTypes
export const getMaterialItemTypes = (state: RootState) =>
  getState(state).materialItemTypes
export const getLoading = (state: RootState) => getState(state).loading
export const getItemBalance = (state: RootState) =>
  getState(state).itemBalance?.items
export const getError = (state: RootState) => getState(state).error

const itemDetailMatchSelector = createMatchSelector<
  RootState,
  {
    id: string
  }
>(locations.item(':id'))

const _itemDetailMatchSelector = createMatchSelector<
  RootState,
  {
    id: string
  }
>(locations._item(':id'))

const _orderedItemDetailMatchSelector = createMatchSelector<
  RootState,
  {
    id: string
  }
>(locations._browseitem(':id'))

export const getItemId = createSelector<
  RootState,
  ReturnType<typeof itemDetailMatchSelector>,
  string | null
>(itemDetailMatchSelector, match => match?.params.id || null)

export const getCurrentItem = createSelector<
  RootState,
  string | null,
  ItemState['data'],
  Item | undefined
>(
  state => getItemId(state),
  state => getData(state),
  (itemId, items) => {
    if (itemId === null) {
      return undefined
    }
    return items.find(item => item.itemId === itemId)
  }
)

export const _getItemId = createSelector<
  RootState,
  ReturnType<typeof _itemDetailMatchSelector>,
  string | null
>(_itemDetailMatchSelector, match => match?.params.id || null)

export const _getOrderedItemId = createSelector<
  RootState,
  ReturnType<typeof _orderedItemDetailMatchSelector>,
  string | null
>(_orderedItemDetailMatchSelector, match => match?.params.id || null)

export const _getCurrentItem = createSelector<
  RootState,
  string | null,
  ItemState['allItems'],
  AllItem | undefined
>(
  state => _getItemId(state),
  state => getAllData(state),
  (itemId, items) => {
    if (itemId === null) {
      return undefined
    }
    return items.find(
      (item: any) => BigNumber.from(item.itemTypeId).toString() === itemId
    )
  }
)

export const getMyCurrentItem = createSelector<
  RootState,
  string | null,
  ItemState['myItems'],
  AllItem | undefined
>(
  state => _getItemId(state),
  state => getMyData(state),
  (itemId, items) => {
    if (itemId === null) {
      return undefined
    }
    const result = items.find((item: any) => {
      return BigNumber.from(item.itemTypeId).toString() === itemId
    })

    return result
  }
)

export const getOrderedCurrentItem = createSelector<
  RootState,
  string | null,
  ItemState['orderedItems'],
  AllItem | undefined
>(
  state => _getOrderedItemId(state),
  state => getOrderedData(state),
  (itemId, items) => {
    if (itemId === null) {
      return undefined
    }
    const result = items.find((item: any) => {
      console.log('ITEM=>', item)
      return BigNumber.from(item.baseType).toString() === itemId
    })
    console.log("result ->", result);

    return result
  }
)

export const _getOrderedCurrentItem = createSelector<
  RootState,
  string | null,
  ItemState['orderedItems'],
  AllItem | undefined
>(
  state => _getOrderedItemId(state),
  state => getOrderedData(state),
  (itemId, items) => {
    if (itemId === null) {
      return undefined
    }
    console.log('ITEM=>', items)
    return items.find(
      (item: any) => BigNumber.from(item.baseType).toString() === itemId
    )
  }
)