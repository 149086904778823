import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { push, goBack } from 'connected-react-router'
import { Button, Container, Field, Form, Modal, Page, Header } from '@kmon/ui'
import { ethers, providers } from 'ethers'
import { Loader } from 'semantic-ui-react'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import { fromWei } from 'web3x-es/utils'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'
import { Address } from 'web3x-es/address'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { ContractName } from '@kmon/transactions'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'

import { locations } from '../../../modules/routing/locations'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { AuthorizationModal } from '../../AuthorizationModal'
import { Props } from './TransferPage.types'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { Navbar } from '../../Navbar'
import { Navigation } from '../../Navigation'
import { Footer } from '../../Footer'
import { Details } from '../Details'
import { LootboxDetailCard } from '../LootboxDetailCard'
import './TransferPage.css'
import { DescriptionBlock } from '../DescriptionBlock'
import { TitleBlock } from '../../NFTPage/TitleBlock'
import { Item, ItemVersion } from '../../../modules/item/types'
import { fetchIPFS } from '../../../modules/item/utils'
import { images } from '../LootboxesPage'
import { fromItemCount, toItemCount } from '../../../lib/number'
import { ConfirmModal } from '../ConfirmModal'
import { BigNumber } from 'ethers'
import BoundCraftNFT from '../../../images/items/Bounded-craft-static.svg'
import { CraftingModal } from '../CraftingModal'
import { KmonftDiamond } from '../../../modules/item/diamond'
import { addresses } from '../../../modules/item/addresses'
import { NavigationTab } from '../../Navigation/Navigation.types'

const TransferPage = (props: Props) => {
  const dispatch = useDispatch()
  const {
    wallet,
    authorizations,
    isConnecting,
    isLoading,
    isBuyingItem,
    isCraftingItem,
    isTransferringItem,
    itemId,
    isOwned,
    currentItem,
    upgradeDefinitions,
    itemTypes,
    materialItemTypes,
    myItems,
    itemBalance,
    craftingStarted,
    craftingFinished,
    onFetchItem,
    onFetchAllItems,
    onFetchMyItems,
    onFetchItemTypes,
    onFetchMaterialItemTypes,
    onBuyItem,
    onCraftItem,
    onTransfer,
    onFetchItemBalance,
    onBuyItemWithCandies
  } = props

  const [metaData, setMedata] = useState({} as any)
  const [address, setAddress] = useState('')
  const [isInvalidAddress, setIsInvalidAddress] = useState(false)
  const [isAuthorizing, setIsAuthorizing] = useState(false)

  let isDisabled =
    !address || isInvalidAddress || isTransferringItem || isAuthorizing

  useEffect(() => {
    onFetchAllItems()
    wallet && onFetchMyItems()
  }, [wallet])

  useEffect(() => {
    onFetchItemBalance(itemId)
    if (itemId !== undefined) {
      isOwned === '0' ? onFetchMyItems() : onFetchAllItems()
    }
  }, [itemId])

  console.log('Current item-->', isTransferringItem)

  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)

  const contractNames = getContractNames()

  const kmon = getContract({
    name: contractNames.KMONToken
  })
  const _chainId = Number(getConnectedProviderChainId())
  const item = getContract({
    name: contractNames.KMONFTV2
  })

  if (!wallet) {
    return null
  }

  const authorization: Authorization = {
    address: wallet.address,
    authorizedAddress: item.address,
    contractAddress: item.address,
    contractName: ContractName.KMONFTV2,
    chainId: _chainId,
    type: AuthorizationType.APPROVAL
  }

  const checkAuthorization = async () => {
    const connectedProvider = await getConnectedProvider()
    if (!connectedProvider) {
      throw new Error('Provider not connected')
    }

    const provider = await new providers.Web3Provider(connectedProvider)
    const chainId = Number(getConnectedProviderChainId())
    const _addresses: any = addresses

    const contract = await new ethers.Contract(
      _addresses[chainId]?.KmonftDiamond,
      KmonftDiamond,
      provider.getSigner()
    )

    const isapproved = await contract.isApprovedForAll(
      wallet.address,
      _addresses[chainId]?.KmonftDiamond
    )

    return isapproved
  }

  const handleTransfer = async () => {
    // setShowConfirmModal(true)
    setIsAuthorizing(true)
    const approved = await checkAuthorization()
    if (approved) {
      setIsAuthorizing(false)
      onTransfer({
        tokenName: currentItem.name,
        tokenId: currentItem.itemTypeId,
        address,
        balance: itemBalance
      })
    } else {
      setShowAuthorizationModal(true)
      setIsAuthorizing(false)
    }
  }

  const handleClose = () => setShowAuthorizationModal(false)

  const TransferPage = () => {
    return (
      <Page className="TransferPage">
        <div className="NFTAction">
          <Row className="Row">
            <Column align="left">
              {currentItem && (
                <LootboxDetailCard
                  name={currentItem.name || ''}
                  image={currentItem.image}
                  price={fromWei(
                    BigNumber.from(currentItem.usdPrice).toString(),
                    'ether'
                  )}
                  kmonPrice={fromWei(
                    BigNumber.from(currentItem.usdPrice)
                      .div(BigNumber.from(currentItem.rate))
                      .toString(),
                    'wei'
                  )}
                  priceWithCandies={fromWei(
                    BigNumber.from(currentItem.candiesPrice || 0).toString(),
                    'wei'
                  )}
                  isNFT={currentItem.isNFT}
                  itemType={metaData?.properties?.type}
                  canBeTransferred={currentItem.canBeTransferred}
                  balance={itemBalance}
                  isOwned={isOwned}
                />
              )}
            </Column>
            <Column align="right">
              <Header size="large">Transfer Item</Header>
              <div className="subtitle">
                <T
                  id="transfer_page.subtitle"
                  values={{ name: <b>{currentItem?.name || ''}</b> }}
                />
              </div>
              <Form>
                <div className="form-fields">
                  <Field
                    type="address"
                    error={isInvalidAddress}
                    message={
                      isInvalidAddress
                        ? t('transfer_page.invalid_address')
                        : undefined
                    }
                    label={t('transfer_page.recipient')}
                    value={address}
                    placeholder="0x..."
                    disabled={!currentItem.canBeTransferred}
                    onChange={(_event, props) => {
                      setAddress(props.value)
                      const isValid =
                        !props.value || /^0x[a-fA-F0-9]{40}$/g.test(props.value)
                      setIsInvalidAddress(!isValid)
                    }}
                  />
                </div>
                {currentItem.canBeTransferred ? (
                  <div className="warning">
                    <T id="transfer_page.warning" values={{ br: <br /> }} />
                  </div>
                ) : null}
                <div className="buttons">
                  <Button
                    as="div"
                    onClick={() =>
                      dispatch(push(locations._item(itemId, isOwned)))
                    }
                  >
                    {t('global.cancel')}
                  </Button>
                  <Button
                    // type="submit"
                    onClick={() => handleTransfer()}
                    // loading={isTransfering}
                    disabled={isDisabled}
                  >
                    {t('transfer_page.submit')}
                  </Button>
                </div>
              </Form>
              <div className="lootbox-separator" />
            </Column>
          </Row>
          <AuthorizationModal
            open={showAuthorizationModal}
            authorization={authorization}
            isLoading={isTransferringItem}
            onProceed={() =>
              onTransfer({
                tokenId: currentItem.name,
                address,
                balance: itemBalance
              })
            }
            onCancel={handleClose}
          />
        </div>
      </Page>
    )
  }

  const Loading = () => (
    <div className="nft-center">
      <Loader active size="huge" />
    </div>
  )

  const NotFound = () => (
    <div className="nft-center">
      <p className="secondary-text">{t('global.not_found')}&hellip;</p>
    </div>
  )

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation isFullscreen activeTab={NavigationTab.MY_ASSETS} />
      </div>
      <Page className="NFTPage" isFullscreen>
        {/* <TransferPage /> */}
        {isConnecting ? <Loading /> : null}
        {!isConnecting && !currentItem ? <NotFound /> : null}
        {!isConnecting && currentItem !== undefined ? <TransferPage /> : null}
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(TransferPage)
