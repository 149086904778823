import {
  LoadingState,
  loadingReducer
} from '@kmon/dapps/dist/modules/loading/reducer'
import {
  FetchBNFTBalanceRequestAction,
  FetchBNFTBalanceFailureAction,
  FetchBNFTBalanceSuccessAction,
  SaveBNFTCheckoutRequestAction,
  FetchCommunityProductsFailureAction,
  FetchCommunityProductsRequestAction,
  FetchCommunityProductsSuccessAction,
  FetchCommunityOrderFailureAction,
  FetchCommunityOrderRequestAction,
  FetchCommunityOrderSuccessAction,
  FetchOrderDetailsFailureAction,
  FetchOrderDetailsRequestAction,
  FetchOrderDetailsSuccessAction,
  FETCH_BNFT_BALANCE_REQUEST,
  FETCH_BNFT_BALANCE_SUCESS,
  FETCH_BNFT_BALANCE_FAILURE,
  FETCH_COMMUNITY_PRODUCTS_REQUEST,
  FETCH_COMMUNITY_PRODUCTS_SUCESS,
  FETCH_COMMUNITY_PRODUCTS_FAILURE,
  FETCH_COMMUNITY_ORDER_FAILURE,
  FETCH_COMMUNITY_ORDER_REQUEST,
  FETCH_COMMUNITY_ORDER_SUCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_SUCESS,
  SAVE_BNFT_CHECKOUT_REQUEST
} from './actions'
import { BNFTItem } from './types'
import { ethers } from 'ethers'

export type BNFTState = {
  loading: LoadingState
  data: BNFTItem[] | []
  error: string | null
  tokenIds: any
  bnftBalance: any
  tokenBalances: any
  checkout: any
  ownedNFTs: any
  tokenBalancesByIds: any
  communityRedeeption: any
  orderedItems: any
}

const INITIAL_STATE = {
  loading: [],
  data: [],
  error: null,
  tokenIds: [],
  bnftBalance: 0,
  tokenBalances: [],
  tokenBalancesByIds: null,
  checkout: { lineItems: [], id: '' },
  ownedNFTs: [],
  communityRedeeption: null,
  orderedItems: null
}

type BNFTReducerAction =
  | FetchBNFTBalanceRequestAction
  | FetchBNFTBalanceSuccessAction
  | FetchBNFTBalanceFailureAction
  | SaveBNFTCheckoutRequestAction
  | FetchCommunityProductsFailureAction
  | FetchCommunityProductsRequestAction
  | FetchCommunityProductsSuccessAction
  | FetchCommunityOrderFailureAction
  | FetchCommunityOrderRequestAction
  | FetchCommunityOrderSuccessAction
  | FetchOrderDetailsFailureAction
  | FetchOrderDetailsRequestAction
  | FetchOrderDetailsSuccessAction

export function bnftReducer(
  state: BNFTState = INITIAL_STATE,
  action: BNFTReducerAction
): BNFTState {
  switch (action.type) {
    case FETCH_BNFT_BALANCE_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case FETCH_COMMUNITY_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case FETCH_COMMUNITY_ORDER_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case FETCH_COMMUNITY_PRODUCTS_SUCESS:
      return {
        ...state,
        data: action.payload.items[0],
        tokenIds: action.payload.items[1],
        bnftBalance: action.payload.items[2],
        tokenBalances: action.payload.items[3],
        ownedNFTs: action.payload.items[4],
        tokenBalancesByIds: action.payload.items[5],
        loading: loadingReducer(state.loading, action),
        error: null
      }
    case FETCH_COMMUNITY_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case FETCH_COMMUNITY_ORDER_SUCESS:
      return {
        ...state,
        communityRedeeption: action.payload.items,
        loading: loadingReducer(state.loading, action),
        error: null
      }
    case FETCH_BNFT_BALANCE_SUCESS:
      return {
        ...state,
        data: action.payload.items[0],
        tokenIds: action.payload.items[1],
        bnftBalance: action.payload.items[2],
        tokenBalances: action.payload.items[3],
        ownedNFTs: action.payload.items[4],
        tokenBalancesByIds: action.payload.items[5],
        loading: loadingReducer(state.loading, action),
        error: null
      }
    case FETCH_BNFT_BALANCE_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    case FETCH_ORDER_DETAILS_REQUEST:
    case FETCH_ORDER_DETAILS_FAILURE:
    case FETCH_ORDER_DETAILS_SUCESS:
      return {
        ...state,
        orderedItems: action.payload,
        loading: loadingReducer(state.loading, action),
        error: null
      }
    case SAVE_BNFT_CHECKOUT_REQUEST:
      return {
        ...state,
        checkout: action.payload.checkout
      }
    default:
      return state
  }
}
