import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@kmon/ui'

import { Props } from './Actions.types'
import { locations } from '../../../modules/routing/locations'
import { ItemVersion } from '../../../modules/item/types'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

const Actions = (props: Props) => {
  const { isTxPending, onBuy, isBid, isTransfer, itemId, isSell, isUpdate } = props

  const classes = ['lootbox-action-button']
  isBid ? classes.push('bid-action') : classes.push('bid-action')

  return (
    <div className={classes.join(' ')}>
      {!isTransfer ? (
        !isSell ? (
          <Button
            disabled={isTxPending}
            loading={isTxPending}
            onClick={() => onBuy(ItemVersion.V2)}
            primary
          >
            {isBid ? t('lootbox_page.bid') : t('lootbox_page.buy')}
          </Button>
        ) : (
          isUpdate ?
            <Button
              Sizes
              size="medium"
              onClick={() => onBuy(ItemVersion.V2)}
            // as={Link}
            // to={locations.transferitem(itemId, itemId)}
            >
              {t('nft_page.update')}
            </Button>
            :
            <Button
              onClick={() => onBuy(ItemVersion.V2)}
            // as={Link}
            // to={locations.transferitem(itemId, itemId)}
            >
              {t('nft_page.sell')}
            </Button>
        )
      ) : (
        isUpdate ?
          <Button
            as={Link}
            to={locations.cancelitem(itemId, '0')}
          >
            {t('nft_page.cancel_listing')}
          </Button>
          :
          <Button
            onClick={() => onBuy(ItemVersion.V2)}
          // as={Link}
          // to={locations.transferitem(itemId, itemId)}
          >
            {t('nft_page.transfer')}
          </Button>
      )}
      {/* {isTransfer && <Button>{t('nft_page.transfer')}</Button>} */}
    </div>
  )
}

export default React.memo(Actions)
