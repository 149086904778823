import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { MapStateProps, MapDispatchProps } from './Banner.types'
import Banner from './Banner'
import { getSubgraphTimestamp } from '../../modules/subgraph/selectors'

const mapState = (state: RootState): MapStateProps => {
  return {
    subgraphTimestamp: getSubgraphTimestamp(state)
  }
}

const mapDispatch = (): MapDispatchProps => ({})

export default connect(mapState, mapDispatch)(Banner)
