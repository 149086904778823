import React, { useCallback, useState, useEffect } from 'react'
import { Card, Button, Loader } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { getAnalytics } from '@kmon/dapps/dist/modules/analytics/utils'

import { getMaxQuerySize, MAX_PAGE, PAGE_SIZE } from '../../modules/vendor/api'
import { NFTCard } from '../NFTCard'
import { Props } from './OrderItemList.types'
import { ItemCard } from './ItemCard'
import { IndexingDelay } from '../IndexingDelayCard'
import { useIndexingDelay } from '../../hooks'
import { BigNumber } from 'ethers'
import { fromWei } from 'web3x-es/utils'
import { Coin } from '@kmon/schemas'
import { Address } from 'web3x-es/address'

const OrderItemList = (props: Props) => {
  const {
    vendor,
    nfts,
    page,
    count,
    subgraphTimestamp,
    isLoading,
    isPreventClick,
    myNFT,
    itemGroups,
    pathname,
    isbreeding,
    onClickCard
  } = props

  const { showIndexingDelay } = useIndexingDelay(
    subgraphTimestamp
  )

  const maxQuerySize = getMaxQuerySize(vendor)

  const hasExtraPages =
    (nfts.length !== count || count === maxQuerySize) && page <= MAX_PAGE

  const isLoadingNewPage = isLoading && nfts.length >= PAGE_SIZE

  return (
    <>
      <Card.Group>
        {showIndexingDelay && <IndexingDelay />}
        {nfts.length > 0 && !/^\/browseItem/gi.test(pathname)
          ? nfts.map((nft, index) => (
            <NFTCard
              key={nft.id + '-' + index}
              nft={nft}
              status={{ showPrice: true }}
              isPreventClick={isPreventClick}
              onClickCard={onClickCard}
              isbreeding={isbreeding}
            />
          ))
          : null}
        {isLoading ? (
          <>
            <div className="overlay" />
            <Loader size="massive" active />
          </>
        ) : null}
      </Card.Group>
      {itemGroups.length > 0 && /^\/account/gi.test(pathname) || itemGroups.length > 0 && /^\/browseItem/gi.test(pathname) ? (
        <Card.Group>
          {itemGroups.map((item: any, id: number) => {
            return (
              <ItemCard
                key={id}
                itemId={BigNumber.from(item.baseType).toString()}
                groupName={item.name}
                image={item.image}
              />
            )
          })}
        </Card.Group>
      ) : <div className="empty">{t('nft_list.empty')}</div>}

      {/* {nfts.length > 0 && hasExtraPages && (!isLoading || isLoadingNewPage) ? (
        <div className="load-more">
          <Button loading={isLoading} primary onClick={handleLoadMore}>
            {t('global.load_more')}
          </Button>
        </div>
      ) : null} */}
    </>
  )
}

export default React.memo(OrderItemList)
