import React from 'react'
import { KIcon } from '@kmon/ui'
import { Props, ItemProps } from './Details.types'
import './Details.css'
import { Actions } from '../Actions'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

const Details = (props: Props) => {
  const {
    name,
    usdPrice,
    price,
    priceWithCandies,
    isTxPending,
    isOwned,
    itemId,
    canBeTransferred,
    isUpdate,
    onBuyItem,
    onBuyItemWithCandies,
    onTransferItem,
    onSellItem
  } = props

  return (
    <div className="LootboxDetails grid-container">
      {!(
        /hunter/i.test(name) ||
        /hoodie/i.test(name) ||
        price === '0' ||
        isOwned === '0'
      ) ? (
        <DetailItem title={t('lootbox_page.price_with_kmon')}>
          <p className="detail-big-text">{`${price} kmon($${usdPrice}) `}</p>
        </DetailItem>
      ) : null}
      <div className="lootbox-actions-container">
        {isOwned !== '0' && price !== '0' && (
          <Actions isTxPending={isTxPending} onBuy={onBuyItem} isUpdate={isUpdate} />
        )}
        {/* <Actions isTxPending={isTxPending} onBuy={onBuyItemWithCandies} isBid /> */}
      </div>
      {!(
        /hunter/i.test(name) ||
        /hoodie/i.test(name) ||
        priceWithCandies === '0' ||
        isOwned === '0'
      ) ? (
        <DetailItem title={t('lootbox_page.price_with_candy')}>
          <p className="detail-big-text">
            <KIcon icon="candy" inline>
              {priceWithCandies}
            </KIcon>
          </p>
        </DetailItem>
      ) : null}
      <div className="lootbox-actions-container">
        {isOwned === '0' && canBeTransferred && (
          <Actions
            isTxPending={isTxPending}
            onBuy={onSellItem}
            isSell
            isUpdate={isUpdate}
            itemId={itemId}
          // disabled={priceWithCandies === '0'}
          />
        )}
        {isOwned !== '0' && priceWithCandies !== '0' && (
          <Actions
            isUpdate={isUpdate}
            isTxPending={isTxPending}
            onBuy={onBuyItemWithCandies}
            disabled={priceWithCandies === '0'}
          />
        )}
        {isOwned === '0' && canBeTransferred && (
          <Actions
            isUpdate={isUpdate}
            isTxPending={isTxPending}
            onBuy={onTransferItem}
            isTransfer
            itemId={itemId}
          // disabled={priceWithCandies === '0'}
          />
        )}
        {/* <Actions isTxPending={isTxPending} onBuy={onBuyItemWithCandies} isBid /> */}
      </div>
    </div>
  )
}

export default React.memo(Details)

const DetailItem = ({ title, children }: ItemProps) => {
  return (
    <div className="block-title">
      <p className="block-title-text">{title}</p>
      {children}
    </div>
  )
}
