import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { RootState } from '../../modules/reducer'
import { getData as getAuthorizations } from '@kmon/dapps/dist/modules/authorization/selectors'
import { isLoadingType } from '@kmon/dapps/dist/modules/loading/selectors'
import { getWallet, isConnecting } from '../../modules/wallet/selectors'
import {
  fetchBNFTBalanceRequest,
  fetchCommunityProductsRequest,
  FETCH_BNFT_BALANCE_REQUEST,
  FETCH_BNFT_BALANCE_SUCESS
} from '../../modules/bnft/actions'
import {
  getData as getBNFTItems,
  getLoading as getBNFTLoading
} from '../../modules/bnft/selectors'
import { placeBidRequest, PLACE_BID_REQUEST } from '../../modules/bid/actions'
import { getLoading } from '../../modules/bid/selectors'
import { MapStateProps, MapDispatchProps, MapDispatch } from './BNFTPage.types'
import BNFTPage from './BNFTPage'

const mapState = (state: RootState): MapStateProps => {
  return {
    wallet: getWallet(state),
    isConnecting: isConnecting(state),
    authorizations: getAuthorizations(state),
    isPlacingBid: isLoadingType(getLoading(state), PLACE_BID_REQUEST),
    bnftItems: getBNFTItems(state),
    isBNFTRequestLoading: isLoadingType(
      getBNFTLoading(state),
      FETCH_BNFT_BALANCE_REQUEST
    ),
    isBNFTSuccessLoading: isLoadingType(
      getBNFTLoading(state),
      FETCH_BNFT_BALANCE_SUCESS
    )
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onNavigate: path => dispatch(push(path)),
  onRedirect: path => dispatch(replace(path)),
  onPlaceBid: (nft, price, expiresAt, fingerprint) =>
    dispatch(placeBidRequest(nft, price, expiresAt, fingerprint)),
  onFetchBNFTBalance: () => dispatch(fetchBNFTBalanceRequest()),
  onFetchCommunityProducts: () => dispatch(fetchCommunityProductsRequest())
})

export default connect(mapState, mapDispatch)(BNFTPage)
