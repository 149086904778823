import { Address } from 'web3x-es/address'
import { fromWei } from 'web3x-es/utils'
import Web3 from 'web3'
import { ethers, providers, BigNumber } from 'ethers'
import { fetchJson } from 'ethers/lib/utils'
import { getContract, ContractName as CN } from '@kmon/transactions'
import { Wallet } from '@kmon/dapps/dist/modules/wallet/types'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'
import { ContractFactory } from '../contract/ContractFactory'
import { Marketplace } from '../../contracts/Marketplace'
import { Item } from '../../contracts/Item'
import { sendTransaction } from '../wallet/utils'
import { AllItem, ItemVersion } from './types'
import { KmonftDiamond } from './diamond'
import { addresses } from './addresses'
import { toWei } from 'web3x-es/utils'

export const REACT_APP_API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL!
export const fetchIPFS = async (uri: any) => {
  const _metaData = getIPFSUrl(uri)
  const res = await fetchJson(await _metaData)
  return res
}

export const getIPFSUrl = async (uri: string) => {
  if (!uri) {
    return ''
  } else {
    if (uri.indexOf('ipfs://') > -1)
      return `https://ipfs.kryptomon.co/ipfs/${uri.replace('ipfs://', '')}`
    else
      return uri;
  }
}

export async function getKMONExchangeRate() {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)

  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.KmonftDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  const res = await contract.getKMONExchangeRate()
  return res
}

export async function fetchItemTypes(ids: any) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids: ids
    })
  }

  try {
    const response: any = await fetch(
      `${REACT_APP_API_SERVER_URL}/nft-items/type`,
      requestOptions
    ).then(resp => resp.json())
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function fetchNFTItemsBalance(wallet: Wallet) {
  try {
    const response: any = await fetch(
      `${REACT_APP_API_SERVER_URL}/user/${wallet?.address}/nft-items?limit=1000`
    ).then(resp => resp.json())
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function getNFTItemImage(wallet: Wallet, name: any) {
  try {
    const balance = await fetchNFTItemsBalance(wallet)
    const filteredArray: any =
      balance?.length > 0 &&
      balance.filter((item: any) => item?.metadata?.name === name)
    return filteredArray.length > 0 && filteredArray[0]?.metadata?.image
  } catch (err) {
    console.log(err)
  }
}

export async function getNFTItemTypeId(wallet: Wallet, name: any) {
  try {
    const balance = await fetchNFTItemsBalance(wallet)
    const filteredArray: any =
      balance?.length > 0 &&
      balance.filter((item: any) => item?.metadata?.name === name)
    return filteredArray.length > 0 && filteredArray[0].itemId
  } catch (err) {
    console.log(err)
  }
}

export async function getNFTItemBalanceByTypeId(wallet: Wallet, id: any) {
  try {
    const balance = await fetchNFTItemsBalance(wallet)
    const filteredArray: any =
      balance?.length > 0 && balance.filter((item: any) => item?.itemId === id)
    return filteredArray.length > 0 && filteredArray[0].balance
  } catch (err) {
    console.log(err)
  }
}

export async function fetchAllItems() {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.nftItemsDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  const res = await contract.getAllPurchasableItems()
  const rate = await contract.getKMONExchangeRate()
  const crafts = await contract.getUpgradeItemsDefinitions()

  var result: any[] = res.map((item: any) => ({
    itemTypeId: item.itemTypeId,
    baseTypeId: item.itemTypeId,
    name: item.itemType.name,
    candiesPrice: item.candiesPrice,
    usdPrice: item[2]?.usdPrice,
    image: item.itemType.uri,
    isNFT: item.itemType.isNFT,
    canBeTransferred: item.itemType.canBeTransferred,
    category: item.itemType.category,
    maxQuantity: item.itemType.maxQuantity,
    balance: item.balance,
    rate,
    isOwned: '1'
  }))

  return [result, crafts]
}

export async function fetchItemsBalance(wallet: Wallet) {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.nftItemsDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  const res = await contract.itemBalancesWithTypes(wallet.address)
  const rate = await contract.getKMONExchangeRate()

  var result: any[] = res.map((item: any) => ({
    itemTypeId: item.itemId,
    baseTypeId: item.itemTypeId,
    name: item.itemType.name,
    candiesPrice: item.candiesPrice,
    usdPrice: item.itemType.usdPrice,
    image: item.itemType.uri,
    isNFT: item.itemType.isNFT,
    canBeTransferred: item.itemType.canBeTransferred,
    category: item.itemType.category,
    maxQuantity: item.itemType.maxQuantity,
    balance: item.balance,
    isOwned: '0',
    rate
  }))

  return result
}

export async function fetchItemsBalanceById(wallet: Wallet, id: any) {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.nftItemsDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  let res = await contract['balanceOf(address,uint256)'](wallet.address, id)
  // For emergency
  if (id.length > 64) res = BigNumber.from('1');
  // End emergency
  return res
}

export async function fetchCraftItem(param: any, wallet: Wallet) {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }
  const mainItemId = await getNFTItemTypeId(wallet, param.tokenName)

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.nftItemsDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  const { itemUpgradeId, mainItemTypeId, items, quantities } = param
  const res = await contract.upgradeItem(
    itemUpgradeId,
    mainItemId,
    items,
    quantities
  )
  return res
}

export async function transferItem(
  tokenId: any,
  address: any,
  wallet: Wallet
) {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.nftItemsDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  const res = await contract[
    'safeBatchTransferFrom(address,address,uint256[],uint256[],bytes)'
  ](wallet.address, address, [tokenId], [1], [])

  return res.hash
}

export async function fetchItems() {
  const itemFactory = getContract(
    CN.Item,
    Number(getConnectedProviderChainId())
  )
  const item = await ContractFactory.build(Item, itemFactory.address)
  return item.methods.getItems().call()
}

export async function fetchItemsWithCandies() {
  const itemFactory = getContract(
    CN.Item,
    Number(getConnectedProviderChainId())
  )
  const item = await ContractFactory.build(Item, itemFactory.address)
  return item.methods.getItemCandies().call()
}

export async function fetchItem(itemId: string) {
  const itemFactory = getContract(
    CN.Item,
    Number(getConnectedProviderChainId())
  )
  const item = await ContractFactory.build(Item, itemFactory.address)
  return item.methods.nameToItem(itemId).call()
}

export async function fetchItemWithCandies(itemId: string) {
  const itemFactory = getContract(
    CN.Item,
    Number(getConnectedProviderChainId())
  )
  const item = await ContractFactory.build(Item, itemFactory.address)
  return item.methods.nameToItemCandy(itemId).call()
}

export async function buyItem(
  wallet: Wallet | null,
  version: ItemVersion,
  itemId: string,
  count: number,
  to: Address
) {
  const itemFactory = getContract(
    CN.Item,
    Number(getConnectedProviderChainId())
  )
  const item = await ContractFactory.build(Item, itemFactory.address)

  if (!wallet) {
    throw new Error('Invalid address. Wallet must be connected.')
  }

  const from = Address.fromString(wallet.address)

  let buyItem
  if (version === ItemVersion.V1) {
    buyItem = item.methods.buyLootbox(to, itemId)
  } else if (version === ItemVersion.V2) {
    buyItem = item.methods.buyItem(to, itemId, count)
  }

  if (buyItem === undefined) return
  return sendTransaction(buyItem, itemFactory, from)
}

export async function _buyItem(
  wallet: Wallet | null,
  // version: ItemVersion,
  item: AllItem,
  count: number,
  withCandies: boolean
  // to: Address
) {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId]?.nftItemsDiamond,
    KmonftDiamond,
    provider.getSigner()
  )

  if (!wallet) {
    throw new Error('Invalid address. Wallet must be connected.')
  }
  const from = Address.fromString(wallet.address)

  const res = await contract.purchaseItems(
    wallet.address,
    [BigNumber.from(item.itemTypeId).toString()],
    [count],
    withCandies
  )
  console.log('Buy item--->', res)
  return res
}

export async function buyItemWithCandies(
  wallet: Wallet | null,
  itemId: string,
  price: string,
  count: number,
  to: Address
) {
  const itemFactory = getContract(
    CN.Item,
    Number(getConnectedProviderChainId())
  )
  const item = await ContractFactory.build(Item, itemFactory.address)

  if (!wallet) {
    throw new Error('Invalid address. Wallet must be connected.')
  }

  const candiesInWei = BigNumber.from(price)
    .mul(BigNumber.from(count))
    .toString()

  const from = Address.fromString(wallet.address)
  const buyItem = item.methods.buyItemWithCandies(
    to,
    itemId,
    count,
    candiesInWei
  )

  return sendTransaction(buyItem, itemFactory, from)
}

export async function createOrder1155(
  wallet: Wallet | null,
  itemId: string,
  numberOfItem: number = 1,
  price: number,
  paymentToken: string,
  expiresAt: number
) {
  const marketPlaceFactory = getContract(
    CN.Marketplace,
    Number(getConnectedProviderChainId())
  )
  const marketplace = await ContractFactory.build(Marketplace, marketPlaceFactory.address)

  const _addresses: any = addresses;

  if (!wallet) {
    throw new Error('Invalid address. Wallet must be connected.')
  }
  const from = Address.fromString(wallet.address)

  const createOrder = marketplace.methods.createOrder1155(
    Address.fromString(_addresses[Number(getConnectedProviderChainId())]?.nftItemsDiamond),
    itemId,
    numberOfItem,
    toWei(price.toString(), 'ether'),
    Address.fromString(paymentToken),
    expiresAt
  )
  return sendTransaction(createOrder, marketPlaceFactory, from)
}

export async function executeOrder1155(
  wallet: Wallet | null,
  itemId: string,
  seller: string,
  price: string,
  paymentToken: string
) {
  const marketPlaceFactory = getContract(
    CN.Marketplace,
    Number(getConnectedProviderChainId())
  )
  const marketplace = await ContractFactory.build(Marketplace, marketPlaceFactory.address)

  const _addresses: any = addresses;

  if (!wallet) {
    throw new Error('Invalid address. Wallet must be connected.')
  }
  const from = Address.fromString(wallet.address)

  const executeorder = marketplace.methods.executeOrder1155(
    Address.fromString(_addresses[Number(getConnectedProviderChainId())]?.nftItemsDiamond),
    itemId,
    Address.fromString(seller),
    price,
    Address.fromString(paymentToken),
  )

  return paymentToken === Address.ZERO.toString() ?
    sendTransaction(executeorder, marketPlaceFactory, from, BigNumber.from(price))
    : sendTransaction(executeorder, marketPlaceFactory, from);
}

export async function cancelOrder1155(
  wallet: Wallet | null,
  itemId: string
) {
  const marketPlaceFactory = getContract(
    CN.Marketplace,
    Number(getConnectedProviderChainId())
  )
  const marketplace = await ContractFactory.build(Marketplace, marketPlaceFactory.address)

  const _addresses: any = addresses;

  if (!wallet) {
    throw new Error('Invalid address. Wallet must be connected.')
  }
  const from = Address.fromString(wallet.address)

  const cancelorder = marketplace.methods.cancelOrder1155(
    Address.fromString(_addresses[Number(getConnectedProviderChainId())]?.nftItemsDiamond),
    itemId
  )
  return sendTransaction(cancelorder, marketPlaceFactory, from)
}

export async function fetchOrderedItem(baseType: string) {
  console.log({ baseType })
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  let url = `${REACT_APP_API_SERVER_URL}/orders/1155/open?`

  if (baseType) {
    url += `baseType[]=${baseType}`
  }

  try {
    const response: any = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    const resultItem = response.orders.map((res: any) => {
      return {
        itemTypeId: res.assetId,
        baseType: res.baseType,
        candiesPrice: BigNumber.from(0),
        image: res.itemType.metadata.image,
        isNFT: true,
        name: res.itemType.metadata.name,
        rate: BigNumber.from(1),
        usdPrice: BigNumber.from(1),
        isOwned: "string",
        paymentToken: Address.fromString(res.paymentToken),
        seller: Address.fromString(res.seller),
        amount: Number(res.amount),
        expires: res.expiresAt,
        priceInWei: res.priceInWei
      }
    })
    const result = makeListResponse(resultItem);
    return result;
  } catch (error) {
    console.log(error)
  }
}

export async function fetchOrderedItemGroup() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  try {
    const response: any = await fetch(
      `${REACT_APP_API_SERVER_URL}/orders/1155/groups`,
      requestOptions
    ).then(resp => resp.json())
    const resultGroup = response.data.order1155Groups.filter((res: any) => {
      res.name = res.itemType.metadata.name;
      res.image = res.itemType.metadata.image;
      return Number(res.quantity) > 0;
    })
    return resultGroup;
  } catch (error) {
    console.log(error)
  }
}

function makeListResponse(data: any) {
  let calcArr = data.reduce((acc: any, cur: any) => {
    let findEle = acc.find((value: any) => value.baseType == cur.baseType)
    let findIndex = acc.indexOf(findEle)
    return findIndex >= 0 ? [
      ...acc.slice(0, findIndex),
      {
        ...findEle,
        detail: findEle['detail'] !== undefined ? [...findEle['detail'], {
          seller: cur.seller,
          amount: cur.amount,
          priceInWei: cur.priceInWei,
          paymentToken: cur.paymentToken,
          expires: cur.expires,
          itemTypeId: cur.itemTypeId
        }] : [{
          seller: cur.seller,
          amount: cur.amount,
          priceInWei: cur.priceInWei,
          paymentToken: cur.paymentToken,
          expires: cur.expires,
          itemTypeId: cur.itemTypeId
        }]
      },
      ...acc.slice(findIndex + 1)
    ] : [...acc, {
      ...cur,
      detail: [{
        seller: cur.seller,
        amount: cur.amount,
        priceInWei: cur.priceInWei,
        paymentToken: cur.paymentToken,
        expires: cur.expires,
        itemTypeId: cur.itemTypeId
      }]
    }]
  }, [])
  let result = calcArr.map(({ seller, amount, priceInWei, paymentToken, itemTypeId, ...rest }: { seller: any, amount: any, priceInWei: any, paymentToken: any, itemTypeId: any }) => rest)
  return result;
}