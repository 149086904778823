import React, { Component } from 'react'
import LineItem from './LineItem'
import './Cart.css'
import { Button } from '@kmon/ui'

class Cart extends Component {
  constructor(props) {
    super(props)

    this.state = { total_quantity: 0, inSufficientObject: [] }
    this.openCheckout = this.openCheckout.bind(this)
  }

  openCheckout() {
    // window.open(this.props.checkout.webUrl)
    this.props.handleCheckout(this.props.checkout)
    // window.open(
    //   this.props.checkout.webUrl,
    //   '_blank',
    //   'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    // )
  }

  render() {
    let line_items = this.props.checkout.lineItems.map(line_item => {
      const { bnftTokenBalances, selectedBnftTokenBalances } = this.props
      const isInsufficient =
        bnftTokenBalances[line_item.variant.sku] ===
        selectedBnftTokenBalances[line_item.variant.sku]
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
          isInsufficient={isInsufficient}
        />
      )
    })

    return (
      <div className={`Cart ${this.props.isCartOpen ? 'Cart--open' : ''}`}>
        <header className="Cart__header">
          <h2>Your cart</h2>
          <button onClick={this.props.handleCartClose} className="Cart__close">
            ×
          </button>
        </header>
        {this.props.inSufficientArray.map(item => (
          <div className="Cart-info__total Cart-info__small">{`Cart is full with ${item}`}</div>
        ))}
        <ul className="Cart__line-items">{line_items}</ul>
        <footer className="Cart__footer">
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Subtotal</div>
            <div className="Cart-info__pricing">
              <span className="pricing">
                $ {this.props.checkout.subtotalPrice}
              </span>
            </div>
          </div>
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Taxes</div>
            <div className="Cart-info__pricing">
              <span className="pricing">$ {this.props.checkout.totalTax}</span>
            </div>
          </div>
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Total</div>
            <div className="Cart-info__pricing">
              <span className="pricing">
                $ {this.props.checkout.totalPrice}
              </span>
            </div>
          </div>
          <div className="Cart__checkout">
            <Button onClick={this.openCheckout} primary>
              {'Checkout'}
            </Button>
          </div>
          {/* <button className="Cart__checkout button" onClick={this.openCheckout}>
            Checkout
          </button> */}
        </footer>
      </div>
    )
  }
}

export default Cart
