import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'

import { RootState } from '../reducer'
import { locations } from '../routing/locations'

export const getState = (state: RootState) => state.settings
export const getData = (state: RootState) => getState(state).authToken
export const getGame = (state: RootState) => getState(state).gameData

export const getAuthToken = createSelector<RootState, any, any>(
  getData,
  authToken => authToken
)

export const getGameData = createSelector<RootState, any, any>(
  getGame,
  gameData => gameData
)
