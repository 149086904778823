import React, { useEffect, useCallback, useState } from 'react'
import { Card } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { locations } from '../../../modules/routing/locations'
import { fetchIPFS, getIPFSUrl } from '../../../modules/item/utils'
import { Props } from './ItemCard.types'
import { Image } from '../../Image'
import NFTLock from '../../../images/items/nft-lock-icon.svg'
import './ItemCard.css'

const LootboxCard = (props: Props) => {
  const {
    itemId,
    groupName,
    image,
  } = props
  const [metaData, setMetaData] = useState({} as any)

  let itemName = groupName.replace(/_/g, ' ')
  itemName = itemName.replace(/basic/gi, 'bronze')
  itemName = itemName.replace(/medium/gi, 'silver')
  itemName = itemName.replace(/premium/gi, 'gold')
  const fetchData = useCallback(async image => {
    const data = await fetchIPFS(image)
    setMetaData(data)
  }, [])

  return (
    <Card
      className="LootboxCard"
      link
      as={Link}
      to={locations._browseitem(itemId, '0')}
    >
      <div className="card-image-container">
        <div className="card-image">
          <Image src={image} />
        </div>
      </div>
      <div className="product-description-container">
        <div className="product-info">
          <div className="product-info-name-container">
            <p className="product-info-number-card">
              {t(`nft_item.title.${groupName}`)}
            </p>
          </div>
        </div>
        <div className="product-description">
          <div className="product-description-left">
            <p className="product-description-left-item">
              {t('item_page.item_type')}:
              {/token/i.test(groupName) ? ' Materials' : ' Power-up'}
            </p>
            <p className="product-description-left-item">
            </p>
          </div>
          <div className="product-description-right-nft">
            <img src={NFTLock} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default React.memo(LootboxCard)
