import { Address } from 'web3x-es/address'
import { BigNumber, ethers, providers } from 'ethers'
import { getContract, ContractName as CN } from '@kmon/transactions'
import { Wallet } from '@kmon/dapps/dist/modules/wallet/types'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'
import { ContractFactory } from '../contract/ContractFactory'
import { Item } from '../../contracts/Item'
import { sendTransaction } from '../wallet/utils'
import { ItemVersion } from './types'
import { BNFT } from './BNFTABI'
import Web3 from 'web3'
import { fetchJson } from 'ethers/lib/utils'
import { community } from './community'
import { addresses } from './addresses'

export const REACT_APP_API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL!
export async function fetchBNFTBalance(wallet: Wallet) {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId].BNFT,
    BNFT,
    provider.getSigner()
  )

  const balance = await contract.balanceOf(wallet.address)
  console.log(wallet)
  let ownedPartnerNFTs2 = []
  let tokenIdsArray = []
  let tokenIds = []
  const userBalance = Number(BigNumber.from(balance).toString())

  if (userBalance > 0) {
    for (let i = 0; i < userBalance; i++) {
      const _tokenId = await contract.tokenOfOwnerByIndex(wallet.address, i)
      const tokenId = Number(BigNumber.from(_tokenId).toString())
      tokenIdsArray.push(tokenId.toString())
      tokenIds.push(tokenId)
      console.log('TokenId-->', tokenId)
      // call the api
      // ownedPartnerNFTs2.push(tokenId)

      try {
        const response: any = await fetch(
          `${REACT_APP_API_SERVER_URL}/binance/bnft/${_addresses[chainId].BNFT}/${tokenId}`
        ).then(resp => resp.json())
        console.log('Register assets-->', response)
      } catch (error) {
        console.log(error)
      }

      const metadataUrl = await contract.tokenURI(tokenId)

      //fetch metadata
      let metadata = await fetchJson(`${REACT_APP_API_SERVER_URL}/binance/fetch/${encodeURIComponent(metadataUrl)}`)
      metadata.tokenId = tokenId
      ownedPartnerNFTs2.push(metadata)
      // console.log('Metadata--->', metadata)
    }

    const tokensBalance = getTokensBalance(ownedPartnerNFTs2)
    const tokensBalanceById = getTokensBalanceById(ownedPartnerNFTs2)
    console.log(
      'Metadata--->',
      ownedPartnerNFTs2,
      tokensBalance,
      tokensBalanceById
    )

    // Shopify products list
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tokenIds: tokenIdsArray,
        address: _addresses[chainId].BNFT
      })
    }

    try {
      const response: any = await fetch(
        `${REACT_APP_API_SERVER_URL}/shopify/products`,
        requestOptions
      ).then(resp => resp.json())
      console.log('Products-->', response)
      return [
        response?.statusCode ? [] : response,
        tokenIds,
        userBalance,
        tokensBalance,
        ownedPartnerNFTs2,
        tokensBalanceById
      ]
    } catch (error) {
      return [[], [], 0, [], [], null]
      console.log(error)
    }
  } else {
    return [[], [], 0, [], [], null]
  }
}

export async function fetchCommunityProducts(wallet: Wallet) {
  // Coummnity sale user products available
  try {
    const response: any = await fetch(
      `${REACT_APP_API_SERVER_URL}/shopify/products/${wallet?.address}`
    ).then(resp => resp.json())
    const tokensBalance = getCommunityTokensBalance(response)

    console.log('Community Products-->', response, tokensBalance)
    return [response?.statusCode ? [] : response, [], 0, tokensBalance, [], {}]
  } catch (error) {
    return [[], [], 0, [], [], {}]
    // console.log(error)
  }
}

export function getTokensBalance(tokenURIs: any) {
  const occurrences = tokenURIs.reduce(function (acc: any, curr: any) {
    return acc[curr.name] ? ++acc[curr.name] : (acc[curr.name] = 1), acc
  }, {})
  return occurrences
}

export function getTokensBalanceById(tokenURIs: any) {
  const count: any = {}

  for (let index = 0; index < tokenURIs.length; index++) {
    const element = tokenURIs[index]
    // let array: any = []

    if (count[element.name]) {
      count[element.name].push(element.tokenId)
    } else {
      // array = [element.tokenId]
      count[element.name] = [element.tokenId]
    }
  }
  return count
}

export function getCommunityTokensBalance(tokenURIs: any) {
  const count: any = {}

  for (let index = 0; index < tokenURIs.length; index++) {
    const element = tokenURIs[index]
    // let array: any = []

    if (count[element.variants[0].sku]) {
      count[element.variants[0].sku] += element.qty
    } else {
      // array = [element.tokenId]
      count[element.variants[0].sku] = element.qty
    }
  }
  return count
}

export async function fetchCommunityOrder(
  wallet: Wallet,
  orderId: any,
  email: string
) {
  console.log('Wallet', wallet)
  // Community sale order redemption
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      address: wallet?.address
    })
  }

  try {
    const response: any = await fetch(
      `${REACT_APP_API_SERVER_URL}/order/${orderId}/paid`,
      requestOptions
    ).then(resp => {
      console.log('Resp-->', resp)
      return resp.status
    })
    console.log('Redemption order check-->', response)
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function getOrderDetails(
  wallet: Wallet,
  orderId: any,
  email: string
) {
  console.log('Wallet', wallet)
  // Get order details from orderId
  const _requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      orderId
    })
  }

  try {
    const response: any = await fetch(
      `${REACT_APP_API_SERVER_URL}/order`,
      _requestOptions
    ).then(resp => resp.json())
    console.log('Order details-->', response)
    return response
  } catch (error) {
    console.log(error)
  }
}

export function getIfCommunityMember(wallet: Wallet) {
  return (
    community.filter(
      (item: any) => item.toLowerCase() === wallet?.address.toLowerCase()
    ).length > 0
  )
}

export async function bnftContract() {
  const connectedProvider = await getConnectedProvider()
  if (!connectedProvider) {
    throw new Error('Provider not connected')
  }

  const provider = await new providers.Web3Provider(connectedProvider)
  const chainId = Number(getConnectedProviderChainId())
  const _addresses: any = addresses

  const contract = await new ethers.Contract(
    _addresses[chainId].BNFT,
    BNFT,
    provider.getSigner()
  )

  return contract
}
