import React from 'react'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

import { Props } from './Header.types'
import './Header.css'

const Header = (props: Props) => {
  const { title, subTitle, wallet, link, content } = props

  if (!wallet) {
    return null
  }

  return (
    <div className="ui container Header">
      <div className="header-title">{title}</div>
      <div className="subtitle-container">
        <div className="subtitle-text">
          {subTitle}
          {link && (
            <a href={link?.link} target="_blank">
              {link.text}
            </a>
          )}
          {content}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Header)
