import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { fetchJson } from 'ethers/lib/utils';
import { BlockNumberType, BreedingFeeType } from './types';

const API_URL = process.env.REACT_APP_SUBGRAPH_URL as string
const API_STATUS_URL = process.env.REACT_APP_API_SERVER_URL as string

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})

class SubgraphAPI {
  getStatus = async (): Promise<BlockNumberType> => {
    const response: any = await fetchJson(
      `${API_STATUS_URL}/status/subgraph`
    )
    console.log('Subgraph status-->', response)
    return response;
  }

  getBredingFee = async () => {
    const query = gql`
      {
        breedingCentres {
            breedingFee
        }
      }
    `

    return client.query<BreedingFeeType>({
      query
    })
  }
}

export const subgraphAPI = new SubgraphAPI()
