import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Card } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { KIcon } from '@kmon/ui'
import { fetchJson } from 'ethers/lib/utils'
import { locations } from '../../../modules/routing/locations'
import { Props } from './BNFTCard.types'
import { Image } from '../../Image'
import BNFTImage from '../../../images/bnft-hunter-club.svg'
import './BNFTCard.css'
import ProductImagesSlider from '../ProductImageSlider'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/thumbs/thumbs.min.css'

const BNFTCard = (props: Props) => {
  const { item } = props

  const productImages = useMemo(() => {
    let imagesArray: any = []
    item?.images.map((item: any) => {
      imagesArray.push(item.src)
    })
    return imagesArray
  }, [item])

  return (
    <Card
      className="BNFTCard"
      link
      as={Link}
      to={locations.bnftdetail(item.id)}
    >
      <div className="card-image-container">
        <div className="card-image">
          <Image src={item?.image?.src} />
          {/* <ProductImagesSlider images={productImages} /> */}
        </div>
      </div>
      <div className="product-description-container">
        <div className="product-info">
          <div className="product-info-name-container">
            {/* <Row> */}
            {/* <p className="product-info-number-card">{item?.title}</p> */}
            {/* </Row> */}
          </div>
        </div>
        <div className="product-description">
          <div className="product-description-left">
            {/* <p className="product-description-left-item">{description}</p> */}
            <p className="product-info-number-card">{item?.title}</p>
          </div>
          <div className="product-description-right-nft">
            <img src={BNFTImage} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default React.memo(BNFTCard)
