import React, { useState } from 'react'
import { t, T } from '@kmon/dapps/dist/modules/translation/utils'
import { Form, Field, Button, Modal } from '@kmon/ui'

import { Props } from './ConfirmModal.types'
import { fromItemCount, toDecimal, toItemCount } from '../../../../lib/number'
import { toCoin, fromCoin } from '../../../../lib/kmon'
import './ConfirmModal.css'
import { CoinPopup } from '../../../CoinPopup'
import { Coin } from '@kmon/schemas'
import { CoinField } from '../../../CoinField'

const ConfirmModal = (props: Props) => {
  const {
    price,
    currentItem,
    currentPrice,
    paymentCoin,
    isBuyingItem,
    showConfirmModal,
    handleProceed,
    onCloseModal,
  } = props

  const [confirmPrice, setConfirmPrice] = useState('')

  return (
    <Modal size="small" open={showConfirmModal} className="ConfirmNumberOfItemsModal">
      <Modal.Header>{t('sell_page.confirm.title')}</Modal.Header>
      <Form onSubmit={() => handleProceed(currentPrice)}>
        <Modal.Content>
          <T
            id="sell_page.confirm.line_one"
            values={{
              name: <b>{currentItem?.name.replace(/_/g, ' ')}</b>,
              amount: <CoinPopup inline coin={paymentCoin}>{fromCoin(currentPrice, paymentCoin).toLocaleString()}</CoinPopup>
            }}
          />
          <br />
          <T id="sell_page.confirm.line_two" />
          <CoinField
            className="mana-input"
            label={t('sell_page.price')}
            coin={paymentCoin}
            placeholder={price}
            value={confirmPrice}
            onChange={(_event, props) => {
              if (paymentCoin === Coin.BNB) {
                setConfirmPrice(toDecimal(props.value))
              } else {
                const newPrice = fromCoin(props.value, paymentCoin)
                setConfirmPrice(toCoin(newPrice))
              }
            }}
          />
        </Modal.Content>
        <Modal.Actions className='ModalAction'>
          <Button
            type="submit"
            primary
            disabled={isBuyingItem || fromCoin(currentPrice, paymentCoin) !== fromCoin(confirmPrice, paymentCoin)}
            loading={isBuyingItem}
          >
            {t('global.proceed')}
          </Button>
          <div
            className="ui button"
            onClick={() => {
              onCloseModal()
            }}
          >
            {t('global.cancel')}
          </div>
        </Modal.Actions>
      </Form>
    </Modal>
  )
}

export default React.memo(ConfirmModal)
