import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@kmon/ui'
import { Grid, Image } from 'semantic-ui-react'
import ProgressBar from '@ramonak/react-progress-bar'
import { Loader } from '@kmon/ui'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'
import { Props } from './BluePrint.types'
import './BluePrint.css'
import { BigNumber } from 'ethers'
import PriceImg from '../../../../images/items/price.svg'
import UserImg from '../../../../images/items/user.svg'
import { Address } from 'web3x-es/address'
import { fromWei } from 'web3x-es/utils'
import { locations } from '../../../../modules/routing/locations'

export const REACT_APP_API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL!
const BluePrint = (props: Props) => {
  const {
    wallet,
    data,
    isLast,
    itemId,
    isOwned,
    onBuy,
    onNavigate
  } = props

  const pricePerUnit = (price: string, amount: number) => {
    return fromWei(BigNumber.from(price).div(amount).toString(), 'ether').toString() + ' KMON';
  }

  const isBnborKmon = (address: any) => {
    if (address.toString() == Address.ZERO.toString()) {
      return 'BNB';
    }
    return 'KMON';
  }

  const isBuy = data.seller.toString().toLowerCase() === wallet?.address.toString().toLowerCase() ? true : false;

  return (
    <div className={`blueprint_container ${!isLast ? 'blueprint-border-bottom' : ''}`}>
      <div className='blueprint-seller-price-container'>
        <div className='blueprint-seller-container'>
          <div className='blueprint-seller-title-container'>
            <img src={UserImg} />
            <div className='blueprint-seller-title'>Seller</div>
          </div>
          <div className='blueprint-seller-address'>{data.seller.toString()}</div>
          {isBuy ? <Button Sizes size="small" onClick={() => { onNavigate(locations.sellitem(data.itemTypeId, isOwned)) }}> {t('buy_page.update')} </Button> : <></>}
        </div>
        {(parseInt(data.amount) > 1) ?
          <div className='blueprint-seller-container'>
            <div className='blueprint-seller-title-container'>
              {/* <img src={PriceImg} /> */}
              <div className='blueprint-seller-title'>Amount</div>
            </div>
            <div className='blueprint-seller-address'>{data.amount.toString()}</div>
          </div>
          : <></>}
        <div className='blueprint-seller-container'>
          <div className='blueprint-seller-title-container'>
            <img src={PriceImg} />
            <div className='blueprint-seller-title'>Price</div>
          </div>
          <div className='blueprint-price'>{fromWei(data.priceInWei, 'ether')} {isBnborKmon(data.paymentToken)}</div>
          {isBuy ? <Button Sizes size="small" onClick={() => { onNavigate(locations.cancelitem(data.itemTypeId, isOwned)) }}> {t('buy_page.cancel')} </Button> : <></>}
        </div>
        {(parseInt(data.amount) > 1) ?
          <div className='blueprint-seller-container'>
            <div className='blueprint-seller-title-container'>
              <img src={PriceImg} />
              <div className='blueprint-seller-title'>Each</div>
            </div>
            <div className='blueprint-seller-address'>{pricePerUnit(data.priceInWei, data.amount)}</div>
          </div>
          : <></>}
      </div>
      {!isBuy ?
        <Button
          primary
          onClick={onBuy}
        >
          {t('buy_page.buy')}
        </Button>
        : <></>}

    </div>
  )
}

export default React.memo(BluePrint)
