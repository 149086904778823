export const addresses = {
  '4': {
    explorerURL: 'https://rinkeby.etherscan.io',
    KMONFT: '0xBc0741f3EC450406Bb8DCD24c3eEd8549678d868',
    KmonftDiamond: '0x5eA74A7105f9c2628AbE80D3Af22Afb1d7CE9A46',
    USDContract: '0xF1dF161b82e33D4FB27aa7445dE9F4c8Fe273d22',
    BNFT: '0x8bF6404c80863E8bDc176099594Bf949c44c26E4'
  },
  '97': {
    explorerURL: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    KMONFT: '0xBc0741f3EC450406Bb8DCD24c3eEd8549678d868',
    KmonftDiamond: '0x0d205d5be22a39a870e0ba00ebe24fc1beeed932',
    USDContract: '0xF1dF161b82e33D4FB27aa7445dE9F4c8Fe273d22',
    BNFT: '0x8bF6404c80863E8bDc176099594Bf949c44c26E4'
  },
  '56': {
    explorerURL: 'https://bscscan.com',
    KMONFT: '',
    KmonftDiamond: '0x04b0f7d5cb2ce4688497f2525748fb7a9affa394',
    USDContract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    BNFT: '0xe2d64e38f9b2e954022167b48f292353bD0aCF46'
  },
  '80001': {
    explorerURL: 'https://bscscan.com',
    KMONFT: '',
    KmonftDiamond: '0x4604c1eC8104c2aad562e2DD5Bf9D30eE2547F00',
    nftItemsDiamond: '0x1213b63d18286dE7F2A805433827F27130D61404',
    USDContract: '0xA02f6adc7926efeBBd59Fd43A84f4E0c0c91e832',
    BNFT: ''
  }
}
