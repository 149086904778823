import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { locations } from '../../modules/routing/locations'
import { NFTImage } from '../NFTImage'
import { Row } from '../Layout/Row'
import { Column } from '../Layout/Column'
import { Props } from './ItemAction.types'
import { fetchIPFS } from '../../modules/item/utils'
import './ItemAction.css'

const ItemAction = (props: Props) => {
  const { itemId, isOwned, image, children } = props
  const [metaData, setMedata] = useState({} as any)

  return (
    <div className="ItemAction">
      <Link to={locations.browseItem()}>
        <div className="back" />
      </Link>
      <Row>
        <Column align="left">
          <div className="item-image-wrapper">
            <img src={image} />
            {/* <NFTImage nft={nft} zoom={1} /> */}
          </div>
        </Column>
        <Column align="right">{children}</Column>
      </Row>
    </div>
  )
}

export default React.memo(ItemAction)
