import React from 'react'
import { Button } from '@kmon/ui'

import { Props } from './Actions.types'
import { ItemVersion } from '../../../modules/item/types'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

const Actions = (props: Props) => {
  const { onBuy } = props

  const classes = ['lootbox-action-button']
  // isBid ? classes.push('bid-action') : classes.push('buy-action')

  return (
    <div className={classes.join(' ')}>
      <Button onClick={() => onBuy()} primary>
        {'Add to Cart'}
      </Button>
    </div>
  )
}

export default React.memo(Actions)
