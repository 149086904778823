import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { RouteComponentProps } from 'react-router'
import { isLoadingType } from '@kmon/dapps/dist/modules/loading/selectors'
import { RootState } from '../../modules/reducer'
import { getLoading } from '../../modules/item/selectors'
import {
  cancelOrderRequest,
  CANCEL_ORDER_REQUEST,
} from '../../modules/item/actions'
import {
  Params,
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './CancelItemPage.types'
import CancelItemPage from './CancelItemPage'

const mapState = (
  state: RootState,
  ownProps: RouteComponentProps<Params>
): MapStateProps => {
  const { id, isOwned } = ownProps.match.params
  const { orderedItems } = state.item
  return {
    isLoading: isLoadingType(getLoading(state), CANCEL_ORDER_REQUEST),
    itemId: id,
    isOwned: isOwned,
    orderedItem: orderedItems
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onNavigate: path => dispatch(push(path)),
  onCancelOrder: (itemId, name) => dispatch(cancelOrderRequest(itemId, name))
})

export default connect(mapState, mapDispatch)(CancelItemPage)
