import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { push, goBack } from 'connected-react-router'
import { Button, Container, Field, Form, Modal, Page, Header } from '@kmon/ui'
import { ethers, providers } from 'ethers'
import { Loader } from 'semantic-ui-react'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import { fromWei } from 'web3x-es/utils'
import dateFnsFormat from 'date-fns/format'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'
import { Address } from 'web3x-es/address'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { ContractName } from '@kmon/transactions'

import { locations } from '../../../modules/routing/locations'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { AuthorizationModal } from '../../AuthorizationModal'
import { Props } from './SellPage.types'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { Navbar } from '../../Navbar'
import { Navigation } from '../../Navigation'
import { Footer } from '../../Footer'
import { LootboxDetailCard } from '../LootboxDetailCard'
import './SellPage.css'
import { fetchIPFS } from '../../../modules/item/utils'
import { ConfirmModal } from './ConfirmModal'
import { BigNumber } from 'ethers'
import { KmonftDiamond } from '../../../modules/item/diamond'
import { addresses } from '../../../modules/item/addresses'
import { CoinSelectField } from '../../CoinSelectField'
import { Coin } from '@kmon/schemas'
import { CoinField } from '../../CoinField'
import { toCoin, fromCoin } from '../../../lib/kmon'
import { toDecimal } from '../../../lib/number'
import {
  INPUT_FORMAT,
  getDefaultExpirationDate
} from '../../../modules/order/utils'
import { NavigationTab } from '../../Navigation/Navigation.types'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'
import { SellModal } from './SellModal'
import { isLoading } from '@kmon/dapps/dist/modules/loading/selectors'

const SellPage = (props: Props) => {
  const dispatch = useDispatch()
  const {
    wallet,
    isConnecting,
    isBuyingItem,
    isTransferringItem,
    itemId,
    isOwned,
    currentItem,
    orderedItem,
    itemBalance,
    onFetchAllItems,
    onFetchMyItems,
    onSell,
    onFetchItemBalance,
    onNavigate,
    authorizations,
  } = props

  let isUpdate = false
  let filteredItem: any = [];

  orderedItem.forEach((ele: any) => {
    ele.detail.forEach((subEle: any) => {
      if (subEle.itemTypeId == itemId && subEle.seller.toString().toLowerCase() == wallet?.address.toString().toLocaleLowerCase()) {
        isUpdate = true; filteredItem = [subEle];
      }
    })
  });

  const [metaData, setMedata] = useState({} as any)

  useEffect(() => {
    onFetchAllItems()
    wallet && onFetchMyItems()
  }, [wallet])

  useEffect(() => {
    onFetchItemBalance(itemId)
    if (itemId !== undefined) {
      isOwned === '0' ? onFetchMyItems() : onFetchAllItems()
    }
  }, [itemId])

  useEffect(() => {
    fetchData(currentItem?.image).catch(console.error)
  }, [currentItem])

  const fetchData = useCallback(async image => {
    const data = await fetchIPFS(image)

    setMedata(data)
  }, [])

  const Loading = () => (
    <div className="nft-center">
      <Loader active size="huge" />
    </div>
  )

  const NotFound = () => (
    <div className="nft-center">
      <p className="secondary-text">{t('global.not_found')}&hellip;</p>
    </div>
  )

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation isFullscreen activeTab={NavigationTab.MY_ASSETS} />
      </div>
      <Page className="NFTPage" isFullscreen>
        <SellModal isOwned={isOwned} metaData={metaData} isUpdate={isUpdate} wallet={wallet} authorizations={authorizations} filteredItem={filteredItem[0]} itemId={itemId} currentItem={currentItem} itemBalance={itemBalance} isBuyingItem={isBuyingItem} isTransferringItem={isTransferringItem} onNavigate={onNavigate} onSell={onSell} />
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(SellPage)
