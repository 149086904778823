import React, { useEffect, useState, useCallback } from 'react'
import { ethers, providers, BigNumber } from 'ethers'
import CopyToClipboard from 'react-copy-to-clipboard'
import Web3 from 'web3'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { isMobile } from '@kmon/dapps/dist/lib/utils'
import { AuthorizationType } from '@kmon/dapps/dist/modules/authorization/types'
import { Page, Grid, Blockie, Loader, Form, Field, Button } from '@kmon/ui'
import { ContractName } from '@kmon/transactions'
import { getConnectedProvider } from '@kmon/dapps/dist/lib/eth'

import { locations } from '../../modules/routing/locations'
import { shortenAddress } from '../../modules/wallet/utils'
import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import { Navigation } from '../Navigation'
import { Authorization } from './Authorization'
import { getContractNames } from '../../modules/vendor'
import { getContract } from '../../modules/contract/utils'
import { useTimer } from '../../lib/timer'
import { Props } from './SettingsPage.types'
import './SettingsPage.css'

const SettingsPage = (props: Props) => {
  const {
    wallet,
    authorizations,
    isLoadingAuthorization,
    isConnecting,
    hasError,
    authToken,
    gameData,
    onNavigate,
    onFetchGameAccount,
    onFetchGameAuthToken
  } = props

  const [hasCopiedText, setHasCopiedAddress] = useTimer(1200)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [_password, _setPassword] = useState('')

  const [signature, setSignature] = useState('')
  const [checkSumAddress, setCheckSumAddress] = useState('')
  const [visibleForm, setVisibleForm] = useState(false)

  const USER_NAME: any = localStorage.getItem('user-name')
  const USER_EMAIL: any = localStorage.getItem('user-email')
  const GAME_DATA: any = localStorage.getItem('game_data')

  useEffect(() => {
    if (!isConnecting && !wallet) {
      onNavigate(locations.signIn())
    }
  }, [isConnecting, wallet, onNavigate])

  useEffect(() => {
    if (authToken) {
      setVisibleForm(true)
    }
  }, [authToken])

  useEffect(() => {
    if (signature?.length > 0 && checkSumAddress?.length > 0) {
      onFetchGameAuthToken({
        id: checkSumAddress,
        signature: signature.toString()
      })
    }
  }, [signature, checkSumAddress])

  const onConnect = useCallback(async () => {
    const _address: any = wallet?.address
    const connectedProvider = await getConnectedProvider()
    var web3 = new Web3(
      new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
    )

    const _checkSumAddress = await web3.utils.toChecksumAddress(_address)
    setCheckSumAddress(_checkSumAddress)

    var msg = await web3.utils.utf8ToHex(
      `Connect my wallet to the game account ${_checkSumAddress}`
    )

    const _connectedProvider: any = connectedProvider
    const from = wallet?.address
    const params = [msg, from]
    const method = 'personal_sign'

    let _signature = ''
    _connectedProvider.sendAsync(
      {
        method,
        params,
        from
      },
      function (err: any, result: any) {
        if (err) {
          console.error(err)
        } else {
          console.log('PERSONAL SIGNED:' + JSON.stringify(result.result))
          _signature = result.result
          setSignature(_signature)
        }
      }
    )
  }, [])

  const onCreate = useCallback(async () => {
    const _authToken: any = authToken
    onFetchGameAccount({
      username,
      email,
      password,
      _password,
      authToken: _authToken
    })
  }, [username, email, password, _password])

  const contractNames = getContractNames()

  const marketplace = getContract({
    name: contractNames.MARKETPLACE
  })

  const erc721Bid = getContract({
    name: contractNames.ERC721Bid
  })

  const kmon = getContract({
    name: contractNames.KMONToken
  })

  const wbnb = getContract({
    name: contractNames.WBNB
  })

  const item = getContract({
    name: contractNames.Item
  })

  // const kmonft = getContract({
  //   name: contractNames.KMONFT
  // })

  const kmonftV2 = getContract({
    name: contractNames.KMONFTV2
  })

  const authorizationsForSelling = authorizations.filter(authorization => {
    const contract = getContract({ address: authorization.contractAddress })
    return contract.category != null
  })

  const gameAccount = () => {
    const _gameData = GAME_DATA && JSON.parse(GAME_DATA)
    const _username = USER_NAME
    const _useremail = USER_EMAIL

    const connect = (
      <Grid.Column computer={12} mobile={16}>
        <Button primary onClick={onConnect}>
          {t('settings_page.connect')}
        </Button>
      </Grid.Column>
    )

    const create = (
      <Grid.Column computer={12} mobile={16}>
        <Field
          label={t('settings_page.username')}
          size="small"
          value={username}
          onChange={(_event, props) => setUsername(props.value)}
        />
        <Field
          label={t('settings_page.email')}
          value={email}
          onChange={(_event, props) => setEmail(props.value)}
        />
        <Field
          label={t('settings_page.password')}
          value={password}
          type="password"
          onChange={(_event, props) => setPassword(props.value)}
        />
        <Field
          label={t('settings_page.confirm_password')}
          value={_password}
          type="password"
          onChange={(_event, props) => _setPassword(props.value)}
        />
        <Button primary onClick={onCreate}>
          {t('settings_page.create')}
        </Button>
      </Grid.Column>
    )

    const userAccount = (
      <Grid.Column computer={12} mobile={16}>
        <div className="username-container">
          <div className="username-title">{t('settings_page.username')}</div>
          <div className="username-content">
            {gameData?.Username || _gameData?.Username || _username || ''}
          </div>
        </div>
        <div className="username-container">
          <div className="username-title">{t('settings_page.email')}</div>
          <div className="username-content">
            {gameData?.Email || _useremail || ''}
          </div>
        </div>
      </Grid.Column>
    )

    let component = null
    if (_username || _useremail) {
      component = userAccount
    } else if (_gameData) {
      component = userAccount
    } else if (visibleForm) {
      component = create
    } else if (!visibleForm) {
      component = connect
    } else {
      component = connect
    }
    // return (_username || _useremail) ? userAccount
    return component
  }

  return (
    <div className="SettingsPageMain">
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation />
      </div>
      <Page className="SettingsPage">
        {isConnecting ? (
          <Loader size="massive" active />
        ) : wallet ? (
          <Grid>
            <Grid.Row>
              <Grid.Column
                className="left-column secondary-text"
                computer={4}
                mobile={16}
              >
                {t('global.address')}
              </Grid.Column>
              <Grid.Column computer={12} mobile={16}>
                <div className="blockie-container">
                  <Blockie seed={wallet.address} scale={12} />
                </div>
                <div className="address-container">
                  <div className="address">
                    {isMobile()
                      ? shortenAddress(wallet.address)
                      : wallet.address}
                  </div>
                  <CopyToClipboard
                    text={wallet.address}
                    onCopy={setHasCopiedAddress}
                  >
                    {hasCopiedText ? (
                      <span className="copy-text">
                        {t('settings_page.copied')}
                      </span>
                    ) : (
                      <span className="copy-text link">
                        {t('settings_page.copy_address')}
                      </span>
                    )}
                  </CopyToClipboard>
                </div>
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
              <Grid.Column
                className="left-column secondary-text"
                computer={4}
                mobile={16}
              >
                {t('global.game_account')}
              </Grid.Column>
              {gameAccount()}
            </Grid.Row> */}
            <Grid.Row>
              <Grid.Column
                className="left-column secondary-text"
                computer={4}
                mobile={16}
              >
                {t('settings_page.authorizations')}
              </Grid.Column>
              <Grid.Column computer={12} mobile={16}>
                {isLoadingAuthorization ? (
                  <Loader size="massive" active />
                ) : (
                  <div className="authorization-checks-container">
                    {hasError ? (
                      <div className="authorization-checks">
                        <p className="danger-text">
                          {t('settings_page.authorization_error')}
                          <br />
                          {t('settings_page.authorization_error_contact')}
                        </p>
                      </div>
                    ) : (
                      <Form>
                        <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_buying_nft')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: marketplace.address,
                              contractAddress: kmon.address,
                              contractName: ContractName.KMONToken,
                              chainId: kmon.chainId,
                              type: AuthorizationType.ALLOWANCE
                            }}
                          />
                        </div>

                        {/* <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_selling_or_approving')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: marketplace.address,
                              contractAddress: kmonft.address,
                              contractName: ContractName.KMONFT,
                              chainId: kmonft.chainId,
                              type: AuthorizationType.APPROVAL
                            }}
                          />
                        </div> */}

                        <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_selling_or_approving')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: marketplace.address,
                              contractAddress: kmonftV2.address,
                              contractName: ContractName.KMONFTV2,
                              chainId: kmonftV2.chainId,
                              type: AuthorizationType.APPROVAL
                            }}
                          />
                        </div>

                        <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_bidding_with_kmon')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: erc721Bid.address,
                              contractAddress: kmon.address,
                              contractName: ContractName.KMONToken,
                              chainId: kmon.chainId,
                              type: AuthorizationType.ALLOWANCE
                            }}
                          />
                        </div>

                        <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_bidding_with_wbnb')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: erc721Bid.address,
                              contractAddress: wbnb.address,
                              contractName: ContractName.WBNB,
                              chainId: wbnb.chainId,
                              type: AuthorizationType.ALLOWANCE
                            }}
                          />
                        </div>

                        <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_buying_lootbox')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: item.address,
                              contractAddress: kmon.address,
                              contractName: ContractName.KMONToken,
                              chainId: kmon.chainId,
                              type: AuthorizationType.ALLOWANCE
                            }}
                          />
                        </div>

                        <div className="authorization-checks">
                          <label className="secondary-text">
                            {t('settings_page.for_breeding')}
                          </label>
                          <Authorization
                            authorization={{
                              address: wallet.address,
                              authorizedAddress: kmonftV2.address,
                              contractAddress: kmon.address,
                              contractName: ContractName.KMONToken,
                              chainId: kmon.chainId,
                              type: AuthorizationType.ALLOWANCE
                            }}
                          />
                        </div>

                        {authorizationsForSelling.length > 0 ? (
                          <div className="authorization-checks">
                            <label className="secondary-text">
                              {t('settings_page.for_selling')}
                            </label>

                            {authorizationsForSelling.map(authorization => {
                              return (
                                <Authorization
                                  key={
                                    authorization.authorizedAddress +
                                    authorization.contractAddress
                                  }
                                  authorization={authorization}
                                />
                              )
                            })}
                          </div>
                        ) : null}
                      </Form>
                    )}
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </Page>
      <Footer />
    </div>
  )
}

export default React.memo(SettingsPage)
