import React, { useRef, useState } from 'react'
import Web3 from 'web3'
import { getConnectedProvider } from '@kmon/dapps/dist/lib/eth'
import { NFTImage } from '../NFTImage'
import { Props } from './NFTDetailCard.types'
import './NFTDetailCard.css'
import { Row } from '../Layout/Row'
import { Radio } from '@kmon/ui'
import { t, getCurrentLocale } from '@kmon/dapps/dist/modules/translation/utils'
import { isOwnedBy, renameNFT } from '../../modules/nft/utils'

const NFTDetailCard = (props: Props) => {
  const { wallet, nft, elementType, isV2, toogleV2, canBreed } = props
  const [isEdit, setIsEdit] = useState(false)
  const [name, setName] = useState(nft.metadata?.name)
  const nameRef = useRef<HTMLInputElement>(null)
  const laidTimestamp = nft.data.kryptomon!.timeBorn * 1000
  var options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  const lastEvolvedTime =
    nft.data.kryptomon?.lastEvolved != null
      ? nft.data.kryptomon?.lastEvolved
      : nft.data.kryptomon?.timeHatched
  const laid = new Date(laidTimestamp).toLocaleDateString(
    getCurrentLocale().locale,
    options
  )
  const isV2New =
    nft.contractAddress.toUpperCase() ==
    '0x04b0f7d5cb2ce4688497f2525748fb7a9affa394'.toUpperCase() ||
    nft.contractAddress.toUpperCase() ==
    '0x0D205D5BE22a39a870e0BA00ebE24Fc1beEED932'.toUpperCase()
  const lastEvolved =
    nft.data.kryptomon?.status == '0'
      ? laid
      : new Date(lastEvolvedTime! * 1000).toLocaleDateString(
        getCurrentLocale().locale,
        options
      )
  const lastEvolvedTitle =
    nft.data.kryptomon?.status == '0'
      ? t('menu.keys.laid')
      : nft.data.kryptomon?.status == '1'
        ? t('menu.keys.hatched')
        : parseInt(nft.data.kryptomon!.status) > 1
          ? t('menu.keys.last_evolved')
          : undefined

  const isOwner = isOwnedBy(nft, wallet)
  const handleEditName = () => {
    nameRef.current?.focus();
    setIsEdit(true);
  }

  const onChangeName = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  }

  const onKeyPressName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      saveName();
    }
  }

  const saveName = async () => {
    setIsEdit(false);
    if (name === nft.metadata?.name) return
    const _address: any = wallet?.address
    const connectedProvider = await getConnectedProvider()
    var web3 = new Web3(
      new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
    )

    var msg = await web3.utils.utf8ToHex(
      `Name my Kryptomon(${nft.tokenId}) ${name}`
    )
    const from = wallet?.address
    const params = [msg, from]
    const method = 'personal_sign'

    const _connectedProvider: any = connectedProvider
    let _signature = ''
    _connectedProvider.sendAsync(
      {
        method,
        params,
        from
      },
      function (err: any, result: any) {
        if (err) {
          console.error(err)
        } else {
          console.log('PERSONAL SIGNED:' + JSON.stringify(result.result))
          _signature = result.result

          renameNFT(nft.tokenId, name, _signature).then(response => {
            setName(response.name);
          })
        }
      }
    )
  }

  return (
    <div className="card">
      <div className="card-image-container">
        <div className="card-image">
          <NFTImage nft={nft} showMonospace />
        </div>
      </div>
      <div className="product-description">
        <div className="product-description-left">
          <Row>
            <input
              type="text"
              className="product-info-number"
              ref={nameRef}
              value={name}
              onChange={onChangeName}
              onKeyPress={onKeyPressName}
              onBlur={saveName}
              disabled={!isEdit}
            />
            {isOwner && (
              <div className="product-edit" onClick={handleEditName} />
            )}
            <div className="product-verified" />
            {canBreed ? (
              <i className="product-description-mid-heart"></i>
            ) : null}
          </Row>
          <p className="product-description-left-item">
            {t('menu.keys.number')}: {nft.tokenId}
          </p>
          <p className="product-description-left-item">
            {lastEvolvedTitle}: {lastEvolved}
          </p>
        </div>
        <div className="product-description-right">
          <span className="product-type-price">
            Gen: {nft.data.kryptomon?.genes.generation}
          </span>
          <span>ERC-721</span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NFTDetailCard)
