import React, { useCallback, useEffect, useState } from 'react'
import { t, getCurrentLocale } from '@kmon/dapps/dist/modules/translation/utils'
import { isMobile } from '@kmon/dapps/dist/lib/utils'
import { Page, Hero, Button, Grid } from '@kmon/ui'
import { locations } from '../../modules/routing/locations'
import { View } from '../../modules/ui/types'
import { HomepageView } from '../../modules/ui/nft/homepage/types'
import { Section } from '../../modules/vendor/decentraland/routing/types'
import { Navbar } from '../Navbar'
import { Navigation } from '../Navigation'
import { Footer } from '../Footer'
import { Slideshow } from './Slideshow'
import Community from './Components/Community'
import { Props } from './HomePage.types'
import './HomePage.css'
import { SearchOptions } from '../../modules/routing/types'
import { OrderStatus } from '../../modules/order/types'
import { FooterImage } from '../FooterImage'
import { KryptomonMetadataResponse } from '../../modules/vendor/decentraland'
import NFTCard from '../NFTCard/NFTCard'

const HomePage = (props: Props) => {
  const { homepage, homepageLoading, onNavigate, onFetchNFTsFromRoute } = props

  const strongFighterUrl = 'first=10&skip=0&sortBy=newest&category=kryptomon&section=all&isMap=false&isFullscreen=false&vendor=kryptomon&page=1&sortBy=recently_listed&onlyOnSale=true&constitution=40_100&instinct=40_100&healthPoints=50_100&attack=55_100&defence=55_100';
  const cheapStarterUrl = 'first=10&skip=0&sortBy=newest&category=kryptomon&section=all&isMap=false&isFullscreen=false&vendor=kryptomon&page=1&sortBy=cheapest&onlyOnSale=true&priceToken=kmon';

  const [strongfighter, setStrongfighter] = useState([]);
  const [cheapstarter, setCheapstarter] = useState([]);

  var options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit'
  }

  const sections = {
    [View.KRYPTOMONS]: Section.KRYPTOMONS,
    // [View.LATEST_SOLD]: Section.LATEST_SOLD,
    // [View.ALL_ASSETS]: Section.ALL
  }

  const flashTime = new Date('April 16, 2022 15:00:00')

  const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + (minutes > 0 && ':') + (minutes > 0 && minutes) + '' + ampm;
    return strTime;

  }

  const formatDate = (date: any) => {
    const newDate = date.toLocaleDateString(getCurrentLocale().locale, options)
    const enDate = `${date.getDay()} ${date.toLocaleString('default', { month: 'short' })} ${formatAMPM(flashTime)}`
    const value = getCurrentLocale().locale === 'zh-CN' ? newDate : enDate
    return t('home_page.starting_date',
      { date: value })
  }

  const handleGetStarted = useCallback(() => onNavigate(locations.browse()), [
    onNavigate
  ])

  const handleViewAll = useCallback(
    (section: Section) => {
      if (section === Section.ALL) {
        return onNavigate(locations.browse({ section, onlyOnSale: false }))
      }
      return onNavigate(locations.browse({ section }))
    },
    [onNavigate]
  )


  const strongFightersView = useCallback(
    () => {
      return onNavigate(locations.strongFighters())
    },
    [onNavigate]
  )

  const cheapStarterView = useCallback(
    () => {
      return onNavigate(locations.cheapStarter())
    },
    [onNavigate]
  )

  useEffect(() => {
    let view: HomepageView
    for (view in homepage) {
      const section = sections[view]
      const fetchObj: SearchOptions = {
        section,
        view,
        page: 1,
        onlyOnSale: false
      }
      if (view === 'kryptomons') {
        fetchObj.onlyOnSale = true
      }
      // if (view === 'latest_sold') {
      //   fetchObj.orderStatus = OrderStatus.SOLD
      // }
      onFetchNFTsFromRoute(fetchObj)
    }
    // eslint-disable-next-line
  }, [onFetchNFTsFromRoute])

  const NFT_SERVER_URL = process.env.REACT_APP_NFT_SERVER_URL!

  useEffect(() => {
    const fetchData = async () => {

      const strongFighterNfts: any = []
      const cheapStarterNfts: any = []

      try {
        const nftList1 = await fetch(
          `${NFT_SERVER_URL}/v1/nfts?${strongFighterUrl}`
        ).then(resp => resp.json())

        const nftList2 = await fetch(
          `${NFT_SERVER_URL}/v1/nfts?${cheapStarterUrl}`
        ).then(resp => resp.json())

        for (const result of nftList1.data) {
          // setting metadata
          const metadata: KryptomonMetadataResponse = await fetch(
            result.nft.tokenURI
          ).then(resp => resp.json())
          result.nft.metadata = metadata
          strongFighterNfts.push(result)
        }
        setStrongfighter(strongFighterNfts);

        for (const result of nftList2.data) {
          // setting metadata
          const metadata: KryptomonMetadataResponse = await fetch(
            result.nft.tokenURI
          ).then(resp => resp.json())
          result.nft.metadata = metadata
          cheapStarterNfts.push(result)
        }
        setCheapstarter(cheapStarterNfts);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
  }, [])

  const views = Object.keys(homepage) as HomepageView[]

  return (
    <div className="HomePage">
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation />
      </div>
      <Hero centered className="HomePageHero">
        <div className="hero-title-text">{t('home_page.title')}</div>
        <Hero.Content>
          <div className="hero-image" />
          <div className="hero-fade" />
          <a className="hero-logo" href="https://kryptomon.co" />
          <div className="hero-market" />
          <div className="grid-top" />
          <div className="dragons" />
        </Hero.Content>
        <Hero.Actions>
          <Button primary onClick={handleGetStarted} className="button-secondary">
            {t('home_page.get_started')}
          </Button>
        </Hero.Actions>
      </Hero>
      <div className="HomePageContent">
        {/* <div className="flash-container">
          <div className="play-game">
            <div className="become-trainer">{t('home_page.become_trainer')}</div>
            <Button primary onClick={() => window.open('https://app.kryptomon.co/play')} className="button-play">
              {t('home_page.play')}
            </Button>
          </div>
          <div className="flash" onClick={() => window.open('https://www.kryptomon.co/treasure-hunt')}>
            <div className="starting-date">{formatDate(flashTime)}</div>
          </div>
        </div> */}
        <Navigation />
        <Page className="HomePage">
          <div className='HomePage-carousels-header'>
            <div className='HomePage-carousels-header-main'>
              <div className='HomePage-carousels-header-text'>
                <div>{t(`home_page.strongFighters`)}</div>
                <p onClick={() => strongFightersView()}>{t(`slideshow.view_all`)}{` >`}</p>
              </div>
              <div className='homepage-horizontal-nft-scroll'>
                {strongfighter.length > 0
                  ? strongfighter.map((item: any, index) => (
                    <NFTCard
                      key={item.nft.id + '-' + index}
                      nft={item.nft}
                      status={{ showPrice: true }}
                      isRelated={true}
                    />
                  )) : null
                }
              </div>
            </div>
            <div className='HomePage-carousels-header-main'>
              <div className='HomePage-carousels-header-text'>
                <div>{t(`home_page.cheapStarterKryptomons`)}</div>
                <p onClick={() => cheapStarterView()}>{t(`slideshow.view_all`)}{` >`}</p>
              </div>
              <div className='homepage-horizontal-nft-scroll'>
                {cheapstarter.length > 0
                  ? cheapstarter.map((item: any, index) => (
                    <NFTCard
                      key={item.nft.id + '-' + index}
                      nft={item.nft}
                      status={{ showPrice: true }}
                      isRelated={true}
                    />
                  )) : null
                }
              </div>
            </div>
          </div>
          {views.map((view, index) => {
            return (
              <>
                <Slideshow
                  key={`${view}-${index}`}
                  title={t(`home_page.${view}`)}
                  nfts={homepage[view]}
                  isLoading={homepageLoading[view]}
                  onViewAll={() => handleViewAll(sections[view])}
                />
              </>
            )
          })}
        </Page>
        <div className="flash-container">
          <Community />
        </div>
      </div>
      <div className='homepagedata'>
        <FooterImage />
        <Footer />
      </div>
    </div>
  )
}


export default React.memo(HomePage)
