import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { RouteComponentProps } from 'react-router'
import { Address } from 'web3x-es/address'
import { push } from 'connected-react-router'
import {
  getData as getAuthorizations,
  getLoading as getLoadingAuthorizations
} from '@kmon/dapps/dist/modules/authorization/selectors'
import { isLoadingType } from '@kmon/dapps/dist/modules/loading/selectors'
import { FETCH_AUTHORIZATIONS_REQUEST } from '@kmon/dapps/dist/modules/authorization/actions'

import { RootState } from '../../../modules/reducer'
import { getWallet, isConnecting } from '../../../modules/wallet/selectors'
import {
  Params,
  MapStateProps,
  MapDispatch,
  MapDispatchProps
} from './OrderItemDetail.types'
import OrderItemDetail from './OrderItemDetail'
import {
  buyItemRequest,
  buyNFTItemRequest,
  fetchItemRequest,
  fetchMyItemsRequest,
  fetchOrderedItemsRequest,
  fetchItemTypesRequest,
  fetchAllItemsRequest,
  buyItemWithCandiesRequest,
  craftNFTItemRequest,
  fetchMaterialItemTypesRequest,
  fetchItemBalanceRequest,
  executeOrderRequest,
  CRAFT_NFT_ITEM_REQUEST,
  BUY_NFT_ITEM_REQUEST,
  BUY_ITEM_REQUEST
} from '../../../modules/item/actions'
import {
  getOrderedCurrentItem,
  _getCurrentItem,
  _getOrderedCurrentItem,
  getMyData,
  getUpgradeDefinitions,
  getItemTypes,
  getLoading as getLoadingItem,
  getMaterialItemTypes,
  getItemBalance,
  getCraftingStarted,
  getCraftingFinished
} from '../../../modules/item/selectors'
import { Item, AllItem } from '../../../modules/item/types'
import { ItemVersion } from '../../../modules/item/types'

const mapState = (
  state: RootState,
  ownProps: RouteComponentProps<Params>
): MapStateProps => {
  const { address, id, isOwned } = ownProps.match.params

  return {
    address: address?.toLowerCase(),
    wallet: getWallet(state),
    authorizations: getAuthorizations(state),
    isConnecting: isConnecting(state),
    isLoading: isLoadingType(
      getLoadingAuthorizations(state),
      FETCH_AUTHORIZATIONS_REQUEST
    ),
    upgradeDefinitions: getUpgradeDefinitions(state),
    itemTypes: getItemTypes(state),
    materialItemTypes: getMaterialItemTypes(state),
    isBuyingItem: isLoadingType(getLoadingItem(state), BUY_NFT_ITEM_REQUEST),
    isCraftingItem: isLoadingType(
      getLoadingItem(state),
      CRAFT_NFT_ITEM_REQUEST
    ),
    itemId: id,
    isOwned,
    currentItem:
      isOwned === '0' ? getOrderedCurrentItem(state) : _getOrderedCurrentItem(state),
    myItems: getMyData(state),
    itemBalance: getItemBalance(state),
    craftingStarted: getCraftingStarted(state),
    craftingFinished: getCraftingFinished(state)
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onRedirect: path => dispatch(replace(path)),
  onFetchItem: () => dispatch(fetchItemRequest()),
  onFetchMyItems: () => dispatch(fetchMyItemsRequest()),
  onFetchOrderedItems: (params) => dispatch(fetchOrderedItemsRequest(params)),
  onFetchItemTypes: ids => dispatch(fetchItemTypesRequest(ids)),
  onFetchMaterialItemTypes: ids => dispatch(fetchMaterialItemTypesRequest(ids)),
  onFetchAllItems: () => dispatch(fetchAllItemsRequest()),
  onFetchItemBalance: id => dispatch(fetchItemBalanceRequest(id)),
  onCraftItem: param => dispatch(craftNFTItemRequest(param)),
  onBuyItem: (
    version: ItemVersion,
    item: AllItem,
    count: number,
    to: Address
  ) => dispatch(buyNFTItemRequest(version, item, count, to)),
  onBuyItemWithCandies: (
    version: ItemVersion,
    item: Item,
    count: number,
    to: Address
  ) => dispatch(buyItemWithCandiesRequest(version, item, count, to)),
  onExecuteOrder: (
    itemId: any,
    tokenName: any,
    seller: string,
    price: string,
    paymentToken: string
  ) => dispatch(executeOrderRequest(itemId, tokenName, seller, price, paymentToken)),
  onNavigate: path => dispatch(push(path))
})

export default connect(mapState, mapDispatch)(OrderItemDetail)
