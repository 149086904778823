import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { push, replace } from 'connected-react-router'
import { Button, Container, Field, Form, Modal, Page } from '@kmon/ui'
import { Loader } from 'semantic-ui-react'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import { fromWei } from 'web3x-es/utils'
import { Address } from 'web3x-es/address'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { ContractName } from '@kmon/transactions'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'

import { locations } from '../../../modules/routing/locations'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { AuthorizationModal } from '../../AuthorizationModal'
import { Props } from './OrderItemDetail.types'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { Navbar } from '../../Navbar'
import { Navigation } from '../../Navigation'
import { Footer } from '../../Footer'
import { Details } from '../Details'
import { OrderItemDetailCard } from '../OrderItemDetailCard'
import { BluePrint } from './BluePrint'
import './OrderItemDetail.css'
import { DescriptionBlock } from '../DescriptionBlock'
import { TitleBlock } from '../../NFTPage/TitleBlock'
import { Item, ItemVersion } from '../../../modules/item/types'
import { fetchIPFS } from '../../../modules/item/utils'
import { fromItemCount, toItemCount } from '../../../lib/number'
import { ConfirmModal } from '../ConfirmModal'
import { BigNumber } from 'ethers'
import { Network, Coin } from '@kmon/schemas'
import { NavigationTab } from '../../Navigation/Navigation.types'
import BoundCraftNFT from '../../../images/items/Bounded-craft-static.svg'

const OrderItemDetail = (props: Props) => {
  const dispatch = useDispatch()
  const {
    wallet,
    authorizations,
    isConnecting,
    isLoading,
    isBuyingItem,
    isCraftingItem,
    itemId,
    isOwned,
    currentItem,
    upgradeDefinitions,
    itemTypes,
    materialItemTypes,
    myItems,
    itemBalance,
    craftingStarted,
    craftingFinished,
    onFetchItem,
    onFetchAllItems,
    onFetchMyItems,
    onFetchOrderedItems,
    onFetchItemTypes,
    onFetchMaterialItemTypes,
    onBuyItem,
    onCraftItem,
    onFetchItemBalance,
    onBuyItemWithCandies,
    onExecuteOrder,
    onNavigate
  } = props
  const isTxPending = isLoading && isBuyingItem
  const price =
    currentItem === undefined
      ? undefined
      : BigNumber.from(currentItem.usdPrice).toString()
  const priceWithCandies =
    currentItem === undefined
      ? undefined
      : BigNumber.from(currentItem.candiesPrice || 0).toString()
  const priceStr = price !== undefined ? fromWei(price, 'ether') : ''
  const priceWithCandiesStr =
    priceWithCandies !== undefined ? fromWei(priceWithCandies, 'ether') : '0'
  const [currentItemVersion, setCurrentItemVersion] = useState(ItemVersion.V2)
  const [currentItemCount, setCurrentItemCount] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentPayment, setCurrentPayment] = useState('KMON')
  const [metaData, setMedata] = useState({} as any)
  const [definitions, setDefinitions] = useState([])
  const [buyItem, setBuyItem] = useState(null);
  const [isInsufficient, setIsInsufficient] = useState(false);
  const [notConnect, setNotConnect] = useState(false);

  let itemName = currentItem ? currentItem.name.replace(/_/g, ' ') : ''
  itemName = itemName.replace(/basic/gi, 'bronze').toUpperCase()
  itemName = itemName.replace(/medium/gi, 'silver').toUpperCase()
  itemName = itemName.replace(/premium/gi, 'gold').toUpperCase()

  useEffect(() => {
    onFetchOrderedItems({baseType: itemId})
  }, [itemId])

  useEffect(() => {
    onFetchAllItems()
    wallet && onFetchMyItems()
  }, [wallet])

  useEffect(() => {
    onFetchItemBalance(itemId)
    if (itemId !== undefined) {
      isOwned === '0' ? onFetchMyItems() : onFetchAllItems()
    }
  }, [itemId])

  useEffect(() => {
    const newArray: any = []
    const itemsArray: any = []
    if (definitions.length > 0) {
      definitions.forEach((element: any) => {
        newArray.push(BigNumber.from(element.resultItemTypeId).toString())
        element.items.length > 0 &&
          itemsArray.push(BigNumber.from(element.items[0]).toString())
      })
    }
    newArray.length > 0 && onFetchItemTypes(newArray)
    itemsArray.length > 0 && onFetchMaterialItemTypes(itemsArray)
  }, [definitions])

  useEffect(() => {
    if (buyItem != null) {
      setIsInsufficient(isInsufficientCoin(wallet, buyItem));
      setShowConfirmModal(true);
    }
    else {
      setShowConfirmModal(false);
    }
  }, [buyItem])

  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)

  const contractNames = getContractNames()

  const kmon = getContract({
    name: contractNames.KMONToken
  })

  const item = getContract({
    name: contractNames.KMONFTV2
  })

  // if (!wallet) {
  //   return null
  // }

  const handleSubmitTransfer = () => {
    // return null
    dispatch(push(locations.transferitem(itemId, isOwned)))
  }

  const handleSubmitSell = () => {
    dispatch(push(locations.sellitem(itemId, isOwned)))
  }

  const handleSubmit = (version: ItemVersion) => {
    setCurrentItemVersion(version)
    setShowConfirmModal(true)
    setCurrentPayment('KMON')
  }

  const handleOnCraft = (param: any) => {
    onCraftItem(param)
  }

  const handleSubmitWithCandies = () => {
    setShowConfirmModal(true)
    setCurrentPayment('CANDY')
  }

  const isInsufficientCoin = (wallet: any, item: any) => {
    if (item.paymentToken.toString() === Address.ZERO.toString()) {
      return wallet?.networks[Network.BSC].bnbBalance < +fromWei(item.priceInWei, 'ether')
    } else {
      return wallet?.networks[Network.BSC].kmonBalance < +fromWei(item.priceInWei, 'ether')
    }
  }

  const OrderItemDetail = () => {
    return (
      <Container className="lootbox-detail product-container">
        <Row className="Row">
          {currentItem && (
            <OrderItemDetailCard
              name={currentItem.name}
              image={currentItem.image}
              price={fromWei(
                BigNumber.from(currentItem.usdPrice).toString(),
                'ether'
              )}
              kmonPrice={fromWei(
                BigNumber.from(currentItem.usdPrice)
                  .div(BigNumber.from(currentItem.rate))
                  .toString(),
                'wei'
              )}
              priceWithCandies={fromWei(
                BigNumber.from(currentItem.candiesPrice || 0).toString(),
                'wei'
              )}
              isNFT={currentItem.isNFT}
              itemType={metaData?.properties?.type}
              canBeTransferred={currentItem.canBeTransferred}
              balance={itemBalance}
              isOwned={isOwned}
            />
          )}
          <Column>
            {isTxPending && (
              <>
                <div className="overlay" />
                <Loader active size="massive" />
              </>
            )}
            <div className="lootbox-description-container">
              <TitleBlock title={t('lootbox_page.description_block.title')}>
                {/* <p className="lootbox-description-name">
                  {t(`nft_item.description.${currentItem?.name}`)}
                </p> */}
                <DescriptionBlock
                  description={
                    t(`nft_item.description.${currentItem?.name}`) || ''
                  }
                />
              </TitleBlock>
            </div>
            <div className="lootbox-separator" />
            <div className="lootbox-craft-container">
              <div className='orderitemdetail-seller-container'>
                {!isConnecting ?
                  currentItem.detail.map((item: any, index: any) => {
                    return <BluePrint
                      wallet={wallet}
                      data={item}
                      onBuy={() => {
                        setBuyItem(item)
                        if (!wallet) setNotConnect(true)
                      }
                      }
                      isOwned={isOwned}
                      isLast={index == currentItem.detail.length - 1}
                      onNavigate={onNavigate}
                      key={index}
                    />
                  }) : <Loading />}
              </div>
            </div>
          </Column>
        </Row>
        <ConfirmModal
          wallet={wallet}
          authorizations={authorizations}
          currentItem={currentItem}
          selectedItem={buyItem}
          showConfirmModal={showConfirmModal}
          image={currentItem.image}
          isInsufficient={isInsufficient}
          handleProceed={onExecuteOrder}
          onCloseModal={() => {
            setBuyItem(null)
          }}
        />
      </Container>
    )
  }

  const Loading = () => (
    <div className="nft-center">
      <Loader active size="huge" />
    </div>
  )

  const NotFound = () => (
    <div className="nft-center">
      <p className="secondary-text">{t('global.not_found')}&hellip;</p>
    </div>
  )

  const NotConnected = () => (
    <div className="wallet-center">
      <p className="secondary-text">
        <T
          id="wallet.sign_in_required"
          values={{
            sign_in: <Link to={locations.signIn()}>{t('wallet.sign_in')}</Link>
          }}
        />
      </p>
    </div>
  )

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation isFullscreen activeTab={NavigationTab.BROWSEITEM} />
      </div>
      <Page className="NFTPage" isFullscreen>
        {isConnecting || isLoading ? <Loading /> : null}
        {!isConnecting && price !== undefined && !notConnect ? <OrderItemDetail /> : null}
        {notConnect ? <NotConnected /> : null}
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(OrderItemDetail)
