import React from 'react'
import { Card } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { t } from '@kmon/dapps/dist/modules/translation/utils'
import { KIcon } from '@kmon/ui'

import { locations } from '../../../modules/routing/locations'
import { Props } from './LootboxCard.types'
import { Image } from '../../Image'
import './LootboxCard.css'

const LootboxCard = (props: Props) => {
  const { itemId, name, price, priceWithCandies, image, usdPrice } = props
  let itemName = name.replace(/_/g, ' ')
  itemName = itemName.replace(/basic/gi, 'bronze')
  itemName = itemName.replace(/medium/gi, 'silver')
  itemName = itemName.replace(/premium/gi, 'gold')
  itemName = itemName.replace(/-/gi, ' X ')
  const itemPrice = Number.parseFloat(price).toFixed(2)
  const itemPriceWithCandies = Number.parseFloat(priceWithCandies).toFixed(2)

  return (
    <Card className="LootboxCard" link as={Link} to={locations.item(itemId)}>
      <div className="card-image-container">
        <div className="card-image">
          <Image src={image} />
        </div>
        <div className="card-image-text">
          <div className="product-type-price-container">
            <div className="product-type-price">{`${itemPrice} KMON`}</div>
          </div>
        </div>
        <div className="card-image-text candy">
          <div className="product-type-price-container">
            <div className="product-type-price-candy">
              <KIcon icon="candy" inline />
              <div>{itemPriceWithCandies}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-description-container">
        <div className="product-info">
          <div className="product-info-name-container">
            {/* <Row> */}
            <p className="product-info-number-card">{itemName}</p>
            {/* </Row> */}
          </div>
        </div>
        <div className="product-description">
          <div className="product-description-left">
            <p className="product-description-left-item">
              {t('item_page.item_type')}: {itemName}
            </p>
            <p className="product-description-left-item">
              {/* {t('item_page.type')}: {metaData?.properties?.type} */}
            </p>
          </div>
          {/* {
            <div className="product-description-right-nft">
              <div className="product-description-right-nft-text">NFT</div>
            </div>
          } */}
        </div>
      </div>
    </Card>
  )
}

export default React.memo(LootboxCard)
