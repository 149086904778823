import React, { useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Page, Loader } from '@kmon/ui'
import { Icon } from 'semantic-ui-react'
import { Profile } from '@kmon/dapps/dist/containers'
import { isMobile } from '@kmon/dapps/dist/lib/utils'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

import { View } from '../../modules/ui/types'
import { useTimer } from '../../lib/timer'
import { Navbar } from '../Navbar'
import { PageHeader } from '../PageHeader'
import { Footer } from '../Footer'
import { NFTBrowse } from '../NFTBrowse'
import { Navigation } from '../Navigation'
import { NavigationTab } from '../Navigation/Navigation.types'
import { locations } from '../../modules/routing/locations'
import { Props } from './SaleItemPage.types'
import { Column } from '../Layout/Column'
import { AccountProfile } from '../AccountProfile'
import './SaleItemPage.css'
import { FooterImage } from '../FooterImage'
import { Header } from '../Header'

const SaleItemPage = (props: Props) => {
  const {
    address,
    vendor,
    wallet,
    isConnecting,
    onRedirect,
    onFetchMyItems,
    isFullscreen
  } = props

  const isCurrentAccount =
    address === undefined || (wallet && wallet.address === address)

  const [hasCopiedAddress, setHasCopiedAddress] = useTimer(1200)
  // Redirect to signIn if trying to access current account without a wallet
  useEffect(() => {
    if (isCurrentAccount && !isConnecting && !wallet) {
      onRedirect(locations.signIn())
    } else {
      onFetchMyItems()
    }
  }, [isCurrentAccount, isConnecting, wallet, onRedirect])

  useEffect(() => {
    window.analytics.track('Login MarketPlace', 
    {
      ethAddress: wallet?.address,
      kmonAmount: wallet?.networks.BSC.kmonBalance,
      providerType: wallet?.providerType
    })
  }, [wallet])

  return (
    <div className="SaleItemPage">
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation
          activeTab={isCurrentAccount ? NavigationTab.SALEITEMS : undefined}
          isFullscreen={isFullscreen}
        />
      </div>
      <Header
        title={t('saleitem_page.title')}
        subTitle={t('saleitem_page.sub_title')}
        wallet={wallet}
      />
      <FooterImage />
      <Footer isFullscreen={isFullscreen} />
    </div>
  )
}

export default React.memo(SaleItemPage)
