import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'
import {
  getData as getAuthorizations,
  getLoading,
  getError
} from '@kmon/dapps/dist/modules/authorization/selectors'
import {
  FETCH_AUTHORIZATIONS_REQUEST,
  grantTokenRequest,
  GrantTokenRequestAction
} from '@kmon/dapps/dist/modules/authorization/actions'

import {
  getBNFTBalance,
  getBNFTTokenIds,
  getCommunityRedeeption,
  getBNFTTokenBalances,
  getOrderedItems,
  getTokenBalancesByIds,
  getLoading as getBNFTLoading
} from '../../../modules/bnft/selectors'
import {
  fetchBNFTBalanceRequest,
  fetchCommunityOrderRequest,
  fetchOrderDetailsRequest,
  sendBuyBnftSuccess,
  FETCH_BNFT_BALANCE_REQUEST,
  FETCH_BNFT_BALANCE_SUCESS
} from '../../../modules/bnft/actions'
import { RootState } from '../../../modules/reducer'
import { isLoadingType } from '@kmon/dapps/dist/modules/loading/selectors'
import { getWallet, isConnecting } from '../../../modules/wallet/selectors'
import { MapStateProps, MapDispatch, MapDispatchProps } from './PayPage.types'
import PayPage from './PayPage'

const mapState = (state: RootState): MapStateProps => {
  const wallet = getWallet(state)

  const error = getError(state)

  const hasError = !!error && !error.includes('denied transaction signature')

  return {
    wallet,
    authorizations: getAuthorizations(state),
    isLoadingAuthorization: isLoadingType(
      getLoading(state),
      FETCH_AUTHORIZATIONS_REQUEST
    ),
    isConnecting: isConnecting(state),
    isBNFTRequestLoading: isLoadingType(
      getBNFTLoading(state),
      FETCH_BNFT_BALANCE_REQUEST
    ),
    isBNFTSuccessLoading: isLoadingType(
      getBNFTLoading(state),
      FETCH_BNFT_BALANCE_SUCESS
    ),
    bnftBalance: getBNFTBalance(state),
    bnftTokenIds: getBNFTTokenIds(state),
    bnftTokenBalances: getBNFTTokenBalances(state),
    communityRedeeption: getCommunityRedeeption(state),
    orderedItems: getOrderedItems(state),
    tokenBalancesByIds: getTokenBalancesByIds(state),
    hasError
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  // onNavigate: path => dispatch(push(path)),
  onRedirect: path => dispatch(replace(path)),
  onGrant: authorization => dispatch(grantTokenRequest(authorization)),
  onFetchBNFTBalance: () => dispatch(fetchBNFTBalanceRequest()),
  onFetchCommunityOrder: param => dispatch(fetchCommunityOrderRequest(param)),
  onFetchOrderDetails: param => dispatch(fetchOrderDetailsRequest(param))
  // onBuyBNFTSuccess: param = dispatch(sendBuyBnftSuccess(param))
})

export default connect(mapState, mapDispatch)(PayPage)
