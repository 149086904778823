import React from 'react'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ScrollToTop } from './components/ScrollToTop'
import WalletProvider from '@kmon/dapps/dist/providers/WalletProvider'
import ToastProvider from '@kmon/dapps/dist/providers/ToastProvider'
import TranslationProvider from '@kmon/dapps/dist/providers/TranslationProvider'
import { ToastContainer } from 'react-toastify'


import 'react-toastify/dist/ReactToastify.css'

import './setup'
import './modules/analytics/track'
import './modules/analytics/rollbar'

import * as locales from './modules/translation/locales'
import { initStore, history } from './modules/store'
import { Routes } from './components/Routes'

import { buildContracts } from './modules/contract/utils'

import './themes'
import './index.css'
import { WrappedApolloProvider } from './components/WrappedApolloProvider'
import { Banner } from './components/Banner'

async function main() {
  await buildContracts()

  const component = (
    <Provider store={initStore()}>
      <TranslationProvider locales={Object.keys(locales)}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastProvider>
          <WalletProvider>
            <ConnectedRouter history={history}>
              <WrappedApolloProvider>
                <Banner />
                <ScrollToTop />
                <Routes />
              </WrappedApolloProvider>
            </ConnectedRouter>
          </WalletProvider>
        </ToastProvider>
      </TranslationProvider>
    </Provider>
  )

  ReactDOM.render(component, document.getElementById('root'))
}

main()
