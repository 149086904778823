import { action } from 'typesafe-actions'
import { ChainId } from '@kmon/schemas'
import { buildTransactionPayload } from '@kmon/dapps/dist/modules/transaction/utils'

import { Address } from 'web3x-es/address'
import { BNFTItem } from './types'
import { ItemVersion } from './types'

export const FETCH_BNFT_BALANCE_REQUEST = '[Request] Fetch BNFT Balance'
export const FETCH_BNFT_BALANCE_SUCESS = '[Success] Fetch BNFT Balance'
export const FETCH_BNFT_BALANCE_FAILURE = '[Failure] Fetch BNFT Balance'

export const fetchBNFTBalanceRequest = () =>
  action(FETCH_BNFT_BALANCE_REQUEST, {})
export const fetchBNFTBalanceSuccess = (items: BNFTItem[]) =>
  action(FETCH_BNFT_BALANCE_SUCESS, { items })
export const fetchBNFTBalanceFailure = (error: string) =>
  action(FETCH_BNFT_BALANCE_FAILURE, { error })

export type FetchBNFTBalanceRequestAction = ReturnType<
  typeof fetchBNFTBalanceRequest
>
export type FetchBNFTBalanceSuccessAction = ReturnType<
  typeof fetchBNFTBalanceSuccess
>
export type FetchBNFTBalanceFailureAction = ReturnType<
  typeof fetchBNFTBalanceFailure
>

export const FETCH_COMMUNITY_PRODUCTS_REQUEST =
  '[Request] Fetch  Community Products'
export const FETCH_COMMUNITY_PRODUCTS_SUCESS =
  '[Success] Fetch  Community Products'
export const FETCH_COMMUNITY_PRODUCTS_FAILURE =
  '[Failure] Fetch  Community Products'

export const fetchCommunityProductsRequest = () =>
  action(FETCH_COMMUNITY_PRODUCTS_REQUEST, {})
export const fetchCommunityProductsSuccess = (items: BNFTItem[]) =>
  action(FETCH_COMMUNITY_PRODUCTS_SUCESS, { items })
export const fetchCommunityProductsFailure = (error: string) =>
  action(FETCH_COMMUNITY_PRODUCTS_FAILURE, { error })

export type FetchCommunityProductsRequestAction = ReturnType<
  typeof fetchCommunityProductsRequest
>
export type FetchCommunityProductsSuccessAction = ReturnType<
  typeof fetchCommunityProductsSuccess
>
export type FetchCommunityProductsFailureAction = ReturnType<
  typeof fetchCommunityProductsFailure
>

export const FETCH_COMMUNITY_ORDER_REQUEST = '[Request] Fetch  Community Order'
export const FETCH_COMMUNITY_ORDER_SUCESS = '[Success] Fetch  Community Order'
export const FETCH_COMMUNITY_ORDER_FAILURE = '[Failure] Fetch  Community Order'

export const fetchCommunityOrderRequest = (options: any) =>
  action(FETCH_COMMUNITY_ORDER_REQUEST, { options })
export const fetchCommunityOrderSuccess = (items: BNFTItem[]) =>
  action(FETCH_COMMUNITY_ORDER_SUCESS, { items })
export const fetchCommunityOrderFailure = (error: string) =>
  action(FETCH_COMMUNITY_ORDER_FAILURE, { error })

export type FetchCommunityOrderRequestAction = ReturnType<
  typeof fetchCommunityOrderRequest
>
export type FetchCommunityOrderSuccessAction = ReturnType<
  typeof fetchCommunityOrderSuccess
>
export type FetchCommunityOrderFailureAction = ReturnType<
  typeof fetchCommunityProductsFailure
>

export const FETCH_ORDER_DETAILS_REQUEST = '[Request] Fetch Order Details'
export const FETCH_ORDER_DETAILS_SUCESS = '[Success] Fetch  Order Details'
export const FETCH_ORDER_DETAILS_FAILURE = '[Failure] Fetch  Order Details'

export const fetchOrderDetailsRequest = (options: any) =>
  action(FETCH_ORDER_DETAILS_REQUEST, { options })
export const fetchOrderDetailsSuccess = (items: BNFTItem[]) =>
  action(FETCH_ORDER_DETAILS_SUCESS, items)
export const fetchOrderDetailsFailure = (error: string) =>
  action(FETCH_ORDER_DETAILS_FAILURE, { error })

export type FetchOrderDetailsRequestAction = ReturnType<
  typeof fetchOrderDetailsRequest
>
export type FetchOrderDetailsSuccessAction = ReturnType<
  typeof fetchOrderDetailsSuccess
>
export type FetchOrderDetailsFailureAction = ReturnType<
  typeof fetchOrderDetailsFailure
>

export const SAVE_BNFT_CHECKOUT_REQUEST = '[Request] Save BNFT Checkout'

export const saveBNFTCheckoutRequest = (checkout: any) =>
  action(SAVE_BNFT_CHECKOUT_REQUEST, { checkout })

export type SaveBNFTCheckoutRequestAction = ReturnType<
  typeof saveBNFTCheckoutRequest
>

export const SAVE_BNFT_NOTICE_REQUEST = '[Request] Save BNFT NOTICE'

export const saveBNFTNoticeRequest = () => action(SAVE_BNFT_NOTICE_REQUEST)

export type SaveBNFTNoticeRequestAction = ReturnType<
  typeof saveBNFTNoticeRequest
>

export const SEND_BUY_BNFT_SUCCESS = '[Request] Send Buy BNFT Success'

export const sendBuyBnftSuccess = (payload: any) => {
  const { chainId, txHash, item, to } = payload
  action(SEND_BUY_BNFT_SUCCESS, {
    item,
    to,
    ...buildTransactionPayload(chainId, txHash, {
      chainId,
      txHash,
      item,
      to
    })
  })
}

export type SendBuyBNFTSuccessRequestAction = ReturnType<
  typeof sendBuyBnftSuccess
>
