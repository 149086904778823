import { action } from 'typesafe-actions'

import { GameAccountOptions, GameAuthTokenOptions } from './types'

// Fetch Merchandise order

export const FETCH_GAME_ACCOUNT_REQUEST = '[Request]Fetch GameAccount Request'
export const FETCH_GAME_ACCOUNT_SUCCESS = '[Success]Fetch GameAccount Success'
export const FETCH_GAME_ACCOUNT_FAILURE = '[Failure]Fetch GameAccount Failure'

export const FETCH_GAME_AUTH_TOKEN_REQUEST =
  '[Request]Fetch Game AuthToken Request'
export const FETCH_GAME_AUTH_TOKEN_SUCCESS =
  '[Request]Fetch Game AuthToken Success'
export const FETCH_GAME_AUTH_TOKEN_FAILURE =
  '[Request]Fetch Game AuthToken Failure'

export const fetchGameAccountRequest = (options: GameAccountOptions) =>
  action(FETCH_GAME_ACCOUNT_REQUEST, { options })

export const fetchGameAccountSuccess = (order: any) =>
  action(FETCH_GAME_ACCOUNT_SUCCESS, order)

export const fetchGameAccountFailure = (error: any) =>
  action(FETCH_GAME_ACCOUNT_FAILURE, { error })

export const fetchGameAuthTokenRequest = (options: GameAuthTokenOptions) =>
  action(FETCH_GAME_AUTH_TOKEN_REQUEST, { options })

export const fetchGameAuthTokenSuccess = (options: any) =>
  action(FETCH_GAME_AUTH_TOKEN_SUCCESS, { options })

export const fetchGameAuthTokenFailure = (options: any) =>
  action(FETCH_GAME_AUTH_TOKEN_FAILURE, { options })

export type FetchGameAccountRequestAction = ReturnType<
  typeof fetchGameAccountRequest
>
export type FetchGameAccountSuccessAction = ReturnType<
  typeof fetchGameAccountSuccess
>
export type FetchGameAccountFailureAction = ReturnType<
  typeof fetchGameAccountFailure
>

export type FetchGameAuthTokenRequestAction = ReturnType<
  typeof fetchGameAuthTokenRequest
>
export type FetchGameAuthTokenSuccessAction = ReturnType<
  typeof fetchGameAuthTokenSuccess
>
export type FetchGameAuthTokenFailureAction = ReturnType<
  typeof fetchGameAuthTokenFailure
>
