import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { Button, Container, Field, Form, Modal, Page } from '@kmon/ui'
import { Loader } from 'semantic-ui-react'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import { fromWei } from 'web3x-es/utils'
import { Address } from 'web3x-es/address'
import {
  Authorization,
  AuthorizationType
} from '@kmon/dapps/dist/modules/authorization/types'
import { ContractName } from '@kmon/transactions'
import { hasAuthorization } from '@kmon/dapps/dist/modules/authorization/utils'

import { locations } from '../../../modules/routing/locations'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { AuthorizationModal } from '../../AuthorizationModal'
import { Props } from './LootboxDetail.types'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { Navbar } from '../../Navbar'
import { Navigation } from '../../Navigation'
import { Footer } from '../../Footer'
import { Details } from '../Details'
import { LootboxDetailCard } from '../LootboxDetailCard'
import { BluePrint } from './BluePrint'
import './LootboxDetail.css'
import { DescriptionBlock } from '../DescriptionBlock'
import { TitleBlock } from '../../NFTPage/TitleBlock'
import { Item, ItemVersion } from '../../../modules/item/types'
import { fetchIPFS } from '../../../modules/item/utils'
import { images } from '../LootboxesPage'
import { NavigationTab } from '../../Navigation/Navigation.types'
import { ConfirmModal } from '../ConfirmModal'
import { BigNumber } from 'ethers'
import BoundCraftNFT from '../../../images/items/Bounded-craft-static.svg'
import { CraftingModal } from '../CraftingModal'

const LootboxDetail = (props: Props) => {
  const dispatch = useDispatch()
  const {
    wallet,
    authorizations,
    isConnecting,
    isLoading,
    isBuyingItem,
    isCraftingItem,
    itemId,
    isOwned,
    currentItem,
    orderedItem,
    upgradeDefinitions,
    itemTypes,
    materialItemTypes,
    myItems,
    itemBalance,
    craftingStarted,
    craftingFinished,
    onFetchItem,
    onFetchAllItems,
    onFetchMyItems,
    onFetchItemTypes,
    onFetchMaterialItemTypes,
    onBuyItem,
    onCraftItem,
    onFetchItemBalance,
    onBuyItemWithCandies
  } = props
  const isTxPending = isLoading && isBuyingItem
  const price =
    currentItem === undefined
      ? undefined
      : BigNumber.from(currentItem.usdPrice).toString()
  const priceWithCandies =
    currentItem === undefined
      ? undefined
      : BigNumber.from(currentItem.candiesPrice || 0).toString()
  const priceStr = price !== undefined ? fromWei(price, 'ether') : ''
  const priceWithCandiesStr =
    priceWithCandies !== undefined ? fromWei(priceWithCandies, 'ether') : '0'
  const itemImage =
    currentItem === undefined
      ? ''
      : images[currentItem.name.toLocaleLowerCase()]
  let isUpdate = false;
  orderedItem?.forEach((ele: any) => {
    ele.detail.forEach((subEle: any) => {
      if (subEle.itemTypeId == itemId && subEle.seller.toString().toLowerCase() == wallet?.address.toString().toLocaleLowerCase()) {
        isUpdate = true;
      }
    })
  });
  const [currentItemVersion, setCurrentItemVersion] = useState(ItemVersion.V2)
  const [currentItemCount, setCurrentItemCount] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [currentPayment, setCurrentPayment] = useState('KMON')
  const [metaData, setMedata] = useState({} as any)
  const [definitions, setDefinitions] = useState([])

  let itemName = currentItem ? currentItem.name.replace(/_/g, ' ') : ''
  itemName = itemName.replace(/basic/gi, 'bronze').toUpperCase()
  itemName = itemName.replace(/medium/gi, 'silver').toUpperCase()
  itemName = itemName.replace(/premium/gi, 'gold').toUpperCase()
  useEffect(() => {
    onFetchAllItems()
    wallet && onFetchMyItems()
  }, [wallet])

  useEffect(() => {
    onFetchItemBalance(itemId)
    if (itemId !== undefined) {
      isOwned === '0' ? onFetchMyItems() : onFetchAllItems()
    }
  }, [itemId])

  useEffect(() => {
    if (upgradeDefinitions.length > 0 && currentItem) {
      const array = upgradeDefinitions.filter((item: any) => {
        return (
          BigNumber.from(item.mainItemTypeId).toString() ===
          BigNumber.from(currentItem.baseTypeId).toString()
        )
      })
      setDefinitions(array)
    }
  }, [currentItem, upgradeDefinitions])

  useEffect(() => {
    const newArray: any = []
    const itemsArray: any = []
    if (definitions.length > 0) {
      definitions.forEach((element: any) => {
        newArray.push(BigNumber.from(element.resultItemTypeId).toString())
        element.items.length > 0 &&
          itemsArray.push(BigNumber.from(element.items[0]).toString())
      })
    }
    newArray.length > 0 && onFetchItemTypes(newArray)
    itemsArray.length > 0 && onFetchMaterialItemTypes(itemsArray)
  }, [definitions])

  const fetchData = useCallback(async image => {
    const data = await fetchIPFS(image)

    setMedata(data)
  }, [])

  const getImage = (uri: string) => {
    if (!uri) {
      return ''
    } else {
      const _img = `https://ipfs.kryptomon.co/ipfs/${uri.replace(
        'ipfs://',
        ''
      )}`
      return _img
    }
  }

  useEffect(() => {
    fetchData(currentItem?.image).catch(console.error)
  }, [currentItem])

  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)

  const contractNames = getContractNames()

  const kmon = getContract({
    name: contractNames.KMONToken
  })

  const item = getContract({
    name: contractNames.Item
  })

  if (!wallet) {
    return null
  }

  const authorization: Authorization = {
    address: wallet.address,
    authorizedAddress: item.address,
    contractAddress: kmon.address,
    contractName: ContractName.KMONToken,
    chainId: kmon.chainId,
    type: AuthorizationType.ALLOWANCE
  }

  const handleSubmitTransfer = () => {
    // return null
    dispatch(push(locations.transferitem(itemId, isOwned)))
  }

  const handleSubmitSell = () => {
    dispatch(push(locations.sellitem(itemId, isOwned)))
  }

  const handleSubmit = (version: ItemVersion) => {
    setCurrentItemVersion(version)
    setShowConfirmModal(true)
    setCurrentPayment('KMON')
  }

  const handleOnCraft = (param: any) => {
    onCraftItem(param)
  }

  const handleSubmitWithCandies = () => {
    setShowConfirmModal(true)
    setCurrentPayment('CANDY')
  }

  const handleProceed = (itemCount: string) => {
    setCurrentItemCount(itemCount)
    if (hasAuthorization(authorizations, authorization)) {
      handleBuyItem(currentItemVersion, itemCount)
    } else {
      setShowAuthorizationModal(true)
    }
  }

  const handleClose = () => setShowAuthorizationModal(false)

  const handleBuyItem = (version: ItemVersion, itemCount: string) => {
    if (currentItem === undefined || price === undefined) return
    if (Number(itemCount) < 1) return
    if (currentPayment === 'KMON') {
      onBuyItem(
        version,
        currentItem,
        Number(itemCount),
        Address.fromString(wallet.address)
      )
    }
    else if (currentPayment === 'CANDY') {
      onBuyItemWithCandies(
        version,
        currentItem,
        Number(itemCount),
        Address.fromString(wallet.address)
      )
    }
  }

  const LootboxDetail = () => {
    return (
      <Container className="lootbox-detail product-container">
        <Row className="Row">
          {currentItem && (
            <LootboxDetailCard
              name={currentItem.name}
              image={getImage(metaData.image)}
              price={fromWei(
                BigNumber.from(currentItem.usdPrice).toString(),
                'ether'
              )}
              kmonPrice={fromWei(
                BigNumber.from(currentItem.usdPrice)
                  .div(BigNumber.from(currentItem.rate))
                  .toString(),
                'wei'
              )}
              priceWithCandies={fromWei(
                BigNumber.from(currentItem.candiesPrice || 0).toString(),
                'wei'
              )}
              isNFT={currentItem.isNFT}
              itemType={metaData?.properties?.type}
              canBeTransferred={currentItem.canBeTransferred}
              balance={itemBalance}
              isOwned={isOwned}
            />
          )}
          <Column>
            <div className="lootbox-description-container">{itemName}</div>
            {currentItem && (
              <Details
                itemId={itemId}
                name={itemName}
                price={fromWei(
                  BigNumber.from(currentItem.usdPrice)
                    .div(BigNumber.from(currentItem.rate))
                    .toString(),
                  'wei'
                )}
                usdPrice={fromWei(
                  BigNumber.from(currentItem.usdPrice).toString(),
                  'ether'
                )}
                priceWithCandies={fromWei(
                  BigNumber.from(currentItem.candiesPrice || 0).toString(),
                  'ether'
                )}
                isTxPending={isTxPending}
                onBuyItem={handleSubmit}
                onBuyItemWithCandies={handleSubmitWithCandies}
                onTransferItem={handleSubmitTransfer}
                onSellItem={handleSubmitSell}
                canBeTransferred={currentItem.canBeTransferred}
                isOwned={isOwned}
                isUpdate={isUpdate}
              />
            )}
            {isTxPending && (
              <>
                <div className="overlay" />
                <Loader active size="massive" />
              </>
            )}
            <div className="lootbox-separator" />
            <div className="lootbox-description-container">
              <TitleBlock title={t('lootbox_page.description_block.title')}>
                {/* <p className="lootbox-description-name">
                  {t(`nft_item.description.${currentItem?.name}`)}
                </p> */}
                <DescriptionBlock
                  description={
                    t(`nft_item.description.${currentItem?.name}`) || ''
                  }
                />
              </TitleBlock>
            </div>
            <div className="lootbox-separator" />
            <div className="lootbox-craft-container">
              {definitions?.length > 0 && itemTypes?.items?.length > 0 && (
                <>
                  {/token/i.test(currentItem.name) && (
                    <img className="craft-static" src={BoundCraftNFT} />
                  )}
                  <BluePrint
                    tokenName={currentItem.name}
                    definitions={definitions}
                    itemTypes={itemTypes}
                    materialItemTypes={materialItemTypes}
                    mainItemBalance={itemBalance}
                    myItems={myItems}
                    onCraft={handleOnCraft}
                    isOwned={isOwned}
                    loading={isCraftingItem}
                    bounded={/token/i.test(currentItem.name)}
                  />
                </>
              )}
            </div>
          </Column>
        </Row>
        <CraftingModal
          showCraftingModal={craftingStarted}
          itemImage={getImage(metaData.image)}
          buy={isOwned !== '0'}
        />
        <ConfirmModal
          currentItem={currentItem}
          currentItemCount={currentItemCount}
          isBuyingItem={isBuyingItem}
          showConfirmModal={showConfirmModal && !craftingStarted}
          handleProceed={(handleProceed)}
          onCloseModal={() => {
            setShowConfirmModal(false)
            setCurrentItemCount('')
          }}
        />
        <AuthorizationModal
          open={showAuthorizationModal}
          authorization={authorization}
          isLoading={isBuyingItem}
          onProceed={() => handleBuyItem(currentItemVersion, currentItemCount)}
          onCancel={handleClose}
        />
      </Container>
    )
  }

  const Loading = () => (
    <div className="nft-center">
      <Loader active size="huge" />
    </div>
  )

  const NotFound = () => (
    <div className="nft-center">
      <p className="secondary-text">{t('global.not_found')}&hellip;</p>
    </div>
  )

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation isFullscreen activeTab={NavigationTab.MY_ASSETS} />
      </div>
      <Page className="NFTPage" isFullscreen>
        {isConnecting || isLoading ? <Loading /> : null}
        {!isLoading && !isConnecting && price === undefined ? <NotFound /> : null}
        {!isLoading && !isConnecting && price !== undefined ? <LootboxDetail /> : null}
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(LootboxDetail)
