import { put, call, takeEvery, select, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { fromWei, toHex, rightPad } from 'web3x-es/utils'
import { Address } from 'web3x-es/address'
import { BigNumber } from 'ethers'
import { showToast } from '@kmon/dapps/dist/modules/toast/actions'
import { ToastType } from '@kmon/ui'
import { getChainId } from '@kmon/dapps/dist/modules/wallet/selectors'
import { ChainId } from '@kmon/schemas'
import {
  BuyItemRequestAction,
  buyItemSuccess,
  buyItemFailure,
  BUY_ITEM_REQUEST,
  FETCH_ALL_ITEMS_REQUEST,
  FETCH_MY_ITEMS_REQUEST,
  fetchAllItemsRequest,
  FETCH_ITEMS_REQUEST,
  fetchItemsSuccess,
  fetchItemsFailure,
  FETCH_ITEM_REQUEST,
  fetchItemFailure,
  fetchItemsRequest,
  BUY_ITEM_WITH_CANDIES_REQUEST,
  BuyItemWithCandiesRequestAction,
  buyItemWithCandiesSuccess,
  buyItemWithCandiesFailure,
  fetchAllItemsSuccess,
  fetchAllItemsFailure,
  fetchMyItemsSuccess,
  fetchMyItemsFailure,
  FETCH_ITEM_TYPES_REQUEST,
  FetchItemTypesRequestAction,
  fetchItemTypesSuccess,
  fetchItemTypesFailure,
  buyNFTItemSuccess,
  BuyNFTItemRequestAction,
  BUY_NFT_ITEM_REQUEST,
  CRAFT_NFT_ITEM_REQUEST,
  CraftNFTItemRequestAction,
  craftNFTItemSuccess,
  craftNFTItemFailure,
  FETCH_MATERIAL_ITEM_TYPES_REQUEST,
  FetchMaterialItemTypesRequestAction,
  fetchMaterialItemTypesSuccess,
  fetchMaterialItemTypesFailure,
  FetchItemBalanceRequestAction,
  fetchItemBalanceSuccess,
  fetchItemBalanceFailure,
  FETCH_ITEM_BALANCE_REQUEST,
  buyNFTItemFailure,
  craftNFTItemStart,
  craftNFTItemFinish,
  TRANSFER_ITEM_REQUEST,
  TransferItemRequestAction,
  transferItemSuccess,
  transferItemFailure,
  CREATE_ORDER_REQUEST,
  CreateOrderRequestAction,
  createOrderSuccess,
  createOrderFailure,
  fetchOrderedItemsSuccess,
  fetchOrderedItemsGroupSuccess,
  fetchOrderedItemsFailure,
  FETCH_ORDERED_ITEMS_REQUEST,
  EXECUTE_ORDER_REQUEST,
  ExecuteOrderRequestAction,
  executeOrderSuccess,
  executeOrderFailure,
  CancelOrderRequestAction,
  CANCEL_ORDER_REQUEST,
  cancelOrderSuccess,
  cancelOrderFailure,
  FetchOrderedItemsRequestAction
} from './actions'
import { locations } from '../routing/locations'
import {
  fetchItems,
  buyItem,
  _buyItem,
  fetchItemsWithCandies,
  buyItemWithCandies,
  fetchAllItems,
  fetchItemsBalance,
  fetchItemTypes,
  fetchCraftItem,
  fetchItemsBalanceById,
  getKMONExchangeRate,
  transferItem,
  getNFTItemImage,
  createOrder1155,
  executeOrder1155,
  cancelOrder1155,
  fetchOrderedItem,
  fetchOrderedItemGroup
} from './utils'
import { getWallet } from '../wallet/selectors'
import { getAllData as getItems } from './selectors'
import { Error, Item } from './types'

export function* itemSaga() {
  yield takeEvery(FETCH_ALL_ITEMS_REQUEST, handleFetchAllItemsRequest)
  yield takeEvery(FETCH_MY_ITEMS_REQUEST, handleFetchMyItemsRequest)
  yield takeEvery(FETCH_ORDERED_ITEMS_REQUEST, handleFetchOrderedItemsRequest)
  yield takeEvery(FETCH_ITEMS_REQUEST, handleFetchItemsRequest)
  yield takeEvery(FETCH_ITEM_REQUEST, handleFetchItemRequest)
  yield takeEvery(BUY_ITEM_REQUEST, handleBuyItemRequest)
  yield takeEvery(BUY_NFT_ITEM_REQUEST, handleBuyNFTItemRequest)
  yield takeEvery(FETCH_ITEM_TYPES_REQUEST, handleFetchItemTypesRequest)
  yield takeEvery(FETCH_ITEM_BALANCE_REQUEST, handleFetchItemBalanceRequest)
  yield takeEvery(
    FETCH_MATERIAL_ITEM_TYPES_REQUEST,
    handleFetchMaterialItemTypesRequest
  )
  yield takeEvery(
    BUY_ITEM_WITH_CANDIES_REQUEST,
    handleBuyItemWithCandiesRequest
  )
  yield takeEvery(CRAFT_NFT_ITEM_REQUEST, handleCraftNFTItemRequest)
  yield takeEvery(TRANSFER_ITEM_REQUEST, handleTransferItemRequest)
  yield takeEvery(CREATE_ORDER_REQUEST, handleCreateOrderRequest)
  yield takeEvery(EXECUTE_ORDER_REQUEST, handleExecuteOrderRequest)
  yield takeEvery(CANCEL_ORDER_REQUEST, handleCancelOrderRequest)
}

function* handleFetchMyItemsRequest() {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const items: [] = yield call(fetchItemsBalance, wallet!)
    yield put(fetchMyItemsSuccess(items))
  } catch (error) {
    // @ts-ignore
    yield put(fetchMyItemsFailure(error.message))
  }
}

function* handleFetchOrderedItemsRequest(action: FetchOrderedItemsRequestAction) {
  const { baseType } = action?.payload
  try {
    const items: [] = yield call(fetchOrderedItem, baseType)
    yield put(fetchOrderedItemsSuccess(items))
    const itemGroups: [] = yield call(fetchOrderedItemGroup)
    yield put(fetchOrderedItemsGroupSuccess(itemGroups))
  } catch (error) {
    // @ts-ignore
    yield put(fetchOrderedItemsFailure(error.message))
  }
}

function* handleFetchItemBalanceRequest(action: FetchItemBalanceRequestAction) {
  const { id } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const items: [] = yield call(fetchItemsBalanceById, wallet!, id)
    yield put(fetchItemBalanceSuccess(items))
  } catch (error) {
    // @ts-ignore
    yield put(fetchItemBalanceFailure(error.message))
  }
}

function* handleFetchItemTypesRequest(action: FetchItemTypesRequestAction) {
  const { ids } = action.payload
  try {
    const items: [] = yield call(fetchItemTypes, ids)
    yield put(fetchItemTypesSuccess(items))
  } catch (error) {
    // @ts-ignore
    yield put(fetchItemTypesFailure(error.message))
  }
}

function* handleFetchMaterialItemTypesRequest(
  action: FetchMaterialItemTypesRequestAction
) {
  const { ids } = action.payload
  try {
    const items: [] = yield call(fetchItemTypes, ids)
    yield put(fetchMaterialItemTypesSuccess(items))
  } catch (error) {
    // @ts-ignore
    yield put(fetchMaterialItemTypesFailure(error.message))
  }
}

function* handleFetchAllItemsRequest() {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const _items: [[], []] = yield call(fetchAllItems)
    yield put(fetchAllItemsSuccess(_items))
  } catch (error) {
    // @ts-ignore
    yield put(fetchAllItemsFailure(error.message))
  }
}

async function fetchSummary(res: any) {
  return await res.wait()
}

function* handleCraftNFTItemRequest(action: CraftNFTItemRequestAction) {
  const { param } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const _items: [[], []] = yield call(fetchCraftItem, param, wallet!)
    const res: any = _items
    yield put(craftNFTItemStart())
    const response: [[]] = yield call(fetchSummary, res)
    if (response) {
      yield put(craftNFTItemFinish())
      yield put(craftNFTItemSuccess(res))
      window.analytics.track('Blueprint Crafted', {
        properties: {
          item: param.craftItem
        }
      })
      yield put(
        showToast({
          type: ToastType.INFO,
          title: 'Congrats!',
          body: 'Your Item has been upgraded successfully!',
          timeout: 2000,
          closable: true
        })
      )
      yield put(push(locations.currentAccount()))
    }
  } catch (error) {
    // @ts-ignore
    const err: Error = error
    yield put(craftNFTItemFinish())
    yield put(craftNFTItemFailure(err.message))
    yield put(
      showToast({
        type: ToastType.ERROR,
        title: 'Warning!',
        body: err?.data?.message || err?.message || 'Transaction failed',
        timeout: 6000,
        closable: true
      })
    )
  }
}

function* handleTransferItemRequest(action: TransferItemRequestAction) {
  const { tokenName, tokenId, address, balance } = action.payload?.param

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const _address = Address.fromString(wallet?.address!)
    const chainId: ChainId = yield select(getChainId)
    const items: [[], []] = yield call(
      transferItem,
      tokenId,
      address,
      wallet!
    )
    const image: [[], []] = yield call(getNFTItemImage, wallet!, tokenName)
    const txHash: any = items
    yield put(
      transferItemSuccess(chainId, txHash, tokenName, address, _address, image)
    )
    yield put(push(locations.activity()))
    console.log("Success!");
  } catch (error) {
    // @ts-ignore
    const err: Error = error
    yield put(craftNFTItemFinish())
    yield put(transferItemFailure(tokenName, address, err?.message))
    yield put(
      showToast({
        type: ToastType.ERROR,
        title: 'Warning!',
        body: err?.data?.message || err?.message || 'Transaction failed',
        timeout: 6000,
        closable: true
      })
    )
  }
}

function* handleFetchItemsRequest() {
  try {
    const _items: [[], []] = yield call(fetchAllItems)
    console.log('Items-->', _items)
    const rate: [] = yield call(getKMONExchangeRate)

    const _rate = fromWei(BigNumber.from(rate).toString(), 'ether')

    const [items, itemsWithCandies]: [Item[], Item[]] = yield all([
      call(fetchItems),
      call(fetchItemsWithCandies)
    ])
    for (let i = 0; i < items.length; i++) {
      const price = fromWei(BigNumber.from(items[i].price).toString(), 'ether')

      const itemWithCandy = itemsWithCandies.find(
        item => item.name === items[i].name
      )
      items[i].usdPrice = (parseFloat(price) * parseFloat(_rate)).toFixed(2)
      if (itemWithCandy) {
        items[i].priceWithCandies = itemWithCandy.price
      }
    }

    // removing battle league pass
    // items.splice(items.findIndex(i => i.name == rightPad(toHex('BATTLE_LEAGUE_PASS'), 64)), 1);
    // removing migrate nft to nft
    items.splice(items.findIndex(i => i.name == rightPad(toHex('MIGRATE_NFT_TO_NFT'), 64)), 1);
    items.pop();

    yield put(fetchItemsSuccess(items))
    yield put(fetchAllItemsSuccess(_items))
  } catch (error) {
    // @ts-ignore
    yield put(fetchItemsFailure(error.message))
  }
}

function* handleFetchItemRequest() {
  try {
    const items: [] = yield select(getItems)
    if (items.length === 0) {
      // yield put(fetchAllItemsRequest())
      yield put(fetchItemsRequest())
    }
  } catch (error) {
    // @ts-ignore
    yield put(fetchItemFailure(error.message))
  }
}

function* handleBuyNFTItemRequest(action: BuyNFTItemRequestAction) {
  const { version, item, count, to, withCandies } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const _items: [[], []] = yield call(_buyItem, wallet, item, count, withCandies)
    const res: any = _items
    yield put(craftNFTItemStart())
    const response: [[]] = yield call(fetchSummary, res)

    if (response) {
      yield put(craftNFTItemFinish())
      yield put(buyNFTItemSuccess(item, count))
      yield put(
        showToast({
          type: ToastType.INFO,
          title: 'Congrats!',
          body: 'Item purchased!',
          timeout: 2000,
          closable: true
        })
      )
      yield put(push(locations.currentAccount()))
    }
  } catch (error) {
    // @ts-ignore
    const err: Error = error
    yield put(craftNFTItemFinish())
    yield put(buyNFTItemFailure(item?.itemTypeId, err.message))
    yield put(
      showToast({
        type: ToastType.ERROR,
        title: 'Warning!',
        body: err?.data?.message || err?.message || 'Transaction failed',
        timeout: 6000,
        closable: true
      })
    )
  }
}

function* handleBuyItemRequest(action: BuyItemRequestAction) {
  const { version, item, count, to } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const rate: [] = yield call(getKMONExchangeRate)

    const price = fromWei(BigNumber.from(item.price).toString(), 'ether')
    const _rate = fromWei(BigNumber.from(rate).toString(), 'ether')

    const txHash: string = yield call(
      buyItem,
      wallet,
      version,
      item.itemId,
      count,
      to
    )
    let _item = {
      ...item,
      usdPrice: (parseFloat(price) * parseFloat(_rate)).toFixed(2)
    }
    yield put(buyItemSuccess(chainId, txHash, _item, count, to))
    yield put(push(locations.activity()))
  } catch (error) {
    // @ts-ignore
    yield put(buyItemFailure(item.itemId, error.message))
  }
}

function* handleBuyItemWithCandiesRequest(
  action: BuyItemWithCandiesRequestAction
) {
  const { version, item, count, to } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const chainId: ChainId = yield select(getChainId)

    const txHash: string = yield call(
      buyItemWithCandies,
      wallet,
      item.itemId,
      item.priceWithCandies,
      count,
      to
    )
    yield put(buyItemWithCandiesSuccess(chainId, txHash, item, count, to))
    yield put(push(locations.activity()))
  } catch (error) {
    // @ts-ignore
    yield put(buyItemWithCandiesFailure(item.itemId, error.message))
  }
}

function* handleCreateOrderRequest(
  action: CreateOrderRequestAction
) {
  const { itemId, name, price, numberOfItem, paymentToken, expiresAt } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const txHash: string = yield call(
      createOrder1155,
      wallet,
      itemId,
      numberOfItem,
      price,
      paymentToken,
      expiresAt
    )
    const image: [[], []] = yield call(getNFTItemImage, wallet!, name);
    yield put(createOrderSuccess(name, itemId, image, price, numberOfItem, paymentToken, expiresAt, txHash))
    yield put(push(locations.activity()))
  } catch (error) {
    // @ts-ignore
    yield put(createOrderFailure(itemId, price, numberOfItem, paymentToken, expiresAt, error.message))
  }
}

function* handleExecuteOrderRequest(
  action: ExecuteOrderRequestAction
) {
  const { itemId, tokenName, seller, price, paymentToken } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const buyer = wallet?.address
    const txHash: string = yield call(
      executeOrder1155,
      wallet,
      itemId,
      seller,
      price,
      paymentToken
    )
    const image: [[], []] = yield call(getNFTItemImage, wallet!, tokenName);
    yield put(executeOrderSuccess(tokenName, seller, price, paymentToken, image, txHash, buyer))
    yield put(push(locations.activity()))
  } catch (error) {
    // @ts-ignore
    yield put(executeOrderFailure(itemId, seller, price, paymentToken, error.message))
  }
}

function* handleCancelOrderRequest(
  action: CancelOrderRequestAction
) {
  const { itemId, name } = action.payload
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    console.log(name);
    const txHash: string = yield call(
      cancelOrder1155,
      wallet,
      itemId
    )
    const image: [[], []] = yield call(getNFTItemImage, wallet!, name);
    yield put(cancelOrderSuccess(name, image, txHash))
    yield put(push(locations.activity()))
  } catch (error) {
    // @ts-ignore
    yield put(cancelOrderFailure(itemId, error.message))
  }
}