import React from 'react'
import { Card } from 'semantic-ui-react'
import { Row, KIcon } from '@kmon/ui'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import { Props } from './LootboxDetailCard.types'
import { Image } from '../../Image'
import './LootboxDetailCard.css'

const LootboxDetailCard = (props: Props) => {
  const { name, image, price, priceWithCandies } = props
  return (
    <Card className="LootboxCard" link>
      <div className="card-image-container">
        <div className="card-image">
          <Image src={image} />
        </div>
        <div className="card-image-text">
          <div className="product-type-price-container">
            <div className="product-type-price">{price} KMON</div>
          </div>
        </div>
        <div className="card-image-text candy">
          <div className="product-type-price-container">
            <div className="product-type-price-candy">
              <KIcon icon="candy" inline />
              <div>{priceWithCandies}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-description-container">
        <div className="product-info">
          <div className="product-info-name-container">
            {/* <Row> */}
            <p className="product-info-number-card">{name}</p>
            {/* </Row> */}
          </div>
        </div>
        <div className="product-description">
          <div className="product-description-left">
            <p className="product-description-left-item">
              {t('item_page.item_type')}: {name}
            </p>
            <p className="product-description-left-item">
              {/* {t('item_page.type')}: {metaData?.properties?.type} */}
            </p>
          </div>
          {/* {
            <div className="product-description-right-nft">
              <div className="product-description-right-nft-text">NFT</div>
            </div>
          } */}
        </div>
      </div>
    </Card>
  )
}

export default React.memo(LootboxDetailCard)
