import { getSearchParams } from './search'
import { SearchOptions } from './types'

export const locations = {
  root: () => '/',
  signIn: (returnPath?: string) => {
    return returnPath ? `/sign-in?returnPath=${returnPath}` : '/sign-in'
  },
  settings: () => '/settings',
  partners: () => '/partners',
  bids: () => '/bids',
  browse: (options?: SearchOptions) => {
    const params = getSearchParams(options)
    return params ? `/browse?${params.toString()}` : '/browse'
  },

  strongFighters: () => {
    const params =
      'section=all&isMap=false&isFullscreen=false&vendor=kryptomon&page=1&sortBy=recently_listed&onlyOnSale=true&constitution=40_100&instinct=40_100&healthPoints=50_100&attack=55_100&defence=55_100'
    return `/browse?${params.toString()}`
  },

  cheapStarter: () => {
    const params =
      'section=all&isMap=false&isFullscreen=false&vendor=kryptomon&page=1&sortBy=cheapest&onlyOnSale=true&priceToken=kmon'
    return `/browse?${params.toString()}`
  },

  saleitems: (options?: SearchOptions) => {
    const params = getSearchParams(options)
    return params ? `/saleitems?${params.toString()}` : '/saleitems'
  },

  browseItem: (options?: SearchOptions) => {
    const params = getSearchParams(options)
    return params ? `/browseItem?${params.toString()}` : '/browseItem'
  },

  kryptomons: (options?: SearchOptions) => {
    const params = getSearchParams(options)
    return params ? `/kryptomons?${params.toString()}` : '/kryptomons'
  },
  currentAccount: (options?: SearchOptions) => {
    const params = getSearchParams(options)
    return params ? `/account?${params.toString()}` : '/account'
  },
  account: (address: string = ':address', options?: SearchOptions) => {
    const params = getSearchParams(options)
    return params
      ? `/accounts/${address}?${params.toString()}`
      : `/accounts/${address}`
  },
  nft: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}`,
  parcel: (x: string = ':x', y: string = ':y') => `/parcels/${x}/${y}/detail`,
  estate: (estateId: string = ':estateId') => `/estates/${estateId}/detail`,
  sell: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/sell`,
  buy: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/buy`,
  cancel: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/cancel`,
  transfer: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/transfer`,
  bid: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/bid`,
  activity: () => `/activity`,
  items: () => `/items`,
  item: (id: string = ':id') => `/items/${id}`,
  _item: (id: string = ':id', isOwned: string = ':isOwned') =>
    `/_items/${id}/${isOwned}`,
  transferitem: (id: string = ':id', isOwned: string = ':isOwned') =>
    `/_items/${id}/${isOwned}/transfer`,
  sellitem: (id: string = ':id', isOwned: string = ':isOwned') =>
    `/_items/${id}/${isOwned}/sell`,
  cancelitem: (id: string = ':id', isOwned: string = ':isOwned') =>
    `/_items/${id}/${isOwned}/cancel`,
  buyitem: (id: string = ':id', isOwned: string = ':isOwned') =>
    `/buyItem/${id}/${isOwned}/buy`,
  breed: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/breed/contracts/${contractAddress}/tokens/${tokenId}`,
  bnft: () => '/hunterclub',
  bnftdetail: (id: string = ':id') => `/bnftdetail/${id}`,
  payorder: (orderId: string = ':orderId', email: string = ':email') =>
    `/payorder/${orderId}/${email}`,
  shop: () => `/shop`,
  _browseitem: (id: string = ':id', isOwned: string = ':isOwned') =>
    `/_browseItem/${id}/${isOwned}`
}
