import React from 'react'
import { KIcon } from '@kmon/ui'
import { Props, ItemProps } from './Details.types'
import './Details.css'
import { Actions } from '../Actions'
import { t } from '@kmon/dapps/dist/modules/translation/utils'

const Details = (props: Props) => {
  const { name, price, onBuyItem } = props

  return (
    <div className="BNFTDetails details-container grid-container">
      <DetailItem title={'Price'}>
        <p className="detail-big-text">{`${price}`}</p>
      </DetailItem>
      <div className="lootbox-actions-container">
        <Actions onBuy={onBuyItem} />
      </div>
    </div>
  )
}

export default React.memo(Details)

const DetailItem = ({ title, children }: ItemProps) => {
  return (
    <div className="block-title">
      <p className="block-title-text">{title}</p>
      {children}
    </div>
  )
}
