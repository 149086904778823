import { createMatchSelector } from 'connected-react-router'
import { createSelector } from 'reselect'

import { locations } from '../routing/locations'
import { RootState } from '../reducer'
import { BNFTItem } from './types'
import { BNFTState } from './reducer'

export const getState = (state: RootState) => state.bnft
export const getData = (state: RootState) => getState(state).data
export const getBNFTBalance = (state: RootState) => getState(state).bnftBalance
export const getBNFTTokenIds = (state: RootState) => getState(state).tokenIds
export const getBNFTCheckout = (state: RootState) => getState(state).checkout
export const getOwnedNFTs = (state: RootState) => getState(state).ownedNFTs
export const getTokenBalancesByIds = (state: RootState) =>
  getState(state).tokenBalancesByIds
export const getCommunityRedeeption = (state: RootState) =>
  getState(state).communityRedeeption
export const getBNFTTokenBalances = (state: RootState) =>
  getState(state).tokenBalances
export const getOrderedItems = (state: RootState) =>
  getState(state).orderedItems
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

const itemDetailMatchSelector = createMatchSelector<
  RootState,
  {
    id: string
  }
>(locations.bnftdetail(':id'))

export const getItemId = createSelector<
  RootState,
  ReturnType<typeof itemDetailMatchSelector>,
  string | null
>(itemDetailMatchSelector, match => match?.params.id || null)

export const getCurrentItem = createSelector<
  RootState,
  string | null,
  BNFTState['data'],
  BNFTItem | undefined
>(
  state => getItemId(state),
  state => getData(state),
  (itemId, items) => {
    if (itemId === null) {
      return undefined
    }
    return items.find(item => item.id.toString() === itemId)
  }
)
