import { takeEvery, takeLatest, put, select, call } from 'redux-saga/effects'
import { showToast } from '@kmon/dapps/dist/modules/toast/actions'
import { ToastType } from '@kmon/ui'
import {
  FETCH_GAME_ACCOUNT_REQUEST,
  FETCH_GAME_ACCOUNT_SUCCESS,
  FETCH_GAME_AUTH_TOKEN_REQUEST,
  FETCH_GAME_AUTH_TOKEN_SUCCESS,
  fetchGameAuthTokenRequest,
  fetchGameAuthTokenSuccess,
  fetchGameAccountSuccess,
  fetchGameAccountFailure,
  FetchGameAccountRequestAction,
  FetchGameAuthTokenRequestAction
} from './actions'
import { GameAccountOptions } from './types'
import { nftAPI } from '../vendor/kryptomon'
import { VendorFactory } from '../vendor'
import { AwaitFn } from '../types'
import { VendorName } from '../vendor/types'

export function* settingsSaga() {
  yield takeLatest(FETCH_GAME_ACCOUNT_REQUEST, handleFetchGameAccountRequest)
  yield takeLatest(
    FETCH_GAME_AUTH_TOKEN_REQUEST,
    handleFetchGameAuthTokenRequest
  )
}

function* handleFetchGameAccountRequest(action: FetchGameAccountRequestAction) {
  const {
    username,
    email,
    password,
    _password,
    authToken
  } = action.payload.options

  if (
    username?.length === 0 ||
    email?.length === 0 ||
    password?.length === 0 ||
    _password?.length === 0
  ) {
    yield put(
      showToast({
        type: ToastType.ERROR,
        title: 'Game Account',
        body: '* All fields are required',
        timeout: 6000,
        closable: true
      })
    )
  }

  if (password !== _password) {
    yield put(
      showToast({
        type: ToastType.ERROR,
        title: 'Game Account',
        body: 'Password confirmation does not match',
        timeout: 6000,
        closable: true
      })
    )
  }

  if (
    username?.length > 0 &&
    email?.length > 0 &&
    password?.length > 0 &&
    _password?.length > 0 &&
    password === _password
  ) {
    try {
      const { nftService } = VendorFactory.build(VendorName.KRYPTOMON)

      const response: AwaitFn<typeof nftService.fetch> = yield call(() => {
        // TODO: This `as any` is here because Typescript joins (&) filter types instead of adding them as an or (|)
        return nftService.fetchGameAccount(
          username as string,
          email as string,
          password as string,
          authToken as string
        )
      })
      const _response: any = response
      if (_response?.code === 200) {
        yield put(fetchGameAccountSuccess(response))
      } else {
        yield put(
          showToast({
            type: ToastType.ERROR,
            title: 'Game Account',
            body: _response?.message,
            timeout: 6000,
            closable: true
          })
        )
        yield put(fetchGameAccountFailure(response))
      }
    } catch (error) {
      // @ts-ignore
      console.log(error)
      yield put(fetchGameAccountFailure(error))
    }
  }
}

function* handleFetchGameAuthTokenRequest(
  action: FetchGameAuthTokenRequestAction
) {
  const { id, signature } = action.payload.options

  // const response = nftAPI.fetchOrder(requestOptions)
  console.log('Response--->', id, signature)

  try {
    const { nftService } = VendorFactory.build(VendorName.KRYPTOMON)

    const response: AwaitFn<typeof nftService.fetch> = yield call(() => {
      // TODO: This `as any` is here because Typescript joins (&) filter types instead of adding them as an or (|)
      return nftService.fetchGameAuthToken(id as string, signature as string)
    })
    yield put(fetchGameAuthTokenSuccess(response))
  } catch (error) {
    // @ts-ignore
    console.log(error)
    yield put(fetchGameAccountFailure(error))
  }
}
