/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import {
  Button,
  Container,
  Field,
  Modal,
  Page,
  SelectField,
  Toast,
  ToastType
} from '@kmon/ui'
import { Coin, Network } from '@kmon/schemas'
import { toast } from 'react-toastify'
import { Loader } from 'semantic-ui-react'
import { T, t } from '@kmon/dapps/dist/modules/translation/utils'
import {
  getConnectedProviderChainId,
  getConnectedProvider
} from '@kmon/dapps/dist/lib/eth'
import { BigNumber, ethers, providers } from 'ethers'
import { locations } from '../../../modules/routing/locations'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { Props } from './BNFTDetail.types'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { Navbar } from '../../Navbar'
import { Navigation } from '../../Navigation'
import { Footer } from '../../Footer'
import { CoinField } from '../../CoinField'
import './BNFTDetail.css'
import Cart from '../BNFTCart/Cart'
import { KmonftDimaond } from './KmonftDiamond'
import { BNFT } from '../../../modules/bnft/BNFTABI'
import { BNFTDetailsCard } from '../BNFTDetailsCard'
import { Details } from '../Details'

var client: any = null
const BNFTDetail = (props: Props) => {
  const {
    wallet,
    authorizations,
    isConnecting,
    isLoading,
    itemId,
    currentItem,
    bnftBalance,
    onGrant,
    onRedirect,
    onFetchBNFTBalance,
    onSaveBNFTCheckout,
    bnftCheckout,
    bnftTokenBalances,
    ownedNFTs
  } = props

  const dispatch = useDispatch()

  const [checkout, setCheckout] = useState(bnftCheckout)
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState('')
  const [showLeavingSiteModal, setShowLeavingSiteModal] = useState(false)
  const [email, setEmail] = useState('')
  const [isInsufficient, setIsInsufficient] = useState(false)
  const [selectedBnftTokenBalances, setSelectedBnftTokenBalances] = useState<
    any
  >({})

  const shopifybuy = window.ShopifyBuy
  console.log('Detail--->', currentItem, selectedVariant)

  useEffect(() => {
    var scriptURL =
      'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
    var script = document.createElement('script')
    script.async = true
    script.src = scriptURL
    document.body.appendChild(script)
    // (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (!wallet) {
      dispatch(replace(locations.bnft()))
    }
  }, [isConnecting, wallet, onRedirect])

  // useEffect(() => {
  //   if (wallet && !currentItem) {
  //     onFetchBNFTBalance()
  //   }
  // }, [itemId, wallet])

  useEffect(() => {
    setCheckout(bnftCheckout)
  }, [bnftCheckout])

  useEffect(() => {
    onSaveBNFTCheckout(checkout)
    setIsInsufficient(checkIfInsufficient)
  }, [checkout])

  const inSufficientArray = useMemo(() => {
    let newArray: any = []
    // (Object.keys(selectedBnftTokenBalances) as Array<keyof typeof selectedBnftTokenBalances>).forEach((item: string | number) => {
    Object.keys(selectedBnftTokenBalances).forEach((item: string | number) => {
      selectedBnftTokenBalances[item] === bnftTokenBalances[item] &&
        newArray.push(item)
    })
    return newArray
  }, [selectedBnftTokenBalances])

  console.log('inSufficientArray-->', inSufficientArray)

  function ShopifyBuyInit() {
    const ShopifyBuy = window.ShopifyBuy
    client = ShopifyBuy.buildClient({
      domain: 'kryptomon-market-dev.myshopify.com',
      storefrontAccessToken: 'ed3c0902f1e499cb8e9751e5d80f5c9d'
    })
    bnftCheckout?.id === '' &&
      client.checkout.create().then((res: any) => {
        setCheckout(res)
      })
  }

  const contractNames = getContractNames()

  const handleAddToCart = () => {
    if (selectedVariant === '') {
      setIsCartOpen(false)
      toast.warn('Choose your size!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      // alert('Choose your size!')
    } else {
      setIsCartOpen(true)
      const sku = getVariantSku(selectedVariant)
      if (inSufficientArray.includes(sku)) {
        // alert('Insufficient balance!')
        toast.warn('Insufficient balance!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      } else {
        const checkoutId = window.btoa(checkout?.id)
        const lineItemsToAdd = [
          {
            variantId: getVariantId(selectedVariant),
            quantity: 1
          }
        ]
        client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((res: any) => {
            setCheckout(res)
          })
      }
    }
  }

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const checkoutId = checkout?.id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) }
    ]

    return client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res: any) => setCheckout(res))
  }

  const removeLineItemInCart = (lineItemId: any) => {
    const checkoutId = checkout?.id

    return client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res: any) => {
        setCheckout(res)
      })
  }

  const handleCartClose = () => {
    setIsCartOpen(false)
  }

  const getVariantId = (title: string) => {
    const result = currentItem?.variants.filter(
      (item: any) => item.title === title
    )
    const baseId = window.btoa(result[0]?.admin_graphql_api_id)
    return baseId
  }

  const getVariantSku = (title: string) => {
    const result = currentItem?.variants.filter(
      (item: any) => item.title === title
    )

    return result[0]?.sku
  }

  const variantsOptions = (variants: any) => {
    let array: { key: number; text: JSX.Element; value: any; id: number }[] = []
    variants &&
      variants.map((variant: any, index: number) => {
        array.push({
          key: index,
          text: <span>{variant.title}</span>,
          value: variant.title,
          id: variant.id
        })
      })
    return array
  }

  const onChangeVariants = (val: any) => {
    setSelectedVariant(val)
  }

  if (!wallet) {
    return null
  }

  const handleCheckout = (checkout: any) => {
    // setShowLeavingSiteModal(true)
    // window.open(
    //   checkout.webUrl
    //   // '_blank',
    //   // 'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    // )
    location.href = checkout.webUrl
    console.log('Complete checkout-->', checkout)
    // dispatch(push(locations.bnft()))
  }

  const getTotalQuantity = () => {
    const count: any = {}

    for (let index = 0; index < checkout?.lineItems.length; index++) {
      const element = checkout?.lineItems[index]

      if (count[element.variant.sku]) {
        count[element.variant.sku] += element.quantity
      } else {
        count[element.variant.sku] = element.quantity
      }
    }
    console.log('Count--->', count)
    setSelectedBnftTokenBalances(count)

    let quantity = 0
    checkout?.lineItems.map((line_item: any) => {
      quantity += line_item.quantity
    })

    return quantity
  }

  const checkIfInsufficient = () => {
    return bnftBalance === getTotalQuantity()
  }

  console.log('Main checkout-->', checkout, isInsufficient)

  const BNFTDetail = () => {
    return (
      <Container className="bnft-detail product-container">
        {/* <div className="bnft-container">
          <div className="slide-container bnft-slider">
            <Slide>
              {currentItem?.images.map((image: any) => {
                return (
                  <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${image.src})` }}></div>
                  </div>
                )
              })}
            </Slide>
          </div>
          <div className="bnft-info">
            <div>{currentItem?.title}</div>
          </div>
        </div>
        <Column>
          <div>{currentItem?.title}</div>
        </Column> */}
        <Row className="Row">
          <Column>
            <div className="slide-container bnft-slider">
              <Slide>
                {currentItem?.images.map((image: any) => {
                  return (
                    <div className="each-slide-effect">
                      <div
                        style={{ backgroundImage: `url(${image.src})` }}
                      ></div>
                    </div>
                  )
                })}
              </Slide>
            </div>
          </Column>
          <Column>
            <div className="bnft-info">
              <div className="title">{currentItem?.title}</div>
              <div className="title">{`$${currentItem?.variants[0]?.price}`}</div>
              <div className="bnft-title">{currentItem?.product_type}</div>
              <div className="bnft-title">
                {new Date(currentItem?.published_at).toLocaleDateString()}
              </div>
              {/* <div
                dangerouslySetInnerHTML={{ __html: currentItem?.body_html }}
              /> */}
              <SelectField
                // className={classes}
                options={variantsOptions(currentItem?.variants)}
                onChange={(_, a) => {
                  onChangeVariants(a.value)
                }}
                defaultValue={selectedVariant}
              />
              <Button onClick={handleAddToCart} primary>
                {'Add to Cart'}
              </Button>
            </div>
          </Column>
          {/* <div>{currentItem?.title}</div> */}
          {/* {currentItem && (
            <LootboxDetailCard
              name={itemName}
              image={itemImage}
              price={Number.parseFloat(priceStr).toFixed(2)}
              priceWithCandies={Number.parseFloat(priceWithCandiesStr).toFixed(
                2
              )}
            />
          )} */}
          {/* <Column>
            {currentItem && (
              <Details
                name={itemName}
                price={Number.parseFloat(priceStr).toFixed(2)}
                priceWithCandies={Number.parseFloat(
                  priceWithCandiesStr
                ).toFixed(2)}
                isTxPending={isTxPending}
                onBuyItem={handleSubmit}
                onBuyItemWithCandies={handleSubmitWithCandies}
              />
            )}
          </Column> */}
        </Row>
        {/* <Row className="Row-space-between">
          <TitleBlock title={t('lootbox_page.description_block.title')}>
            <div dangerouslySetInnerHTML={{ __html: currentItem?.body_html }} />
            <DescriptionBlock
              description={t(
                `lootbox_page.description_block.description.${currentItem?.name.toLowerCase()}`
              )}
            />
          </TitleBlock>
        </Row> */}
      </Container>
    )
  }

  const _BNFTDetail = () => {
    return (
      <Container className="bnft-detail product-container">
        <Row className="Row">
          {currentItem && <BNFTDetailsCard item={currentItem} />}
          <Column>
            {currentItem && (
              <Details
                name={currentItem?.title}
                price={`$${currentItem?.variants[0]?.price}`}
                onBuyItem={handleAddToCart}
              />
            )}
            <div className="bnft-separator" />
            <div className="bnft-description-container">
              <p className="detail-big-text">{currentItem?.title}</p>
              {/* <div className="bnft-title">{currentItem?.product_type}</div>
              <div className="bnft-title">
                {new Date(currentItem?.published_at).toLocaleDateString()}
              </div> */}
              <div className="title">{'Choose your size'}</div>
              <SelectField
                // className={classes}
                options={variantsOptions(currentItem?.variants)}
                onChange={(_, a) => {
                  onChangeVariants(a.value)
                }}
                defaultValue={selectedVariant}
              />
              <div
                className="bnft-description-content"
                dangerouslySetInnerHTML={{ __html: currentItem?.body_html }}
              />
            </div>
            <div className="bnft-separator" />
          </Column>
        </Row>
      </Container>
    )
  }

  const Loading = () => (
    <div className="nft-center">
      <Loader active size="huge" />
    </div>
  )

  return (
    <>
      <div className="PageCustomHeader">
        <Navbar isFullscreen />
        <Navigation isFullscreen />
      </div>
      <Page className="NFTPage" isFullscreen>
        {isConnecting ? <Loading /> : null}
        {<_BNFTDetail />}
        <Cart
          checkout={checkout}
          isCartOpen={isCartOpen}
          handleCartClose={handleCartClose}
          handleCheckout={handleCheckout}
          updateQuantityInCart={updateQuantityInCart}
          removeLineItemInCart={removeLineItemInCart}
          bnftTokenBalances={bnftTokenBalances}
          selectedBnftTokenBalances={selectedBnftTokenBalances}
          inSufficientArray={inSufficientArray}
        />
      </Page>
      <Footer />
    </>
  )
}

export default React.memo(BNFTDetail)
